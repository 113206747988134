import React, { useState, useEffect } from 'react';

import {connect} from "react-redux";
import {getSelectUplineSuper} from "../../appRedux/actions/rex";

import { Select } from 'antd';

const Option = Select.Option;


class SelectUpline extends React.Component {
   
  state = {ready: false,Upliners:null};

  onUplineChange =  (Option)    => { 

    const SelectedItem=this.state.Upliners.filter(item => item.ID === Option)   
    this.props.onUplineChange(SelectedItem[0])
};

  componentDidMount=async() =>{ 
   await getSelectUplineSuper(this.props.Type  ).then(data => {
     this.setState({
       Upliners:data
      });
     
 this.setState({
      ready: true,
    });
    })
  }
    render() {

var Upliner_Code="";
  if(this.props.Type =='Mini Admin'){
  Upliner_Code="MI"
  
}
else if(this.props.Type =='Master'){
  Upliner_Code="MA"
  
}
else if(this.props.Type =='Super'){
  Upliner_Code="SA"
  
}
else if(this.props.Type =='Agent'){
  Upliner_Code="A"
  
}

const UplineList = () => {
  try{  return  (
 
 
  <Select showSearch
   onChange={this.onUplineChange} placeholder="Select share type" style={{"width": "240px", "margin": "10px"}} id="UplineSuper"
 width="100%"  >
                {this.state.Upliners.map(Upline => (

      <Option value={Upline.ID}>{Upliner_Code+Upline.ID+"-"+Upline.Name}</Option>
       ))}

     </Select>
  
                
     )}catch{

return <Select showSearch
onChange={this.onUplineChange} placeholder="Select share type" style={{"width": "240px", "margin": "10px"}} id="UplineSuper"
width="100%"  >
            

  </Select>
      
     }
   }
      return (  <>
    <div className="ant-spin-nested-loading">
    <div className="ant-spin-container">
       <div className="ant-card ant-card-bordered">
          <div className="ant-card-head">
             <div className="ant-card-head-wrapper">
                <div className="ant-card-head-title">
                   <h1>Select Upline</h1>
                </div>
             </div>
          </div>
          <div className="ant-card-body" style={{"text-align": "center"}}>
          {
              this.state.ready?
<>
          {UplineList()}
          </>
          :<Select showSearch
          onChange={this.onUplineChange} placeholder="Select share type" style={{"width": "240px", "margin": "10px"}} id="UplineSuper"
        width="100%"  allowClear>
                     
       
            </Select>}
 
          </div>
       </div>
    </div>
 </div>
 </>
    );
  }
}


const mapStateToProps = ({auth} ) => {
  const {authUser} = auth;

   return {authUser }
 };

export default connect(mapStateToProps) (SelectUpline);
