import React,{useState}  from "react";  
import {   useParams} from "react-router-dom"; 
import { Row,Col,DatePicker ,Radio, RadioChangeEvent } from 'antd';
import moment from "moment";
import CasinoProfitLoss from "../components/tables/CasinoProfitAndLoss"
import GoBack from "../components/buttons/GoBack"
const {  RangePicker} = DatePicker;
const CasinoProfitAndLoss = () => {
   
 const {U_Type } = useParams();  
 const [value4, setValue4] = useState('all');
 
  
 const onChange4 = ({ target: { value } }) => {
   alert(value)
   console.log('radio4 checked', value);
   setValue4(value);
 };
    return (<> 
<div className="ant-card ant-card-bordered">
   <div className="ant-card-head">
      <div className="ant-card-head-wrapper">
         <div className="ant-card-head-title">
            <span>
               <h1 style={{"display": "inline-block"}}>Total Profit and Loss </h1>
            </span>
         </div>
         <div className="ant-card-extra"><span><GoBack/></span></div>
      </div>
   </div>
   <div className="ant-card-body">

   <Row style={{"margin": "10px"}}> 
   <Col lg={8} md={8} sm={24} xs={24} style={{"text-align": "center","margin-left":"20px"}}>
       <div style={{"marginTop": "10px"}}>
       <RangePicker className="gx-mb-2 gx-w-100"
                   ranges={{Today: [moment(), moment()], 'This Month': [moment(), moment().endOf('month')]}}
                   />
      </div>
  </Col> 
  <Col xl={4} md={4} sm={24} xs={24} style={{ "margin-left":"20px"}}>
       <div style={{"marginTop": "10px"}}>
       <button type="button" class="ant-btn ant-btn-primary" ant-click-animating-without-extra-node="false"><span>Apply</span></button>
      </div>
  </Col>
  <Col xl={4} md={4} sm={24} xs={24} style={{ "margin-left":"20px"}}>
       <div style={{"marginTop": "10px"}}>
       <a href="/Casino/today-pandl"><button type="button" class="ant-btn ant-btn-primary"><span>Today P/L</span></button></a>
      </div>
  </Col>
</Row>
       
      <div className="ant-table-wrapper">
         <div className="ant-spin-nested-loading">
            <div className="ant-spin-container">
            <CasinoProfitLoss   />
     
               </div>
         </div>
       
      </div>
   </div>
</div> 
            </>
               );
 

 
}


 
 
 
export default CasinoProfitAndLoss;
