import {   Table } from 'antd';
import React  from 'react'; 
const TodayPandl = (props) => {
  
const data = props.data; 
 
  const columns = [
    {
      title: 'Game ID',
      dataIndex: 'Game ID',
      key: 'Game ID',
     },
     {
      title: 'Type',
      dataIndex: 'Type',
      key: 'Type',
     },
      {
        title: 'Started At',
        dataIndex: 'Started At',
        key: 'Started At',
       } ,
       {
        title: 'Winner',
        dataIndex: 'Winner',
        key: 'Winner',
       } ,
     {
      title: 'Plus/Minus',
      dataIndex: 'Plus/Minus',
      key: 'Plus/Minus',
     } ,
     {
      title: 'Action',
      dataIndex: 'Action',
      key: 'Action',
     } ,
      
      
  ];
  return <Table   width={100} size='small' bordered scroll={"left"}   columns={columns} dataSource={data} />;
};
export default TodayPandl;