


import React, { useState,useEffect} from 'react';
import {Modal } from 'antd';
import {getPartnership} from "../../../appRedux/actions/rex";
const UserParnertshipDetails =(state)=>  { 
  const [_Modal, setModal] = useState({Visible:state.visible });  
 
  const [Details, setDetails] = useState([]); 

  const authUser=JSON.parse(localStorage.getItem("authUser"));   
  const handleOk = e => {
    setModal({Visible:false, Title:"Partnership Info - "});                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          
    
   };

  const handleCancel = e => {
  
     setModal({Visible:false});
   ;
   };


   
   useEffect(() => {
    const fetchData = async () => { 
     await  getPartnership(state.id).then((details)=>{

      setDetails(details); 
     });


    };

    fetchData();
},  []);

    return (
   
  <Modal   title={<h1>{"Partnership Info - "+state.code}</h1>} okText='Close'  afterClose={state.CloseModal} cancelButtonProps={{ style: { display: 'none' } }} visible={_Modal.Visible}    onOk={handleOk}  onCancel={handleCancel} >
  <div className="ant-row">
      <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-xl-24">
      <h4>Match Share</h4>
    <table className="table gx-table-bordered partnship" style={{"width": "100%"}}> 
      <thead>
      <tr>{authUser.U_Type=="Admin"?<th>Admin</th>:null} {authUser.U_Type=="Admin"||authUser.U_Type=="Mini Admin"?<th>Mini Admin</th>:null} 
      {authUser.U_Type=="Admin"||authUser.U_Type=="Mini Admin"||authUser.U_Type=="Master"?<th>Master</th>:null}  
      {authUser.U_Type=="Admin"||authUser.U_Type=="Mini Admin"||authUser.U_Type=="Master"||authUser.U_Type=="Super"?<th>Super Agent</th>:null}   <th>Agent</th></tr>
      </thead>
      <tbody>
      <tr>
        
      {authUser.U_Type=="Admin"?<td>{Details.Admin_Match_Share}</td> :null}
      {authUser.U_Type=="Admin"||authUser.U_Type=="Mini Admin"? <td>{Details.Mini_Match_Share}</td>:null}
      {authUser.U_Type=="Admin"||authUser.U_Type=="Mini Admin"||authUser.U_Type=="Master"? <td>{Details.Master_Match_Share}</td>:null}
      {authUser.U_Type=="Admin"||authUser.U_Type=="Mini Admin"||authUser.U_Type=="Master"||authUser.U_Type=="Super"? <td>{Details.Super_Match_Share}</td>:null} 
    
      <td>{Details.Agent_Match_Share}</td>
      
      </tr>
      </tbody>
      </table>
      </div>
      <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-xl-24" style={{"margin-top": "20px"}}>
      <h4>Match Commission</h4>
      <table className="table gx-table-bordered partnship" style={{"width": "100%"}}>
      <thead>
      <tr>
      {authUser.U_Type=="Admin"?<th>Admin</th>:null}
       {authUser.U_Type=="Admin"||authUser.U_Type=="Mini Admin"?<th>Mini Admin</th>:null} 
       {authUser.U_Type=="Admin"||authUser.U_Type=="Mini Admin"||authUser.U_Type=="Master"?<th>Master</th>:null} 
        {authUser.U_Type=="Admin"||authUser.U_Type=="Mini Admin"||authUser.U_Type=="Master"||authUser.U_Type=="Super"?<th>Super Agent</th>:null} 
      <th>Agent</th>
       <th>Client</th>
      </tr>
      </thead>
      <tbody>
      <tr>
      {authUser.U_Type=="Admin"? <td>{Details.Admin_Match_Commission}</td>:null}
      {authUser.U_Type=="Admin"||authUser.U_Type=="Mini Admin"? <td>{Details.Mini_Match_Commission}</td>:null}
      {authUser.U_Type=="Admin"||authUser.U_Type=="Mini Admin"||authUser.U_Type=="Master"? <td>{Details.Master_Match_Commission}</td>:null}
      {authUser.U_Type=="Admin"||authUser.U_Type=="Mini Admin"||authUser.U_Type=="Master"||authUser.U_Type=="Super"? <td>{Details.Super_Match_Commission}</td>:null} 
  <td>{Details.Agent_Match_Commission}</td>
      <td>{Details.Client_Match_Commission}</td>
      </tr>
      </tbody>
      </table>
      </div>
      <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-xl-24" style={{"margin-top": "20px"}}>
      <h4>Session Commission</h4>
      <table className="table gx-table-bordered partnship" style={{"width": "100%"}}>
      <thead>
      <tr>
      {authUser.U_Type=="Admin"?<th>Admin</th>:null} {authUser.U_Type=="Admin"||authUser.U_Type=="Mini Admin"?<th>Mini Admin</th>:null} {authUser.U_Type=="Admin"||authUser.U_Type=="Mini Admin"||authUser.U_Type=="Master"?<th>Master</th>:null}  {authUser.U_Type=="Admin"||authUser.U_Type=="Mini Admin"||authUser.U_Type=="Master"||authUser.U_Type=="Super"?<th>Super Agent</th>:null} 
      <th>Agent</th>
      <th>Client</th>
      </tr>
      </thead>
      <tbody>
      <tr>
      {authUser.U_Type=="Admin"? <td>{Details.Admin_Session_Commission}</td> :null}
      {authUser.U_Type=="Admin"||authUser.U_Type=="Mini Admin"?<td>{Details.Mini_Session_Commission}</td> :null}
      {authUser.U_Type=="Admin"||authUser.U_Type=="Mini Admin"||authUser.U_Type=="Master"?<td>{Details.Master_Session_Commission}</td> :null}
      {authUser.U_Type=="Admin"||authUser.U_Type=="Mini Admin"||authUser.U_Type=="Master"||authUser.U_Type=="Super"? <td>{Details.Super_Session_Commission}</td>:null} 
  
      <td>{Details.Agent_Session_Commission}</td>
      <td>{Details.Client_Session_Commission}</td>
      </tr>
      </tbody>
      </table>
      </div>
        <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-xl-24" style={{"margin-top": "20px"}}>
      <h4>Casino Share</h4>
      <table className="table gx-table-bordered partnship" style={{"width": "100%"}}>
      <thead>
      <tr>
      {authUser.U_Type=="Admin"?<th>Admin</th>:null} {authUser.U_Type=="Admin"||authUser.U_Type=="Mini Admin"?<th>Mini Admin</th>:null} {authUser.U_Type=="Admin"||authUser.U_Type=="Mini Admin"||authUser.U_Type=="Master"?<th>Master</th>:null}  {authUser.U_Type=="Admin"||authUser.U_Type=="Mini Admin"||authUser.U_Type=="Master"||authUser.U_Type=="Super"?<th>Super Agent</th>:null} 
      <th>Agent</th>
      </tr>
      </thead>
      <tbody>
       
      <tr>
      {authUser.U_Type=="Admin"?   <td>{Details.Admin_Casino_Share}</td>:null}
      {authUser.U_Type=="Admin"||authUser.U_Type=="Mini Admin"?<td>{Details.Mini_Casino_Share}</td> :null}
      {authUser.U_Type=="Admin"||authUser.U_Type=="Mini Admin"||authUser.U_Type=="Master"? <td>{Details.Master_Casino_Share}</td>:null}
      {authUser.U_Type=="Admin"||authUser.U_Type=="Mini Admin"||authUser.U_Type=="Master"||authUser.U_Type=="Super"?  <td>{Details.Super_Casino_Share}</td>:null} 
     <td>{Details.Agent_Casino_Share}</td>
        </tr>
   
     
      </tbody>
      </table>
      </div>
      <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-xl-24" style={{"margin-top": "20px"}}>
      <h4>Casino Commission</h4>
      <table className="table gx-table-bordered partnship" style={{"width": "100%"}}>
      <thead>
      <tr>
      {authUser.U_Type=="Admin"?<th>Admin</th>:null} {authUser.U_Type=="Admin"||authUser.U_Type=="Mini Admin"?<th>Mini Admin</th>:null} {authUser.U_Type=="Admin"||authUser.U_Type=="Mini Admin"||authUser.U_Type=="Master"?<th>Master</th>:null}  {authUser.U_Type=="Admin"||authUser.U_Type=="Mini Admin"||authUser.U_Type=="Master"||authUser.U_Type=="Super"?<th>Super Agent</th>:null} 
      <th>Agent</th>
      <th>Client</th>
      </tr>
      </thead>
      <tbody>
      <tr>
      {authUser.U_Type=="Admin"?  <td>{Details.Admin_Casino_Commission}</td> :null}
      {authUser.U_Type=="Admin"||authUser.U_Type=="Mini Admin"? <td>{Details.Mini_Casino_Commission}</td> :null}
      {authUser.U_Type=="Admin"||authUser.U_Type=="Mini Admin"||authUser.U_Type=="Master"?  <td>{Details.Master_Casino_Commission}</td>:null}
      {authUser.U_Type=="Admin"||authUser.U_Type=="Mini Admin"||authUser.U_Type=="Master"||authUser.U_Type=="Super"?<td>{Details.Super_Casino_Commission}</td> :null} 

        <td>{Details.Agent_Casino_Commission}</td>
      <td>{Details.Client_Casino_Commission}</td>
     
      </tr>
      </tbody>
      </table>
      </div>
      <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-xl-24" style={{"margin-top": "20px"}}>
      <h4>Matka Share</h4>
      <table className="table gx-table-bordered partnship" style={{"width": "100%"}}>
      <thead>
      <tr>
      {authUser.U_Type=="Admin"?<th>Admin</th>:null} {authUser.U_Type=="Admin"||authUser.U_Type=="Mini Admin"?<th>Mini Admin</th>:null} {authUser.U_Type=="Admin"||authUser.U_Type=="Mini Admin"||authUser.U_Type=="Master"?<th>Master</th>:null}  {authUser.U_Type=="Admin"||authUser.U_Type=="Mini Admin"||authUser.U_Type=="Master"||authUser.U_Type=="Super"?<th>Super Agent</th>:null} 
      <th>Agent</th>
      </tr>
      </thead>
      <tbody>
      
     <tr>
     {authUser.U_Type=="Admin"? <td>{Details.Admin_Matka_Share}</td> :null}
      {authUser.U_Type=="Admin"||authUser.U_Type=="Mini Admin"?  <td>{Details.Mini_Matka_Share}</td>:null}
      {authUser.U_Type=="Admin"||authUser.U_Type=="Mini Admin"||authUser.U_Type=="Master"? <td>{Details.Master_Matka_Share}</td>:null}
      {authUser.U_Type=="Admin"||authUser.U_Type=="Mini Admin"||authUser.U_Type=="Master"||authUser.U_Type=="Super"? <td>{Details.Super_Matka_Share}</td>:null} 
    <td>{Details.Agent_Matka_Share}</td>
      </tr>
   
      </tbody>
      </table>
      </div>
      <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-xl-24" style={{"margin-top": "20px"}}>
      <h4>Matka Commission</h4>
      <table className="table gx-table-bordered partnship" style={{"width": "100%"}}>
      <thead>
      <tr>
      {authUser.U_Type=="Admin"?<th>Admin</th>:null} {authUser.U_Type=="Admin"||authUser.U_Type=="Mini Admin"?<th>Mini Admin</th>:null} {authUser.U_Type=="Admin"||authUser.U_Type=="Mini Admin"||authUser.U_Type=="Master"?<th>Master</th>:null}  {authUser.U_Type=="Admin"||authUser.U_Type=="Mini Admin"||authUser.U_Type=="Master"||authUser.U_Type=="Super"?<th>Super Agent</th>:null} 
      <th>Agent</th>
      <th>Client</th>
      </tr>
      </thead>
      <tbody>
      <tr>
      {authUser.U_Type=="Admin"?  <td>{Details.Admin_Matka_Commission}</td>:null}
      {authUser.U_Type=="Admin"||authUser.U_Type=="Mini Admin"? <td>{Details.Mini_Matka_Commission}</td> :null}
      {authUser.U_Type=="Admin"||authUser.U_Type=="Mini Admin"||authUser.U_Type=="Master"?  <td>{Details.Master_Matka_Commission}</td>:null}
      {authUser.U_Type=="Admin"||authUser.U_Type=="Mini Admin"||authUser.U_Type=="Master"||authUser.U_Type=="Super"? <td>{Details.Super_Matka_Commission}</td>:null} 
  <td>{Details.Agent_Matka_Commission}</td>
      <td>{Details.Client_Matka_Commission}</td>
     
      </tr>
      </tbody>
      </table>
      </div>
      </div>
   </Modal>
    );
 
}

export default UserParnertshipDetails; 





 