import React, { useEffect, useState } from "react";
import { Table, Spin } from 'antd';
import { getDetailedLedger } from "../../../../appRedux/actions/rex";

const Details = (props) => {
  const [Ledger, setLedger] = useState([]);
  const [TotalBalance, setTotalBalance] = useState(0.00);
  const [loading, setLoading] = useState(true);
  
  const columns = [
    {
      title: 'User Detail',
      dataIndex: 'Name',
      key: 'Name',
      render: (Status, row) => (
        props.user_Type.toLowerCase()=="client"?<span>{row.Name}</span>:
        <a href={"/client/ledger-"+props.d_user_Type.toLowerCase()+"/" + row.U_ID}><span>{row.Name} 👁</span></a>
      ),
    },
    {
      title: 'Balance',
      dataIndex: 'Balance',
      key: 'Balance',
      render: (balance) => (
        <span>{Math.abs(parseFloat(balance || 0)).toFixed(2)}</span>
      ),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const result = await getDetailedLedger( props.user_ID, props.user_Type);

        if (result && Array.isArray(result)) {
          // Filter the Ledger array based on the condition
          const filteredLedger = props.type === "Dena"
            ? result.filter(item => parseFloat(item.Balance || 0) < 0)
            : result.filter(item => parseFloat(item.Balance || 0) > 0);

          setLedger(filteredLedger);

          // Calculate total of Credit column for filtered data
          const totalBalance = filteredLedger.reduce((total, item) => total + parseFloat(item.Balance || 0), 0);
          setTotalBalance(Math.abs(totalBalance).toFixed(2)); // Display absolute value
        } else {
          console.error("Invalid result format:", result);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
if(props.type!=="Clear"){
    fetchData();}else{ setLoading(false);}
  }, [props]); // Add props.type as a dependency

  return (
    <>
      <div className="ant-row gx-center" style={{ "font-size": "24px", "background-color": props.color, "color": "rgb(255, 255, 255)", "margin": "0px", "border-radius": "4px" }}>
        <div className="ant-col ant-col-xs-12 ant-col-sm-12 ant-col-md-12 ant-col-xl-12">
          <div>{props.type}</div>
        </div>
        <div className="ant-col ant-col-xs-12 ant-col-sm-12 ant-col-md-12 ant-col-xl-12" style={{ "text-align": "right" }}>
          <div>{TotalBalance}</div>
        </div>
      </div>
      {loading ? (
        <div style={{ textAlign: 'center', margin: '20px' }}>
          <Spin size="large" />
        </div>
      ) : (
        <Table
          style={{ "margin-top": "10px", "margin-bottom": "20px" }}
          pagination={false}
          width={100}
          size="small"
          bordered
          scroll={{ x: 'max-content' }}
          columns={columns}
          dataSource={Ledger}
        />
      )}
    </>
  );
};

export default Details;