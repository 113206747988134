import React from "react";
import {Route, Switch} from "react-router-dom";
import SportsDetails from "./sports-details/index";   

import LiveReport from "./live-report/index";   
import PL_Segment from "./pl-segment";   
import AgentCommReport from "./agent-comm-report/indexss";   
import PlusMinusType from "./plus-minus-type/index";   
import CompletedMatchSlips from "./completed-match-slips/index";   
import MatchSlips from "./match-slips/index";   
import FancySlips from "./fancy-slips/index";   
import CompletedFancySlips from "./completed-fancy-slips/index";   
import ClientReport from "./client-report/index";   
import rejectedBetsByEvent from "./rejectedBetsByEvent/index";     
const Main = ({match}) => (
  <Switch> 
    <Route path={`${match.url}/:eid/live-report`} component={LiveReport}/>   
    <Route path={`${match.url}/:eid/:bid/live-report`} component={PL_Segment}/>   
  <Route path={`${match.url}/:eid/agent-comm-report`} component={AgentCommReport}/>   
  <Route path={`${match.url}/:eid/plus-minus-type`} component={PlusMinusType}/>   
  <Route path={`${match.url}/:eid/completed-match-slips`} component={CompletedMatchSlips}/>   
  <Route path={`${match.url}/:eid/match-slips`} component={MatchSlips}/>   
  <Route path={`${match.url}/:eid/fancy-slips`} component={FancySlips}/>   
  <Route path={`${match.url}/:eid/completed-fancy-slips`} component={CompletedFancySlips}/>   
  <Route path={`${match.url}/:eid/client-report`} component={ClientReport}/>   
  <Route path={`${match.url}/:eid/rejectedBetsByEvent`} component={rejectedBetsByEvent}/>   
    <Route path={`${match.url}/sports-details`} component={SportsDetails}/>   
  </Switch>
);

export default Main;
