import React,{useEffect,useState} from "react";  
import {  Table } from 'antd';
import {getEvents} from "../../../appRedux/actions/rex" 
import GoBack from "../../components/buttons/GoBack"
 
const ClientReport = () => {
   const [Events, setEvents] = useState({});
   const data = [];
 const columns = [
   
         {
           title: 'Masters',
           dataIndex: 'Masters',
           key: 'Masters'
         },
         {
           title: ' ',
           dataIndex: ' ',
           key: ' ',
     
         }, 
         {
           title: 'Net Amount',
           dataIndex: 'Net Amount',
           key: 'Net Amount',
         },
     
       ];

   useEffect(() => {
      const fetchData = async () => {
     
    await getEvents().then((result)=>{ 
    setEvents( result);  
    });
      };

      fetchData();



     






  }, []);

  return ( 


    <>
    <div className="ant-card ant-card-bordered">
       <div className="ant-card-head gx-mb-3 ">
          <div className="ant-card-head-wrapper">
             <div className="ant-card-head-title">
                <span>
                   <h1 style={{"display": "inline-block"}}>Client Report</h1>
                   <div>...</div>
                </span>
             </div>
             <div className="ant-card-extra"><span><GoBack/></span></div>
          </div>
       </div>
       <div className="ant-card-body ">
       <Table width={100} size='small' bordered scroll={{ x: 'max-content' }} columns={columns} dataSource={data == null ? null : data} />

        
       </div>
    </div>
                </>







  );
};

export default ClientReport;
