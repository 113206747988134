import React,{useState,useEffect} from 'react'; 
import { useParams} from "react-router-dom";  
import { Form, Row, Col,Select,   InputNumber , Input  ,   Button ,DatePicker } from 'antd';  
import { NotificationContainer } from 'react-notifications';
import Notification from "../../../components/notification";
import CashTransactions from "./cash-transactions"

import UserSelector from "./user-selector"
import {makeCashTransactions}  from "../../../../appRedux/actions/rex";
import GoBack from "../../../components/buttons/GoBack"
import moment from 'moment';
const FormItem = Form.Item;
const Option = Select.Option;
     
  const UserTransactions = (props) => {
    const { getFieldDecorator } = props.form;
     const [User, setUser] = useState(false); 
    const {U_Type } = useParams(); 
   var User_Type; 
   
  if(U_Type.toLowerCase()==="admin"){
      User_Type= "Admin" ;  
       }
      else if(U_Type.toLowerCase()==="mini"){
          User_Type=  "Mini Admin" ;  
       }
      else if(U_Type.toLowerCase()==="master"){
          User_Type=  "Master" ;  
       }
      else if(U_Type.toLowerCase()==="super"){
          User_Type=  "Super" ;  
        }
      else if(U_Type.toLowerCase()==="agent"){
          User_Type=  "Agent" ;  
       }
      else if(U_Type.toLowerCase()==="client"){
          User_Type=  "Client" ;  
       }
     
  
       useEffect(() => {
        const fetchData = async () => {
          try {
             //alert(User)
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
      }, [User ]);


       const onUserChange = (value, option) => {
        if(option!==undefined){
        setUser(option.key)  ;
      }
      };
      const handleSubmit =  (e) => {
        e.preventDefault();
    
        props.form.validateFields(async(err, values) => {
          if (!err) {


            values.User=User;
             await makeCashTransactions(values) .then((res)=>{
    
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
             Notification(res.message,res.type )
             props.form.setFieldsValue({   Type: undefined,Amount: "" ,Remark: "" });
     
         
            });
            



          }
        });
      };
    return (
      <>
          
        
          <div  className="ant-card ant-card-bordered">
              <div className="ant-card-head">
                 <div className="ant-card-head-wrapper">
                    <div className="ant-card-head-title">
                       <h1>{User_Type=='Super'?User_Type+" Agent":User_Type} Transactions</h1>
                    </div>
                    <div className="ant-card-extra"><span><GoBack/></span></div>
                 </div>
              </div>
              <div className="ant-card-body">
                 <div className="ant-spin-nested-loading">
                    <div className="ant-spin-container">
                    <Form
        name="advanced_search"
        className="ant-form ant-form-horizontal"
        style={{ margin: "5px" }}
        labelAlign="left"
        labelCol={{
          span: 8,
        }}
        colon={false}
        onSubmit={handleSubmit} // Change to onSubmit event
      >
        <Row>
                  <Col xl={8} md={8} sm={12} xs={24}>
             
               <UserSelector onUserChange={onUserChange} getFieldDecorator={getFieldDecorator} form={props.form} Type={User_Type}/>
            
                  </Col>
                  <Col xl={8} md={8} sm={12} xs={24}>
                    <FormItem
                      name="Collection"
                      label="Collection"
                      wrapperCol={{
                        span: 15,
                      }}
                      rules={[
                        {
                          required: true,
                          message: "Please select a collection",
                        },
                      ]}
                    >
                      
  
   {getFieldDecorator('Collection', {initialValue: "CASH",
rules: [{ required: true, message: 'Please select a collection'}],   
})(
                      <Select
                      defaultValue=  {"CASH"}
                        placeholder="Select Collection"
                        id="Collection"
                        width="100%"
                        allowClear
                      >
                        <Option   value="CASH">CASH A/C</Option>
                      </Select>

)}
                    </FormItem>
                  </Col>
                     <Col  xl={8} md={8} sm={12} xs={24}  >
                                       
                        
                                       <FormItem   name="Post_Datetime"
                          label="Date"        rules={[
                   {
                            required: true,   message: 'Please Select Date',
                   }]}
                   
                   wrapperCol={{
                            span: 15,
                           }}
                          > 
                       {getFieldDecorator('Post_Datetime', { initialValue: moment(),
rules: [{ required: true, message: 'Please Select Date'}],
})(
  <DatePicker style={{ width: '100%' }} id="Post_Datetime" format="DD-MM-YYYY"/>
)}
                      </FormItem>
                   
                   
                                    
                       
                    </Col>
                   </Row>
                                                  
                                       
                   <Row>
                                      <Col   xl={8} md={8} sm={12} xs={24} >
                                       
                        
                                       <FormItem   name="Amount"
                                            label="Amount"  wrapperCol={{
                                              span: 15,
                                             }}
                                            > 
                                        {getFieldDecorator('Amount', {
rules: [{ required: true, message: 'Enter amount'}],
})(   <InputNumber
  id="Amount"
  placeholder="Enter amount"
  style={{ width: '100%' }}
  min={0} // Set minimum value if needed
  step={1} // Set the step for increment/decrement
/>
                           
)}
                                        </FormItem>
                   
                   
                                    
                       
                    </Col>
                    <Col  xl={8} md={8} sm={12} xs={24} >
                                       
                        
                    <FormItem   name="Payment_Type"
                                            label="Payment Type"  wrapperCol={{
                                              span: 15,
                                             }}
                                            > 
                                        {getFieldDecorator('Type', {initialValue: "Credit",
rules: [{ required: true, message: 'Please Select One'}],
})( 
                                         
  
  <Select
     placeholder="Payment Type"
    id="Type"
    width="100%"
    allowClear
  >
           <Option  value="Debit">PAYMENT - DIYA</Option>
                                             <Option value="Credit">RECEIPT - LIYA</Option>
                           
  </Select>

 
                           
)}
                                        </FormItem>
                   
                                    
                       
                    </Col>
                    <Col  xl={8} md={8} sm={12} xs={24}  >
                                       
                        
                                       <FormItem   name="Remark"
                          label="Remark"        rules={[
                   {
                            required: true,   message: 'Please enter name',
                   }]}
                   
                   wrapperCol={{
                            span: 15,
                           }}
                          > 
                       
                       {getFieldDecorator('Remark', {
rules: [{ required: true, message: 'Please enter name'}],
})(     <Input  
                          id="Remark"
                           placeholder="Remark" />
                           
                           )
                           }
                         
                      </FormItem>
                   
                   
                                    
                       
                    </Col>
                   </Row>
                                
                
        <Row>
          <div className="ant-col ant-col-24">
            <Button type="primary" className="ant-btn ant-btn-primary" htmlType="submit">
              Submit
            </Button>{" "}
          </div>
        </Row>
      </Form>
           
                    </div>
                 </div>
              </div>

           </div>
      <div className="ant-card ant-card-bordered">
      <div className="ant-card-body" style={{"padding": "0px"}}>
      <CashTransactions U_ID={User}/>
      <div className="ant-row gx-center">
      <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-xl-24">
       
     </div>
     </div>
     </div>
     </div>
          
          
      <NotificationContainer/>
      
                      </>
    );
   
}
 
export default Form.create()(UserTransactions);
