import React,{useEffect,useState} from "react";  
import {  Table } from 'antd';
import { useParams} from "react-router-dom";  
import {getAgentCommReport} from "../../../appRedux/actions/rex" 
import GoBack from "../../components/buttons/GoBack"
 
const AgentCommReport = () => {
   const [Report, setReport] = useState({});
   const { eid } = useParams();
  
 const columns = [

         {
           title: 'Code',
           dataIndex: 'Code',
           key: 'Code'
         },
         {
           title: 'Name',
           dataIndex: 'Name',
           key: 'Name',
     
         },
         {
           title: 'Match Amt',
           dataIndex: 'Match_Amount',
           key: 'Match_Amount',
           render:(Status,row)=>( 

            <span  style={{"color":row.Match_Amount<0?"red":"green"}} >{row.Match_Amount}</span>
           ), 
          
         },
         {
          title: 'Sess Amt',
          dataIndex: 'Session_Amount',
          key: 'Session_Amount',
          render:(Status,row)=>( 

            <span  style={{"color":row.Session_Amount<0?"red":"green"}} >{row.Session_Amount}</span>
           ), 
          },
        {
          title: 'Total',
          dataIndex: 'Total',
          key: 'Total',
          render:(Status,row)=>( 

            <span  style={{"color":row.Total<0?"red":"green"}} >{row.Total}</span>
           ), 
      },
       {
           title: 'Match Comm+',
           dataIndex: 'Match_Commission',
           key: 'Match_Commission+',
           render:(Status,row)=>( 

            <span  style={{"color":row.Match_Commission<0?"red":"green"}} >{row.Match_Commission}</span>
           ), 
       },
         {
           title: 'Sess Comm+',
           dataIndex: 'Session_Commission',
           key: 'Session_Commission',
           render:(Status,row)=>( 

            <span  style={{"color":row.Session_Commission<0?"red":"green"}} >{row.Session_Commission}</span>
           ), 
      }
         ,
         {
           title: 'Total Comm',
           dataIndex: 'Total_Commission',
           key: 'Total_Commission',
           render:(Status,row)=>( 

            <span  style={{"color":row.Total_Commission<0?"red":"green"}} >{row.Total_Commission}</span>
           ), 
       }
         ,
         {
           title: 'Total Amount',
           dataIndex: 'Total_Amount',
           key: 'Total_Amount',
           render:(Status,row)=>( 

            <span  style={{"color":row.Total_Amount<0?"red":"green"}} >{row.Total_Amount}</span>
           ), 
      }
         ,
         {
           title: 'My Share',
           dataIndex: 'Share',
           key: 'Share',
           render:(Status,row)=>( 

            <span  style={{"color":row.Share<0?"red":"green"}} >{row.Share}</span>
           ), 
       }
         ,
         {
           title: 'Net Amount',
           dataIndex: 'Net_Amount',
           key: 'Net_Amount',
           render:(Status,row)=>( 

            <span  style={{"color":row.Net_Amount<0?"red":"green"}} >{row.Net_Amount}</span>
           ), 
      },
     
       ];

   useEffect(() => {
      const fetchData = async () => {
     
    await getAgentCommReport(eid).then((data)=>{ 
    setReport(data);  
     
    });
      };

      fetchData();
  }, []);
  let totalMatchAmount = 0.00;
  let totalSessionAmount = 0.00;
  let totalTotal = 0.00;
  let totalMatchCommission = 0.00;
  let totalSessionCommission = 0.00;
  let totalTotalCommission = 0.00;
  let totalTotalAmount = 0.00;
  let totalShare = 0.00;
  let totalNetAmount = 0.00;

  if (Report.report) {
    Report.report.forEach((item) => {
      totalMatchAmount += item.Match_Amount || 0.00;
      totalSessionAmount += item.Session_Amount || 0.00;
      totalTotal += item.Total || 0.00;
      totalMatchCommission += item.Match_Commission || 0.00;
      totalSessionCommission += item.Session_Commission || 0.00;
      totalTotalCommission += item.Total_Commission || 0.00;
      totalTotalAmount += item.Total_Amount || 0.00;
      totalShare += item.Share || 0.00;
      totalNetAmount += item.Net_Amount || 0.00;
    });
  }
    totalMatchAmount = totalMatchAmount.toFixed(2);
    totalSessionAmount = totalSessionAmount.toFixed(2);
    totalTotal = totalTotal.toFixed(2);
    totalMatchCommission = totalMatchCommission.toFixed(2);
    totalSessionCommission = totalSessionCommission.toFixed(2);
    totalTotalCommission = totalTotalCommission.toFixed(2);
    totalTotalAmount = totalTotalAmount.toFixed(2);
    totalShare = totalShare.toFixed(2);
    totalNetAmount = totalNetAmount.toFixed(2);
 



   // Create the total row
  const totalRow = {
    key: 'total',
    Code: 'Total',
    Match_Amount: totalMatchAmount,
    Session_Amount: totalSessionAmount,
    Total: totalTotal,
    Match_Commission: totalMatchCommission,
    Session_Commission: totalSessionCommission,
    Total_Commission: totalTotalCommission,
    Total_Amount: totalTotalAmount,
    Share: totalShare,
    Net_Amount: totalNetAmount,
  };
  const rowClassName = (record) => {
    return record.key === 'total' ? 'dateHiglight' : '';
  }
 return ( 


    <>
    <div className="ant-card ant-card-bordered">
       <div className="ant-card-head gx-mb-3 ">
          <div className="ant-card-head-wrapper">
             <div className="ant-card-head-title">
                <span>
                   <h1 style={{"display": "inline-block"}}>{Report.event_name}</h1>
                </span>
             </div>
             <div className="ant-card-extra"><span><GoBack/></span></div>
          </div>
       </div>
       <div className="ant-card-body ">
       <Table width={100} size='small' pagination={false} rowClassName={rowClassName} bordered scroll={{ x: 'max-content' }} columns={columns} dataSource={Report.report == null ?[totalRow] :  [...Report.report, totalRow]} />

        
       </div>
    </div>
                </>







  );
};

export default AgentCommReport;
