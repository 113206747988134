import React,{useEffect,useState} from "react";
import {Col, Row} from "antd";

import {Link} from "react-router-dom";
 
import Auxiliary from "util/Auxiliary"; 
  
import { connect} from "react-redux";

import {getCricket} from "../../../appRedux/actions/rex"
const Dashboard = (props) => {
const authUser=props.authUser;
const [Cricket, setCricket] = useState({});

useEffect(() => {
   const fetchData = async () => {
  
 await getCricket().then((result)=>{ 
    
 setCricket( result);  
 });
   };

   fetchData();
}, []);



function isTimeLessThanTwoHours(time) {
  // Get the current time
  const currentTime = new Date();

  // Calculate the time difference in milliseconds
  const timeDifference = time - currentTime;

  // Convert the time difference to hours
  const hoursDifference = timeDifference / (1000 * 60 * 60);
   // Check if the time difference is less than 2 hours
  return hoursDifference >= 0.30;
}
const GetInplay =(time)=>{
let Inplay ;
const specificTime = new Date(time); // Replace with the specific time you want to compare

if (isTimeLessThanTwoHours(specificTime)) {
  Inplay= false;
         
          
            }else{
              Inplay=true;
            }


            return Inplay
}
 




   return (
    <Auxiliary>
      {authUser?
      <div style={{"padding-top": "10px"}}>
      <section  className="panel panel-default">
    <Row>
        <Col className="ant-col top-box ant-col-xs-12 ant-col-sm-12 ant-col-md-6 ant-col-lg-6 ant-col-xl-6" >
          <span className="fa-stack fa-2x pull-left m-r-sm">
<i className="fa fa-circle fa-stack-2x text-info" >

</i><i className="fa fa-male fa-stack-1x text-white" >

</i>
          </span>

          <a className="clear" href="#"> <span class="top-box-head block m-t-xs"><strong>My User Name</strong></span><small className="top-box-head text-muted text-uc ng-binding" id="limit">{authUser.Name}<span className="ng-binding">({authUser.U_Type.toLowerCase()})</span></small></a>
             
        </Col>
        
        <Col className="ant-col top-box ant-col-xs-12 ant-col-sm-12 ant-col-md-6 ant-col-lg-6 ant-col-xl-6" >
          <span className="fa-stack fa-2x pull-left m-r-sm">
<i className="fa fa-circle fa-stack-2x text-info" >

</i><i className="fa fa-male fa-stack-1x text-white" >

</i>
          </span>

          <a className="clear" href="#"> <span class="top-box-head block m-t-xs"><strong>My Level</strong></span><small className="top-box-head text-muted text-uc ng-binding" id="limit">{authUser.U_Type.toLowerCase()}</small></a>
             
        </Col>


        <Col className="ant-col top-box ant-col-xs-12 ant-col-sm-12 ant-col-md-6 ant-col-lg-6 ant-col-xl-6" >
          <span className="fa-stack fa-2x pull-left m-r-sm">
<i className="fa fa-circle fa-stack-2x text-info" >

</i><i className="fa fa-male fa-stack-1x text-white" >

</i>
          </span>

          <a className="clear" href="#"> <span class="top-box-head block m-t-xs"><strong>Balance</strong></span><small className="top-box-head text-muted text-uc ng-binding" id="limit">{authUser ? authUser.Coins : "Loading"}</small></a>
             
        </Col>


        <Col className="ant-col top-box ant-col-xs-12 ant-col-sm-12 ant-col-md-6 ant-col-lg-6 ant-col-xl-6" >
          <span className="fa-stack fa-2x pull-left m-r-sm">
<i className="fa fa-circle fa-stack-2x text-info" >

</i><i className="fa fa-male fa-stack-1x text-white" >

</i>
          </span>

          <a className="clear" href="#"> <span class="top-box-head block m-t-xs"><strong>Company Contact</strong></span><small className="top-box-head text-muted text-uc ng-binding" id="limit">-</small></a>
             
        </Col>


        <Col className="ant-col top-box ant-col-xs-12 ant-col-sm-12 ant-col-md-6 ant-col-lg-6 ant-col-xl-6" >
          <span className="fa-stack fa-2x pull-left m-r-sm">
<i className="fa fa-circle fa-stack-2x text-info" >

</i><i className="fa fa-male fa-stack-1x text-white" >

</i>
          </span>

          <a className="clear" href="#"> <span class="top-box-head block m-t-xs"><strong>My Share</strong></span><small className="top-box-head text-muted text-uc ng-binding" id="limit">{authUser ? authUser.Match_Share : "Loading"} %</small></a>
             
        </Col>

        <Col className="ant-col top-box ant-col-xs-12 ant-col-sm-12 ant-col-md-6 ant-col-lg-6 ant-col-xl-6" >
          <span className="fa-stack fa-2x pull-left m-r-sm">
<i className="fa fa-circle fa-stack-2x text-info" >

</i><i className="fa fa-male fa-stack-1x text-white" >

</i>
          </span>

          <a className="clear" href="#"> <span class="top-box-head block m-t-xs"><strong>Company Share</strong></span><small className="top-box-head text-muted text-uc ng-binding" id="limit">{authUser ? (100-authUser.Match_Share) : "Loading"} %</small></a>
             
        </Col>

        <Col className="ant-col top-box ant-col-xs-12 ant-col-sm-12 ant-col-md-6 ant-col-lg-6 ant-col-xl-6" >
          <span className="fa-stack fa-2x pull-left m-r-sm">
<i className="fa fa-circle fa-stack-2x text-info" >

</i><i className="fa fa-male fa-stack-1x text-white" >

</i>
          </span>

          <a className="clear" href="#"> <span class="top-box-head block m-t-xs"><strong>Match Commission</strong></span><small className="top-box-head text-muted text-uc ng-binding" id="limit">{authUser ? authUser.Match_Commission : "Loading"} %</small></a>
             
        </Col>


        <Col className="ant-col top-box ant-col-xs-12 ant-col-sm-12 ant-col-md-6 ant-col-lg-6 ant-col-xl-6" >
          <span className="fa-stack fa-2x pull-left m-r-sm">
<i className="fa fa-circle fa-stack-2x text-info" >

</i><i className="fa fa-male fa-stack-1x text-white" >

</i>
          </span>

          <a className="clear" href="#"> <span class="top-box-head block m-t-xs"><strong>Session Commission</strong></span><small className="top-box-head text-muted text-uc ng-binding" id="limit">{authUser ? authUser.Session_Commission : "Loading"} %</small></a>
             
        </Col>
      </Row> 
       </section>
      <section  className="panel panel-default" style={{"padding-top":"50px"}}>
      <Row>
     <>
     {Cricket&&Cricket.length>0?Cricket.map((item) => (
      
      <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-12 ant-col-lg-12 ant-col-xl-12" style={{"padding": "0px"}}>
<div className="ant-card ant-card-bordered ant-card-type-inner" style={{"marginBottom": "0px"}}>
 
           
<Link to={"/Events/"+item.Event_Id+"/live-report"}> 
    
<div className="ant-card-head">
<div className="ant-card-head-wrapper">
<div className="ant-card-head-title" style={{"display": "inline-grid"}}>
 <span>{item.Event_Name}</span>
 <span>{item.Time}</span>
 </div>
  <div className="ant-card-extra">
    {GetInplay(item.Start_Time)?<span class="gx-link"><span class="ant-tag ant-tag-has-color" style={{"background-color": "rgb(58, 115, 46)", "margin-bottom": "0px"}}>INPLAY</span></span>
   :null}
     
    </div>
     </div>
     </div>


</Link> 
     </div>
     </div>

 
)):null}
</>
  </Row>
      </section>
      </div>:null}
    </Auxiliary>
  );
};


const mapStateToProps = () => {
  const authUser = JSON.parse(localStorage.getItem("authUser"));
  const {  token} = authUser;
  return { token, authUser}
}; 

export default connect(mapStateToProps)(Dashboard);