import React, { useEffect, useState, useRef } from "react";
import { useHistory } from 'react-router-dom';
import {  Table, Empty, Spin } from 'antd';
import { getMatchFinalReport } from "../../../appRedux/actions/rex"; 
import { Typography } from 'antd';

const { Text } = Typography;
const customEmptyText = (
  <div>
    <Empty description={<Text disabled><h2>No Data available</h2></Text>} />
  </div>
);

const ProfitLoss = (props) => {
 const setUsertype=(type)=>{
    if(type=="Admin"){
      
      return "Mini Admin"
   }else if(type=="Mini Admin"){

    return "Master"
 }else if(type=="Master"){

  return "Super Agent"
}else if(type=="Super"){

  return "Agent"
}else if(type=="Agent"){

  return "Client" 
}else{
  return false
}
 
   
  }
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [Report, setReport] = useState([]); 
  const authUser=JSON.parse(localStorage.getItem("authUser"));   
  const [Event_Name, setEvent_Name] = useState(null);
  const [Parent, setParent] = useState(authUser.U_Type=="Admin"?true: false);
  const [U_ID, setU_ID] = useState(authUser.ID);
  const [U_Type, setU_Type] = useState(setUsertype(authUser.U_Type));
  const [U_IDHistory, setU_IDHistory] = useState([]);
  const [U_TypeHistory, setU_TypeHistory] = useState([]);
  const [ParentHistory, setParentHistory] = useState([]);
  const columns = [

    {
      title: 'Code',
      dataIndex: 'Code',
      key: 'Code',
      render:(Status,row)=>( 

        <span
        style={{ cursor: row.U_Type !== "Client" ? "pointer" : "default" }}
        onClick={row.U_Type !== "Client" ? () => { handleCodeClick(row) } : null}
      >
        {row.Code}
      </span>
       ), 
    },
    {
      title: 'Name',
      dataIndex: 'Name',
      key: 'Name',

    },
    {
      title: 'Match Amt',
      dataIndex: 'Match_Amount',
      key: 'Match_Amount',
      render:(Status,row)=>( 

       <span  style={{"color":row.Match_Amount<0?"red":"green"}} >{row.Match_Amount}</span>
      ), 
     
    },
    {
     title: 'Sess Amt',
     dataIndex: 'Session_Amount',
     key: 'Session_Amount',
     render:(Status,row)=>( 

       <span  style={{"color":row.Session_Amount<0?"red":"green"}} >{row.Session_Amount}</span>
      ), 
     },
   {
     title: 'Total',
     dataIndex: 'Total',
     key: 'Total',
     render:(Status,row)=>( 

       <span  style={{"color":row.Total<0?"red":"green"}} >{row.Total}</span>
      ), 
 },
  {
      title: 'Match Comm+',
      dataIndex: 'Match_Commission',
      key: 'Match_Commission+',
      render:(Status,row)=>( 

       <span  style={{"color":row.Match_Commission<0?"red":"green"}} >{row.Match_Commission}</span>
      ), 
  },
    {
      title: 'Sess Comm+',
      dataIndex: 'Session_Commission',
      key: 'Session_Commission',
      render:(Status,row)=>( 

       <span  style={{"color":row.Session_Commission<0?"red":"green"}} >{row.Session_Commission}</span>
      ), 
 }
    ,
    {
      title: 'Total Comm',
      dataIndex: 'Total_Commission',
      key: 'Total_Commission',
      render:(Status,row)=>( 

       <span  style={{"color":row.Total_Commission<0?"red":"green"}} >{row.Total_Commission}</span>
      ), 
  }
    ,
    {
      title: 'Total Amount',
      dataIndex: 'Total_Amount',
      key: 'Total_Amount',
      render:(Status,row)=>( 

       <span  style={{"color":row.Total_Amount<0?"red":"green"}} >{row.Total_Amount}</span>
      ), 
 }
    ,
    {
      title:(Parent==false?'My Share' : U_Type=="Client"?'Client':U_Type+' Share') ,
      dataIndex: 'MyShare',
      key: 'MyShare',
      render:(Status,row)=>( 

 row.U_Type=="Client"? <span  style={{"color":row.Total_Amount<0?"red":"green"}} >{ row.Total_Amount }</span> : <span  style={{"color":row.Share<0?"red":"green"}} >{ row.Share }</span> 

      
      ), 
  }
    ,
    {
      title: 'M.App',
      dataIndex: 'M_App',
      key: 'M_App' 
  }
    ,
    {
      title: 'Net Amount',
      dataIndex: 'Net_Amount',
      key: 'Net_Amount',
      render:(Status,row)=>( 

       <span  style={{"color":row.Net_Amount<0?"red":"green"}} >{row.Net_Amount}</span>
      ), 
 },

  ];
   const handleCodeClick = (user) => { 
    if (user.U_Type !== undefined) {
       
 setU_IDHistory((prevHistory) => [...prevHistory, U_ID]); // Push current U_ID to stack
 setU_TypeHistory((prevHistory2) => [...prevHistory2, U_Type]); // Push current U_ID to stack
 setParentHistory((prevHistory3) => [...prevHistory3, ParentHistory]); // Push current U_ID to stack 
 setParent(true);
 setU_ID(user.U_ID);
 setU_Type( setUsertype( user.U_Type));
    }
  };

  const handleGoBack = () => {

    const lastU_ID = U_IDHistory.pop(); // Pop the last U_ID from stack
      if (lastU_ID !== undefined) {
      setU_ID(lastU_ID);
    }else{ 
     history.goBack();
    }
    
    const lastU_Type =U_TypeHistory.pop(); // Pop the last U_ID from stack
    if (lastU_Type !== undefined) {
      setU_Type(lastU_Type);
  }else{history.goBack();
  }
     
   if (U_ID  == authUser.ID) { 
    setParent(false);
}
   


  };
  const GetCompletedMatchBetPL = async () => {
    try {
      setIsLoading(true);
      const result = await getMatchFinalReport(props.EID,U_ID,Parent,props.Day);
      if(result.length>0){
        setEvent_Name(result[0].Event_Name);
         setReport(result); 
     
      }
       
     
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    GetCompletedMatchBetPL();
  }, [props.EID,U_ID,Parent ]);
 
 
 
  let totalMatchAmount = 0.00;
  let totalSessionAmount = 0.00;
  let totalTotal = 0.00;
  let totalMatchCommission = 0.00;
  let totalSessionCommission = 0.00;
  let totalTotalCommission = 0.00;
  let totalTotalAmount = 0.00;
  let totalShare = 0.00;
  let totalM_App  = 0.00;
  let totalNetAmount = 0.00;
 
  if (Report) {
    Report.forEach((item) => {
      totalMatchAmount += parseFloat(item.Match_Amount) || 0.00;
      totalSessionAmount += parseFloat(item.Session_Amount) || 0.00;
      totalTotal += parseFloat(item.Total) || 0.00;
      totalMatchCommission += parseFloat(item.Match_Commission) || 0.00;
      totalSessionCommission += parseFloat(item.Session_Commission) || 0.00;
      totalTotalCommission += parseFloat(item.Total_Commission) || 0.00;
      totalTotalAmount += parseFloat(item.Total_Amount) || 0.00;
      totalShare += parseFloat(item.Share) || 0.00;
      totalM_App += parseFloat(item.M_App) || 0.00;
      totalNetAmount += parseFloat(item.Net_Amount) || 0.00;
    });
  }
    totalMatchAmount = totalMatchAmount.toFixed(2);
    totalSessionAmount = totalSessionAmount.toFixed(2);
    totalTotal = totalTotal.toFixed(2);
    totalMatchCommission = totalMatchCommission.toFixed(2);
    totalSessionCommission = totalSessionCommission.toFixed(2);
    totalTotalCommission = totalTotalCommission.toFixed(2);
    totalTotalAmount = totalTotalAmount.toFixed(2);
    totalShare = totalShare.toFixed(2);
    totalM_App = totalM_App.toFixed(2);
    totalNetAmount = totalNetAmount.toFixed(2);
 
 
 





  const totalRow = {
    key: 'total',
    Code: 'Total',
    Match_Amount: totalMatchAmount,
    Session_Amount: totalSessionAmount,
    Total: totalTotal,
    Match_Commission: totalMatchCommission,
    Session_Commission: totalSessionCommission,
    Total_Commission: totalTotalCommission,
    Total_Amount: totalTotalAmount,
   Share: (U_Type=="Client"?totalTotalAmount:   totalShare),
    M_App: totalM_App,
    Net_Amount: totalNetAmount,
  };
  const rowClassName = (record) => {
    return record.key === 'total' ? 'dateHiglight' : '';
  }

  return (
    <>
 <div className="ant-card ant-card-bordered">
       <div className="ant-card-head gx-mb-3 ">
          <div className="ant-card-head-wrapper">
             <div className="ant-card-head-title">
                <span>
                   <h1>{U_Type=="Client"?"Agent Client":U_Type+"  Company"} Report</h1><span>{Event_Name}</span>
                </span>
             </div>
             <div className="ant-card-extra"><span>   <button type="button" onClick={handleGoBack} className="ant-btn ant-btn-primary"><span>Back</span></button>
         </span></div>
          </div>
       </div>
       <div className="ant-card-body ">
       

      <Spin spinning={isLoading}>
        {Report && Report.length > 0 ? (
          <Table
            width={100}
            size="small"
            bordered
            pagination={false}
            rowClassName={rowClassName}
            scroll={{ x: 'max-content' }}
             locale={{ emptyText: customEmptyText }}
            columns={columns}
            dataSource={Report == null ?[totalRow] :  [...Report, totalRow]}
          />
        ) : (
          <Empty description={<Text disabled><h2>No Bets available on this match</h2></Text>} />
        )}
      </Spin>
        
       </div>
    </div>

    </>
  );
};

export default ProfitLoss;
