import React,{useEffect,useState } from "react";    
import {  Table , Empty , Spin } from 'antd';
import { useParams  } from "react-router-dom"; 
 import {getLiveFancyBets } from "../../../appRedux/actions/rex";
 import { Typography } from 'antd';
 
 const { Text } = Typography;
 const customEmptyText = (
  <div>
  <Empty  description={<Text disabled><h2>No Bets available on this session</h2></Text>} />
</div>
);

const FancyBets = (props) => { 
  const [isLoading, setIsLoading] = useState(false);
  const [ Fancies,setFancies ] = useState([])  
   const { eid } = useParams()  

   

    const GetFancyBets =async()=>{

  await getLiveFancyBets(eid,props.Bet).then(async(result)=>{  
        
setFancies(result);
 
setIsLoading(false);
      });


    }  
    useEffect(() => {
      setIsLoading(true);
      GetFancyBets(); // This will run only once when the component mounts
    }, [props.Bet]);
   
 
const columns = [
   
  {
    title: 'Rate',
    dataIndex: 'Rate',
    key: 'Rate',
       },
  {
    title: 'Volume',
    dataIndex: 'Volume',
    key: 'Volume', 
  },
  {
    title: 'Amount',
    dataIndex: 'Amount',
    key: 'Amount',
       },
  {
    title: 'Type',
    dataIndex: 'Type',
    key: 'Type',
     },
  {
    title: 'Team',
    dataIndex: 'Team',
    key: 'Team',
  },
  {
    title: 'Client',
    dataIndex: 'Client',
    key: 'Client',
  }
  ,
  {
    title: 'Agent',
    dataIndex: 'Agent',
    key: 'Agent',
  }
  ,
  {
    title: 'Date',
    dataIndex: 'Date',
    key: 'Date',
  }
  ,
  {
    title: 'Loss',
    dataIndex: 'Loss',
    key: 'Loss',
  }
  ,
  {
    title: 'Profit',
    dataIndex: 'Profit',
    key: 'Profit',
  }
];
const getRowClassName = (record) => { 
  if (record.Type == "YES") {
   return 'backbet';  
 } else if (record.Type == "NO") {
      return 'laybet';  
  }
  return '';  
};
  return ( 
    <>
  
  <div className="ant-card ant-card-bordered" style={{marginBottom:"5px"}}>
  <div className="ant-card-head">
    <div className="ant-card-head-wrapper">
      <div className="ant-card-head-title">Fancy Bets - [ {Fancies?Fancies.length:0} ]</div>
      <div className="ant-card-extra">
        <span>
          <button onClick={()=>{props.action(false)}} type="button" className="ant-btn ant-btn-primary">
            <span> Match Bets </span>
          </button>
        </span>
      </div>
    </div>
  </div>
  <div className="ant-card-body" style={{ padding: "0px", "max-height": "500px", "overflow-y": "scroll"}}>
  <Spin spinning={isLoading}>
  <Table width={100} size='small' bordered   pagination={false} scroll={{x: 'max-content'}} rowClassName={getRowClassName} locale={{ emptyText:customEmptyText , }} columns={columns} dataSource={Fancies?Fancies==null?null:Fancies:null} />
 </Spin>
  </div>
</div>
                </>







  );
};

export default FancyBets;
