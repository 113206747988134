


import React, { useState} from 'react';
import {Modal } from 'antd';
const WithdrawalMatkaLimit =(state)=>  { 
  const [_Modal, setModal] = useState({Visible:state.visible });  
 
  const handleOk = e => {
    setModal({Visible:false, Title:"Withraw Matka Limit - "});                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          
    
   };

  const handleCancel = e => {
  
     setModal({Visible:false});
   ;
   };
    return (
   
  <Modal   title={<h1>{"Withraw Matka Limit - "+state.code}</h1>} okText='Submit' cancelText='Return'  afterClose={state.CloseModal}  visible={_Modal.Visible}    onOk={handleOk}  onCancel={handleCancel} >
  <h1>Curr Limit : {state.coins}</h1><p>
    <input type="number" placeholder="matak limit" className="ant-input" value=""/></p>
    
   
      
   </Modal>
    );
 
}

export default WithdrawalMatkaLimit; 





 