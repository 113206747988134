import React  from "react";  
import { Link ,useParams} from "react-router-dom";  
import UserList from "../../components/tables/UserList"
import GoBack from "../../components/buttons/GoBack"
const ListUsers = () => {
   
 const {U_Type } = useParams(); 
 var User_Type;
 var DUser_Type;
if(U_Type.toLowerCase()==="admin"){
    User_Type= "Admin" ;  
    DUser_Type= "Mini Admin" ;  
    }
    else if(U_Type.toLowerCase()==="mini"){
        User_Type=  "Mini Admin" ;  
        DUser_Type= "Master" ;  
    }
    else if(U_Type.toLowerCase()==="master"){
        User_Type=  "Master" ;  
        DUser_Type= "Super" ;  
    }
    else if(U_Type.toLowerCase()==="super"){
        User_Type=  "Super" ;  
        DUser_Type= "Agent" ;  
    }
    else if(U_Type.toLowerCase()==="agent"){
        User_Type=  "Agent" ;  
        DUser_Type= "Client" ;  
    }
    else if(U_Type.toLowerCase()==="client"){
        User_Type=  "Client" ;  
        DUser_Type= "" ;  
    }
     
 

    return (<> 
<div className="ant-card ant-card-bordered">
   <div className="ant-card-head">
      <div className="ant-card-head-wrapper">
         <div className="ant-card-head-title">
            <span>
               <h1 style={{"display": "inline-block"}}>{User_Type=='Super'?User_Type+" Agent":User_Type} Details</h1>
            </span>
         </div>
         <div className="ant-card-extra"><span><GoBack/></span></div>
      </div>
   </div>
   <div className="ant-card-body">
      <div className="ant-row">
      <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 ant-col-xl-24" style={{"text-align": "center"}}>
                <div style={{"marginTop": "10px"}}>
                <Link   className="ant-btn ant-btn-primary" to={"/client/create-"+U_Type}>
                     <span>+ Create</span>
                  </Link>
                  
                 </div>
             </div>
      </div>
      <div className="ant-table-wrapper">
         <div className="ant-spin-nested-loading">
            <div className="ant-spin-container">
                {}
            <UserList User_Type={User_Type}   DUser_Type={DUser_Type}   />
            </div>
         </div>
       
      </div>
   </div>
</div> 
            </>
               );
 

 
}


 
 
 
export default ListUsers;
