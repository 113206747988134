 
import React, { useEffect, useState } from "react";
import { getMatchOddsLivePLBooks } from "../../../appRedux/actions/rex"; 
const Team_PL = (props) => {

    const [ MatchOddsLivePLBooks, setMatchOddsLivePLBooks] = useState([]); 

    const GetMatchOddsLivePLBooks = async () => {
        try {
          const result = await getMatchOddsLivePLBooks(props.EID);
       try{   if(MatchOddsLivePLBooks){ 
     
          setMatchOddsLivePLBooks(result.MyBook);
          }}catch{}
      
        } catch (error) {
          console.error("Error fetching data:", error);
        }  
      };
    
      useEffect(() => {
        GetMatchOddsLivePLBooks();
      }, [props.EID ]);
    
    return (
       <>  
{MatchOddsLivePLBooks?  
  MatchOddsLivePLBooks.map(Book  => (
               
             
        <div class="ant-col ant-col-xs-12 ant-col-sm-12 ant-col-md-6 ant-col-lg-6 ant-col-xl-6">
        <div class={(Book.Balance>=0?"gx-bg-teal":"gx-bg-red")+" ant-card  ant-card gx-card-widget gx-card-full gx-p-3 gx-text-white ant-card-bordered ant-card-bordered"}>
           <div class="ant-card-body">
              <div class="ant-card-body">
                 <div class="gx-media gx-align-items-center gx-flex-nowrap">
                    <div class="gx-mr-2 gx-mr-xxl-3"><i class="icon icon-chart  gx-fs-icon-lg"></i></div>
                    <div class="gx-media-body">
                       <h1 class="gx-fs-xxl gx-font-weight-semi-bold gx-mb-1 gx-text-white">{Book.Balance}</h1>
                       <h2 class="gx-mb-0 gx-text-white">{Book.Selection_Name}</h2>
                    </div>
                 </div>
              </div>
           </div>
        </div>
     </div>
      )):null} 
     
     
     </>
    );
 };
 
 export default Team_PL;