function calculatePercentage(value, percentage) {
  return (value * percentage) / 100;
}


function calculateMyShare(Bet) {
  if (Bet.Admin_Id === Bet.U_ID) {
    return 100 - Bet.Mini_Share;
  } else if (Bet.Mini_Id === Bet.U_ID) {
    return Bet.Mini_Share - Bet.Master_Share;
  } else if (Bet.Master_Id === Bet.U_ID) {
    return Bet.Master_Share - Bet.Super_Share;
  } else if (Bet.Super_Id === Bet.U_ID) {
    return Bet.Super_Share - Bet.Agent_Share;
  } else if (Bet.Agent_Id === Bet.U_ID) {
    return Bet.Agent_Share;
  } else {
    return 0;
  }
}

function getCompanyCommissionOnSessionBet(Bet) {
  let Commission = 0.00;

  
     
  
       if (Bet.U_Type === "Client" && getCommission_ID(Bet ) === Bet.U_ID) {
        Commission = calculatePercentage(Bet.Stake, getCommissionRateOnBet(Bet ));
      } else if (Bet.U_Type === "Client" && getCommission_ID(Bet) !== Bet.U_ID) {
        Commission = 0.00;
      } else if (Bet.U_Type !== "Client" && getCommission_ID(Bet) === Bet.CID) {
            Commission = calculatePercentage(Bet.Stake, getCommissionRateOnBet(Bet));
      } else if (Bet.U_Type !== "Client" && getCommission_ID(Bet) !== Bet.CID) {
     Commission = calculatePercentage(Bet.Stake, getCommissionRateOnBet(Bet ));
      } else {
        Commission = 0.00;
      }
     
   
  return Commission;
}

function getBetProfitLoss(Bet) {
   
  if (Bet.Game_Type === "Odds") {
      const winLossOdds = calculateWinLoss(Bet);
      if (winLossOdds === 'WIN') {
        return Bet.U_Type === "Client" ? Bet.Profit : -Bet.Profit;
      } else if (winLossOdds === 'LOSS') {
       return Bet.U_Type === "Client" ? -Bet.Loss : Bet.Loss;
      } else {
        return 'false';
      }
    } else if (Bet.Game_Type === "Session") {
      const winLossSession = calculateWinLoss(Bet);
  
      if (winLossSession === 'WIN') {
        return Bet.U_Type === "Client" ? Bet.Profit : -Bet.Profit;
      } else if (winLossSession === 'LOSS') {
        return Bet.U_Type === "Client" ? -Bet.Loss : Bet.Loss;
      } else {
        return 'false';
      }
    } else {
      return 0.00;
    }
     
 
}

 function getCommissionRateOnBet(Bet ) {
  let response = false;
let CommId =getCommission_ID(Bet);
   
     if (CommId ===Bet.Admin_Id && Bet.Game_Type === "Odds") {
      
      response = Bet.Admin_Odds_Comm;
    } else if (CommId === Bet.Mini_Id && Bet.Game_Type === "Odds") {
      response = Bet.Mini_Odds_Comm;
    } else if (CommId === Bet.Master_Id && Bet.Game_Type === "Odds") {
      response = Bet.Master_Odds_comm;
    } else if (CommId === Bet.Super_Id && Bet.Game_Type === "Odds") {
      response = Bet.Super_Odds_Comm;
    } else if (CommId === Bet.Agent_Id && Bet.Game_Type === "Odds") {
      response = Bet.Agent_Odds_Comm;
    } else if (CommId === Bet.CID && Bet.Game_Type === "Odds") {
      response = Bet.Client_Odds_Comm;
      
    } else if (CommId===  Bet.Admin_Id && Bet.Game_Type === "Session") {
                                 
    response = Bet.Admin_Sess_Comm;
    } else if (CommId === Bet.Mini_Id && Bet.Game_Type === "Session") {
      response = Bet.Mini_Sess_Comm;
    } else if (CommId === Bet.Master_Id && Bet.Game_Type === "Session") {
      response = Bet.Master_Sess_Comm;
    } else if (CommId === Bet.Super_Id && Bet.Game_Type === "Session") {
      response = Bet.Super_Sess_Comm;
    } else if (CommId === Bet.Agent_Id && Bet.Game_Type === "Session") {
      response = Bet.Agent_Sess_Comm;
    } else if (CommId === Bet.CID && Bet.Game_Type === "Session") {
      response = Bet.Client_Sess_Comm;
    } else {
      response = 0.00;
    }
  
  return response;
}
function getCommission_ID(Bet) {
  let commission_ID;

    if (Bet.Game_Type === 'Session') {
      if (Bet.Client_Sess_Comm > 0) {
        commission_ID = Bet.CID;
      } else if (Bet.Agent_Sess_Comm > 0) {
        commission_ID = Bet.Agent_Id;
      } else if (Bet.Super_Sess_Comm > 0) {
        commission_ID = Bet.Super_Id;
      } else if (Bet.Master_Sess_Comm > 0) {
        commission_ID = Bet.Master_Sess_comm;
      } else if (Bet.Mini_Sess_Comm > 0) {
        commission_ID = Bet.Mini_Id;
      } else if (Bet.Admin_Sess_Comm > 0) {
        commission_ID = Bet.Admin_Id;
      }
    } else if (Bet.Game_Type === 'Odds') {
      if (Bet.Client_Odds_Comm > 0) {
        commission_ID = Bet.CID;
      } else if (Bet.Agent_Odds_Comm > 0) {
        commission_ID = Bet.Agent_Id;
      } else if (Bet.Super_Odds_Comm > 0) {
        commission_ID = Bet.Super_Id;
      } else if (Bet.Master_Odds_Comm > 0) {
        commission_ID = Bet.Master_Id;
      } else if (Bet.Mini_Odds_Comm > 0) {
        commission_ID = Bet.Mini_Id;
      } else if (Bet.Admin_Sess_Comm > 0) {
        commission_ID = Bet.Admin_Id;
      }
    }
     

  return commission_ID;
}

function calculateWinLoss(Bet) {
  let response = 'false';
   // Simulating the SQL query with an array of Bets
 
  // Find the bet with the given Bet_ID
     
     if (Bet.Game_Type === 'Session') {
      if (
        (Bet.Bet_Type === 'YES' && Bet.Gem_Result   >= Bet.Bet_Value) ||
        (Bet.Bet_Type === 'NO' && Bet.Gem_Result < Bet.Bet_Value)
      ) {
  response = 'WIN';
      } else {
        response = 'LOSS';
      }
    } else if (Bet.Game_Type === 'Odds') {
      if (
        (Bet.Bet_Type === 'LAGAI' && Bet.Gem_Result === Bet.Player_ID) ||
        (Bet.Bet_Type === 'KHAI' && Bet.Gem_Result !== Bet.Player_ID)
      ) {
        response = 'WIN';
      } else {
        response = 'LOSS';
      }
    }
  

  return response;
}
function getWinLossOnBet(Bet) {
  let Winner = false;

  if (Bet.Game_Type === 'Session') {
    Winner = (Bet.Bet_Type === 'YES' && Bet.Gem_Result >= Bet.Bet_Value) || (Bet.Bet_Type === 'NO' && Bet.Gem_Result < Bet.Bet_Value);
  } else if (Bet.Game_Type === 'Odds') {
    Winner = (Bet.Bet_Type === 'LAGAI' && Bet.Gem_Result === Bet.Player_ID) || (Bet.Bet_Type === 'KHAI' && Bet.Gem_Result !== Bet.Player_ID);
  }

  if (Bet.CID === Bet.U_ID) {
    // No change needed, Winner already has the correct value
  } else {
    // Invert the value of Winner
    Winner = !Winner;
  }

  return Winner;
}

 function getMyCommissionOnOddsBet(Bets) {
  
  let Amount = 0.00;
  let CommissionID = 0.00;
  let CommissionRate =0.00 ; 
        let   WinLoss=false;
  for (const bet of Bets) {
    const  WinLossOnBet = getWinLossOnBet(bet);
   let   _CommissionID = getCommission_ID(bet);
   let   _CommissionRate = getCommissionRateOnBet(bet);
   if (_CommissionID>CommissionID){CommissionID=_CommissionID}
   if (_CommissionRate>CommissionRate){CommissionRate=_CommissionRate}



    let _Amount = 0.00;

    if (bet.U_ID === bet.CID && WinLossOnBet === true) {
      _Amount = bet.Profit ;
    } else if (bet.U_ID === bet.CID && WinLossOnBet === false) {
      _Amount =  -bet.Loss ;
    } 
    else if (bet.U_ID != bet.CID && WinLossOnBet === true) {
      _Amount = bet.Loss;
    }  
    else if (bet.U_ID != bet.CID && WinLossOnBet === false) {
      _Amount =  -bet.Profit ;
    }  
    else {
      _Amount = 0.00;
    }

    Amount  += _Amount;


 
  }
 
  if (Amount>0){
  WinLoss=true
}else if (Amount<0){
  WinLoss=false
}



let Commission = 0.00;
 


if (Bets[0].U_ID == Bets[0].CID && WinLoss) {
  Commission = 0.0;
} else if (Bets[0].U_ID != Bets[0].CID && !WinLoss) {
  Commission = 0.0;

} else if (Bets[0].U_ID == Bets[0].CID && !WinLoss && CommissionID == Bets[0].CID) {

  Commission = calculatePercentage(Math.abs(Amount), CommissionRate);

} else if (Bets[0].U_ID != Bets[0].CID && WinLoss && CommissionID == Bets[0].U_ID) {

  Commission = calculatePercentage(Math.abs(Amount), CommissionRate);

}  else {
  Commission = 0.0;
}




  return Commission;
}

function calculateTotalPL(Total_MatchPL, Total_Company_Commission, MyShare, My_MatchCommission) {
  // Calculate the commission components individually
  let matchPLCommission = Total_MatchPL - Total_Company_Commission;
  let myShareCommission = MyShare ? calculatePercentage(matchPLCommission, MyShare) : 0.00;
  let myMatchCommission = My_MatchCommission >= 0 ? My_MatchCommission : 0.00;

  // Sum up the commission components
  let totalCommission = myShareCommission + myMatchCommission;

  // Return the total commission
  return totalCommission;
}
function getCompanyCommissionOnOddsByBet(Bets) {
  let Amount = 0.00;
  let CommissionID = 0.00;
  let CommissionRate =0.00 ; 
let   WinLoss=false;
  for (const bet of Bets) {
    const  WinLossOnBet = getWinLossOnBet(bet);
   let   _CommissionID = getCommission_ID(bet);
   let   _CommissionRate = getCommissionRateOnBet(bet);
   if (_CommissionID>CommissionID){CommissionID=_CommissionID}
   if (_CommissionRate>CommissionRate){CommissionRate=_CommissionRate}



    let _Amount = 0.00;

    if (bet.U_ID === bet.CID && WinLossOnBet === true) {
      _Amount = bet.Profit ;
    } else if (bet.U_ID === bet.CID && WinLossOnBet === false) {
      _Amount =  -bet.Loss ;
    } 
    else if (bet.U_ID != bet.CID && WinLossOnBet === true) {
      _Amount = bet.Loss;
    }  
    else if (bet.U_ID != bet.CID && WinLossOnBet === false) {
      _Amount =  -bet.Profit ;
    }  
    else {
      _Amount = 0.00;
    }

    Amount  += _Amount;


 
  }
  
  if (Amount>0){
  WinLoss=true
}else if (Amount<0){
  WinLoss=false
}



let Commission = 0.00;
 


if (Bets[0].U_ID == Bets[0].CID && WinLoss) {
  Commission = 0.0;
} else if (Bets[0].U_ID != Bets[0].CID && !WinLoss) {
  Commission = 0.0;
} else if (Bets[0].U_ID == Bets[0].CID && !WinLoss && CommissionID == Bets[0].CID) {
  Commission = calculatePercentage(Math.abs(Amount), CommissionRate);
} else if (Bets[0].U_ID == Bets[0].CID && !WinLoss && CommissionID != Bets[0].CID) {
  Commission = 0.0;
} else if (Bets[0].U_ID != Bets[0].CID && WinLoss && CommissionID == Bets[0].CID) {
  Commission = calculatePercentage(Math.abs(Amount), CommissionRate);
} else if (Bets[0].U_ID != Bets[0].CID && WinLoss && CommissionID != Bets[0].CID) {
  Commission = calculatePercentage(Math.abs(Amount), CommissionRate);
} else {
  Commission = 0.0;
}




  return Commission;
}
 


// Export the functions
module.exports = {calculateTotalPL, getCompanyCommissionOnSessionBet,getCompanyCommissionOnOddsByBet,getMyCommissionOnOddsBet,getCommissionRateOnBet,calculateMyShare,calculatePercentage,
  getBetProfitLoss,
   getCommission_ID,
  calculateWinLoss, 
};
