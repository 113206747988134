
import React, {useState} from "react";
import ChangePassword from "../../routes/components/modals/ChangePassword";
import {  Menu } from "antd";
import { NotificationContainer } from 'react-notifications';
const ChangePassMenu =(props)=>{
  const [_Modal, setModal] = useState({Modal:null,Visible:false }); 
  const CloseModal = e => {
    setModal({Visible:false  });
  };

  
  var  showChangePass = (e) => { 
   
       
   setModal({Modal:ChangePassword,Visible:true });
  };
return( 
   <>  <Menu>
          <Menu.Item key="0">
        <span  onClick={() =>showChangePass()}>Change Password</span>
      </Menu.Item>
      <Menu.Item key="1">
        <span onClick={() => props.userSignOut()}>Logout</span>
      </Menu.Item>
    </Menu>

{_Modal.Visible?<_Modal.Modal title={_Modal.Title} visible={_Modal.Visible} CloseModal={CloseModal}     />:null}
<NotificationContainer />
  </>
)
}
export default ChangePassMenu;