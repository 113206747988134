import React,{useEffect,useState } from "react";   
import { useParams  } from "react-router-dom";  
import {  Table , Empty, Spin  } from 'antd';
 import {getLiveCompletedFancyBetsPL } from "../../../appRedux/actions/rex";
 import { Typography } from 'antd';
     
 const { Text } = Typography;
 const customEmptyText = (
  <div>
  <Empty  description={<Text disabled><h2>No Bets available on this session</h2></Text>} />
</div>
);

const ProfitLoss = (props) => {
    const [isLoading, setIsLoading] = useState(false);
 
  const [ CompletedFancies,setCompletedFancies ] = useState([])  
  

  
const columns = [
  
    {
        title: 'Username',
        dataIndex: 'Username',
        key: 'Username',render: (text, record) => (
          <span>{record.Name} ({record.Username})</span>
        ),
    },
    {
        title: 'Date',
        dataIndex: 'Date',
        key: 'Date',
    },
    {
        title: 'Selection',
        dataIndex: 'Selection',
        key: 'Selection',
    },
    {
        title: 'Result',
        dataIndex: 'Result',
        key: 'Result',
    },
    {
        title: 'Back/Lay',
        dataIndex: 'Type',
        key: 'Type',
    },
    {
        title: 'Value',
        dataIndex: 'Value',
        key: 'Value',
    },
    {
        title: 'Volume',
        dataIndex: 'Volume',
        key: 'Volume',
    },
    {
        title: 'Stake',
        dataIndex: 'Stake',
        key: 'Stake',
    },
    {
        title: 'P&L',
        dataIndex: 'PL',
        key: 'PL',
        
        render: (text, record) => (
          <span>{Math.abs(parseFloat(text)).toFixed(2)}</span>
        ),
    },
    {
        title: 'WinLoss',
        dataIndex: 'WinLoss',
        key: 'WinLoss',
        
    },
                                  
 ];
 const filteredColumns = columns.filter(column => column.key !== 'WinLoss');

   const GetCompletedFancyBetsPL =async()=>{

 await getLiveCompletedFancyBetsPL(props.EID,props.BID ).then(async(result)=>{  
      
  setCompletedFancies(result);
  setIsLoading(false);


     });


   }
  useEffect(() => {
   const fetchData = async () => {
    setIsLoading(true);

  await   GetCompletedFancyBetsPL();


   };

   fetchData();
},   [props.EID]    );
 
 
 
const getRowClassName = (record) => { 
    if (record.WinLoss == "WIN") {
     return 'bg-green';  
   } else if (record.WinLoss =="LOSS" ) {
        return 'bg-red';  
    }
    return '';  
  };

  return ( 
 
    <Spin spinning={isLoading}>
 
  <Table width={100} size='small' bordered  pagination={false} scroll={{x: 'max-content'}}    rowClassName={getRowClassName}  locale={{ emptyText:customEmptyText , }} columns={filteredColumns}  dataSource={CompletedFancies?CompletedFancies==null?null:CompletedFancies:null}   />
 </Spin>
        







  );
};

export default ProfitLoss;
