import React, {Component} from "react";
import {connect} from "react-redux";
import {Menu,Icon} from "antd";
import {Link} from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import Auxiliary from "util/Auxiliary";  
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";

const SubMenu = Menu.SubMenu;
class SidebarContent extends Component {


    getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
    getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };
  render(){ 
    const {themeType, navStyle, pathname} = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1]; 
     const authUser = JSON.parse(localStorage.getItem("authUser"));
       return (<Auxiliary>
 
        <SidebarLogo/>
        <div className="gx-sidebar-content" style={{"height": "inherit","overflow": "scroll","padding-bottom":"100px"}}> 
            
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline" onClick={ this.props.onClick }>

           
              <Menu.Item key="dashboard">
                <Link to="/dashboard"><i aria-label="icon: home" className="anticon anticon-home gx-text-primary" style={{"fontSize": "20px"}}>
                  <svg viewBox="64 64 896 896" focusable="false" className="" data-icon="home" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M946.5 505L560.1 118.8l-25.9-25.9a31.5 31.5 0 0 0-44.4 0L77.5 505a63.9 63.9 0 0 0-18.8 46c.4 35.2 29.7 63.3 64.9 63.3h42.5V940h691.8V614.3h43.4c17.1 0 33.2-6.7 45.3-18.8a63.6 63.6 0 0 0 18.7-45.3c0-17-6.7-33.1-18.8-45.2zM568 868H456V664h112v204zm217.9-325.7V868H632V640c0-22.1-17.9-40-40-40H432c-22.1 0-40 17.9-40 40v228H238.1V542.3h-96l370-369.7 23.1 23.1L882 542.3h-96.1z"></path></svg></i>
                  <span style={{"marginLeft":"0"}}><IntlMessages id="sidebar.dashboard"/></span></Link>
              </Menu.Item>

              <SubMenu key={authUser.U_Type.toLowerCase()+"-details"} popupClassName={this.getNavStyleSubMenuClass(navStyle)}
                       title={<span style={{"align-items": "center"}}> <i className="icon icon-avatar gx-text-primary"/>
                         <span><IntlMessages id={"sidebar."+authUser.U_Type.toLowerCase()+"-details"}/></span></span>}>
                 
                 
{(authUser.U_Type==="Admin")?
                   <Menu.Item disabled key="client/list-admin">
                  <Link to="/client/list-admin">
                     <span><IntlMessages id="sidebar.details.list-admin"/></span>
                  </Link>
                </Menu.Item>:null}
                
{(authUser.U_Type==="Admin")?
                <Menu.Item key="client/list-mini">
                  <Link to="/client/list-mini">
                     <span><IntlMessages id="sidebar.details.list-mini"/></span>
                  </Link>
                   </Menu.Item>:null}
               
{(authUser.U_Type==="Admin"||authUser.U_Type==="Mini Admin")?
                <Menu.Item key="client/list-master">
                  <Link to="/client/list-master">
                     <span><IntlMessages id="sidebar.details.list-master"/></span>
                  </Link>
                   </Menu.Item>:null}
               

                
                 
{(authUser.U_Type==="Admin"||authUser.U_Type==="Mini Admin"||authUser.U_Type==="Master")?
                <Menu.Item key="client/list-super">
                  <Link to="/client/list-super">
                     <span><IntlMessages id="sidebar.details.list-super"/></span>
                  </Link>
                </Menu.Item>
                 
                  :null}
           
                 
{(authUser.U_Type==="Admin"||authUser.U_Type==="Mini Admin"||authUser.U_Type==="Master"||authUser.U_Type==="Super")?
     <Menu.Item key="client/list-agent">
                  <Link to="/client/list-agent">
                     <span><IntlMessages id="sidebar.details.list-agent"/></span>
                  </Link>
                </Menu.Item>  
                  :null}
                  
{(authUser.U_Type==="Admin"||authUser.U_Type==="Mini Admin"||authUser.U_Type==="Master"||authUser.U_Type==="Super"||authUser.U_Type==="Agent")?
                <Menu.Item key="client/list-client">
                  <Link to="/client/list-client">
                    <span><IntlMessages id="sidebar.details.list-client"/></span>
                  </Link>
                </Menu.Item>    
                  :null}   


              </SubMenu>
               <SubMenu key="sports-details" popupClassName={this.getNavStyleSubMenuClass(navStyle)}
                       title={<span> <i className="icon icon-chart gx-text-primary"/>
                         <span style={{"align-items": "center"}}><IntlMessages id="sidebar.sports-details"/></span></span>}>
                <Menu.Item key="sports-details/cricket">
                  <Link to="/Events/sports-details/cricket">
                     <span><IntlMessages id="sidebar.sports-details.cricket"/></span>
                  </Link>
                </Menu.Item>
                <Menu.Item disabled key="sports-details/casino-details">
                  <Link to="/Casino/casino-details">
                     <span><IntlMessages id="sidebar.sports-details.casino-details"/></span>
                  </Link>
                </Menu.Item>
                <Menu.Item disabled key="sports-details/matka-details">
                  <Link to="/matka/matka-details">
                     <span><IntlMessages id="sidebar.sports-details.matka-details"/></span>
                  </Link>
                </Menu.Item>
                
              </SubMenu>
              <SubMenu key="ledger" popupClassName={this.getNavStyleSubMenuClass(navStyle)}
                       title={<span style={{"align-items": "center"}}> <Icon type="book" className="gx-text-primary" style={{"font-size": "20px"}} /> 
                         <span  style={{"marginLeft":"0"}}><IntlMessages id="sidebar.ledger"/></span></span>}>
             
{(authUser.U_Type!=="Admin")?
   <Menu.Item key="ledger/my-ledger">
                  <Link to="/client/my-ledger">
                     <span style={{"align-items": "center"}}><IntlMessages id="sidebar.ledger.ledger"/></span>
                  </Link>
                </Menu.Item>:
                <Menu.Item key="ledger/match-ledger">
                  <Link to="/client/match-ledger">
                     <span style={{"align-items": "center"}}><IntlMessages id="sidebar.ledger.ledger-match"/></span>
                  </Link>
                </Menu.Item>
              }
              
              {(authUser.U_Type==="Admin")? 
 <Menu.Item key="ledger/ledger-mini">
                  <Link to="/client/ledger-mini">
                     <span><IntlMessages id="sidebar.ledger.ledger-mini"/></span>
                  </Link>
                </Menu.Item>:null}
                
                {(authUser.U_Type==="Admin"||authUser.U_Type==="Mini Admin")? 
 <Menu.Item key="ledger/ledger-master">
                  <Link to="/client/ledger-master">
                     <span><IntlMessages id="sidebar.ledger.ledger-master"/></span>
                  </Link>
                </Menu.Item>:null}
            
{(authUser.U_Type==="Admin"||authUser.U_Type==="Mini Admin"||authUser.U_Type==="Master")?
    <Menu.Item key="ledger/ledger-super">
                  <Link to="/client/ledger-super">
                     <span><IntlMessages id="sidebar.ledger.ledger-super"/></span>
                  </Link>
                </Menu.Item>:null}
                
{(authUser.U_Type==="Admin"||authUser.U_Type==="Mini Admin"||authUser.U_Type==="Master"||authUser.U_Type==="Super")?
                <Menu.Item key="ledger/ledger-agent">
                  <Link to="/client/ledger-agent">
                     <span><IntlMessages id="sidebar.ledger.ledger-agent"/></span>
                  </Link>
                </Menu.Item>:null}
                
{(authUser.U_Type==="Admin"||authUser.U_Type==="Mini Admin"||authUser.U_Type==="Master"||authUser.U_Type==="Super"||authUser.U_Type==="Agent")?
                <Menu.Item key="ledger/ledger-client">
                  <Link to="/client/ledger-client">
                     <span><IntlMessages id="sidebar.ledger.ledger-client"/></span>
                  </Link>
                </Menu.Item>:null}
              
       {(authUser.U_Type==="Agent")?
                <Menu.Item key="ledger/agent-commission">
                  <Link to="/client/agent-commission">
                     <span><IntlMessages id="sidebar.ledger.agent-commission"/></span>
                  </Link>
                </Menu.Item>:null}


{(authUser.U_Type==="Admin")?   <Menu.Item key="ledger/partnership">
                  <Link to="/client/partnership">
                     <span><IntlMessages id="sidebar.ledger.partnership"/></span>
                  </Link>
                </Menu.Item>:null}
                
              </SubMenu>
              <SubMenu key="cash-transanction" popupClassName={this.getNavStyleSubMenuClass(navStyle)}
                       title={<span style={{"align-items": "center"}}> <i className="icon icon-revenue-new gx-fs-xlxl gx-text-primary gx-d-flex"/>
                         <span><IntlMessages id="sidebar.cash-transanction"/></span></span>}>
              
{(authUser.U_Type==="Admin"||authUser.U_Type==="Mini Admin"||authUser.U_Type==="Master"||authUser.U_Type==="Super"||authUser.U_Type==="Agent")?  <Menu.Item key="cash-transanction/txn-client">
                  <Link to="/client/txn-client">
                     <span><IntlMessages id="sidebar.cash-transanction.txn-client"/></span>
                  </Link>
                </Menu.Item>:null}
               
{(authUser.U_Type==="Admin"||authUser.U_Type==="Mini Admin"||authUser.U_Type==="Master"||authUser.U_Type==="Super")? <Menu.Item key="cash-transanction/txn-agent">
                  <Link to="/client/txn-agent">
                     <span><IntlMessages id="sidebar.cash-transanction.txn-agent"/></span>
                  </Link>
                </Menu.Item>:null}
             
{(authUser.U_Type==="Admin"||authUser.U_Type==="Mini Admin"||authUser.U_Type==="Master")?   <Menu.Item key="cash-transanction/txn-super">
                  <Link to="/client/txn-super">
                     <span><IntlMessages id="sidebar.cash-transanction.txn-super"/></span>
                  </Link>
                </Menu.Item>:null}
               
                {(authUser.U_Type==="Admin"||authUser.U_Type==="Mini Admin")?   <Menu.Item key="cash-transanction/txn-master">
                  <Link to="/client/txn-master">
                     <span><IntlMessages id="sidebar.cash-transanction.txn-master"/></span>
                  </Link>
                </Menu.Item>:null}
{(authUser.U_Type==="Admin")?   <Menu.Item key="cash-transanction/txn-mini">
                  <Link to="/client/txn-mini">
                     <span><IntlMessages id="sidebar.cash-transanction.txn-mini"/></span>
                  </Link>
                </Menu.Item>:null}
                
{(authUser.U_Type==="Admin")?  <Menu.Item key="cash-transanction/txn-admin">
                  <Link to="/client/txn-admin">
                     <span><IntlMessages id="sidebar.cash-transanction.txn-admin"/></span>
                  </Link>
                </Menu.Item>:null}
             
{(authUser.U_Type==="Admin")?    
                <Menu.Item key="cash-transanction/share-admin">
                  <Link to="/client/share-admin">
                     <span><IntlMessages id="sidebar.cash-transanction.share-admin"/></span>
                  </Link>
                </Menu.Item>:null}
                      
              </SubMenu>
              <SubMenu key="old-ledger"  disabled  popupClassName={this.getNavStyleSubMenuClass(navStyle)}
                       title={<span style={{"align-items": "center"}}>  <Icon type="book" className="gx-text-primary" style={{"font-size": "20px"}} /> 
                         <span><IntlMessages id="sidebar.old-ledger"/></span></span>}>
  
  <Menu.Item key="old-ledger/old-ledger">
                  <Link to="/client/old-ledger">
                     <span style={{"align-items": "center"}}><IntlMessages id="sidebar.ledger.ledger"/></span>
                  </Link>
                </Menu.Item>
                
                {(authUser.U_Type==="Admin" )?    
 <Menu.Item key="old-ledger/oldledger-mini">
                  <Link to="/client/oldledger-mini">
                     <span><IntlMessages id="sidebar.ledger.ledger-mini"/></span>
                  </Link>
                </Menu.Item>:null}
                
                {(authUser.U_Type==="Admin"||authUser.U_Type==="Mini Admin")?    
 <Menu.Item key="old-ledger/oldledger-master">
                  <Link to="/client/oldledger-master">
                     <span><IntlMessages id="sidebar.ledger.ledger-master"/></span>
                  </Link>
                </Menu.Item>:null}
             
{(authUser.U_Type==="Admin"||authUser.U_Type==="Mini Admin"||authUser.U_Type==="Master")?  
  <Menu.Item key="ledger/oldledger-super">
                  <Link to="/client/oldledger-super">
                     <span><IntlMessages id="sidebar.ledger.ledger-super"/></span>
                  </Link>
                </Menu.Item>:null}
              
{(authUser.U_Type==="Admin"||authUser.U_Type==="Mini Admin"||authUser.U_Type==="Master"||authUser.U_Type==="Super")? 
  <Menu.Item key="ledger/oldledger-agent">
                  <Link to="/client/oldledger-agent">
                     <span><IntlMessages id="sidebar.ledger.ledger-agent"/></span>
                  </Link>
                </Menu.Item>:null}
              
{(authUser.U_Type==="Admin"||authUser.U_Type==="Mini Admin"||authUser.U_Type==="Master"||authUser.U_Type==="Super"||authUser.U_Type==="Agent")? 
  <Menu.Item key="ledger/oldledger-client">
                  <Link to="/client/oldledger-client">
                     <span><IntlMessages id="sidebar.ledger.ledger-client"/></span>
                  </Link>
                </Menu.Item>:null}
                {(authUser.U_Type==="Admin")?  
                  <Menu.Item key="ledger/partnership">
                  <Link to="/client/partnership">
                     <span><IntlMessages id="sidebar.ledger.partnership"/></span>
                  </Link>
                </Menu.Item>:null}



               
              </SubMenu>
              <SubMenu key="old-transanction" disabled  popupClassName={this.getNavStyleSubMenuClass(navStyle)}
                       title={<span style={{"align-items": "center"}}> <i className="icon icon-revenue-new gx-fs-xlxl gx-text-primary gx-d-flex"/>
                         <span><IntlMessages id="sidebar.old-transanction"/></span></span>}>
                         {(authUser.U_Type==="Admin"||authUser.U_Type==="Mini Admin"||authUser.U_Type==="Master"||authUser.U_Type==="Super"||authUser.U_Type==="Agent")? 
                         <Menu.Item key="old-transanction/oldtxn-client">
                  <Link to="/client/oldtxn-client">
                     <span><IntlMessages id="sidebar.cash-transanction.txn-client"/></span>
                  </Link>
                </Menu.Item>:null}
               
{(authUser.U_Type==="Admin"||authUser.U_Type==="Mini Admin"||authUser.U_Type==="Master"||authUser.U_Type==="Super")? 
 <Menu.Item key="old-transanction/oldtxn-agent">
                  <Link to="/client/oldtxn-agent">
                     <span><IntlMessages id="sidebar.cash-transanction.txn-agent"/></span>
                  </Link>
                </Menu.Item>:null}
              
{(authUser.U_Type==="Admin"||authUser.U_Type==="Mini Admin"||authUser.U_Type==="Master")? 
  <Menu.Item key="old-transanction/oldtxn-super">
                  <Link to="/client/oldtxn-super">
                     <span><IntlMessages id="sidebar.cash-transanction.txn-super"/></span>
                  </Link>
                </Menu.Item>:null}
                {(authUser.U_Type==="Admin"||authUser.U_Type==="Mini Admin")?  
               <Menu.Item key="old-transanction/oldtxn-master">
                  <Link to="/client/oldtxn-master">
                     <span><IntlMessages id="sidebar.cash-transanction.txn-master"/></span>
                  </Link>
                </Menu.Item>:null}
                {(authUser.U_Type==="Admin" )?  
               <Menu.Item key="old-transanction/oldtxn-mini">
                  <Link to="/client/oldtxn-mini">
                     <span><IntlMessages id="sidebar.cash-transanction.txn-mini"/></span>
                  </Link>
                </Menu.Item>:null}
                {(authUser.U_Type==="Admin")?  
               <Menu.Item key="old-transanction/oldtxn-admin">
                  <Link to="/client/oldtxn-admin">
                     <span><IntlMessages id="sidebar.cash-transanction.txn-admin"/></span>
                  </Link>
                </Menu.Item>:null}
                {(authUser.U_Type==="Admin")?  
                <Menu.Item key="old-transanction/oldshare-admin">
                  <Link to="/client/oldshare-admin">
                     <span><IntlMessages id="sidebar.cash-transanction.share-admin"/></span>
                  </Link>
                </Menu.Item>:null}
 
                
              </SubMenu>
              <SubMenu key="settings" popupClassName={this.getNavStyleSubMenuClass(navStyle)}
                       title={<span style={{"align-items": "center"}}> <Icon type="setting" className="gx-text-primary" style={{"font-size": "20px"}} />  
                         <span><IntlMessages id="sidebar.settings"/></span></span>}>
                
                <Menu.Item key="settings/markets">
                  <Link to="/markets">
                     <span><IntlMessages id="sidebar.settings.markets"/></span>
                  </Link>
                </Menu.Item>

    
  {(authUser.U_Type==="Admin")?
                    <Menu.Item key="settings/deadmini">
                  <Link to="/client/deadmini">
                     <span><IntlMessages id="sidebar.settings.deadmini"/></span>
                  </Link>
                </Menu.Item>

                 :
                 null
                 
                 } 

{(authUser.U_Type==="Mini Admin")?
                    
                    <Menu.Item key="settings/deadmaster">
                    <Link to="/client/deadmaster">
                       <span><IntlMessages id="sidebar.settings.deadmaster"/></span>
                    </Link>
                  </Menu.Item>
            
                 :
                 null
                 
                 } 

{(authUser.U_Type==="Master")?
                    
                 <Menu.Item key="settings/deadsuper">
                  <Link to="/client/deadmaster">
                     <span><IntlMessages id="sidebar.settings.deadsuper"/></span>
                  </Link>
                </Menu.Item>
            
                 :
                 null
                 
                 } 

{(authUser.U_Type==="Super")?
               <Menu.Item key="settings/deadagent">
                  <Link to="/client/deadmaster">
                     <span><IntlMessages id="sidebar.settings.deadagent"/></span>
                  </Link>
                </Menu.Item>
              
                 :
                 null
                 
                 } 
                   {(authUser.U_Type==="Agent")?
                   <Menu.Item key="settings/deadclient">
                  <Link to="/client/deadmaster">
                     <span><IntlMessages id="sidebar.settings.deadclient"/></span>
                  </Link>
                </Menu.Item>
                 :
                 null
                 
                 } 
                 
                 
               
                
              </SubMenu>
        
          </Menu>
          {/* </CustomScrollbars> */}
        </div>
      </Auxiliary>
    );
   }
  }

SidebarContent.propTypes = {};
const mapStateToProps = ({settings}) => {
  const {navStyle, themeType, locale, pathname} = settings;
   return {navStyle, themeType, locale, pathname}
};
export default connect(mapStateToProps)(SidebarContent);

