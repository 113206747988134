import { AlipaySquareFilled } from '@ant-design/icons';
import React,{useEffect}  from 'react'; 
import {_getCricketDetails } from  './_getCricketDetails' ; 
import { _getMyLedger} from  './_getMyLedger' ;  
import { _getDetailLedger} from  './_getDetailLedger' ; 
import {getUserState} from "./Auth";
import axios from 'util/Api'  

  
  
//      const getSelectUplineSuper = async() => {
    
//       if (authUser!==null){
//     return axios.post('user/info/upline/'+authUser.U_Type+'/'+authUser.ID+'/Super'
    
//      ).then(({data}) => {
//    //  localStorage.setItem("UplineSupers", JSON.stringify( data.uplines));  
//    return  data.uplines
//      }).catch(function (error) {
//         console.log("Error****:", error.message);
//      });
//     }
// };

  
//export {getSelectUplineSuper};

export const getDownlineUsers = async (Type,U_ID,Status) => {
  try {  
     const authUser=JSON.parse(localStorage.getItem("authUser"));  
      if (authUser!==null){
      await (axios.defaults.headers.common['access-token'] = "Bearer " + localStorage.getItem("token")) ;
    const response = await axios.post('user/info/downline/'+(U_ID?U_ID:authUser.ID)+'/'+Type+'/'+Status);
   const data = await response.data;
   // alert(JSON.stringify(data.users))
     return   await data.users ;
  
  }
  }
  catch (e) {
    console.log('We have the error', e);
  }
}

export const getUserExpo = async (U_ID) => {
   try {  
     const authUser=JSON.parse(localStorage.getItem("authUser"));  
      if (authUser!==null){
      await (axios.defaults.headers.common['access-token'] = "Bearer " + localStorage.getItem("token")) ;
    const response = await axios.post('user/info/exposure/'+U_ID);
   const data = await response.data;
   // alert(JSON.stringify(data.users))
     return   await data.exposure ;
  
  }
  }
  catch (e) {
    console.log('We have the error', e);
  }
}
export const getSelectUplineSuper = async (Type) => {
  try {      

    const authUser=JSON.parse(localStorage.getItem("authUser"));  
    if (authUser!==null){
    await (axios.defaults.headers.common['access-token'] = "Bearer " + localStorage.getItem("token")) ;
  const response = await axios.post('user/info/downline/details/'+authUser.ID+'/'+Type);
 const data = await response.data;
 // alert(JSON.stringify(data.users))
   return   await data.users ;
  
  }
  }
  catch (e) {
    console.log('We have the error', e);
  }
}


export const getUserParent = async (U_ID) => {
  try {       
     const authUser=JSON.parse(localStorage.getItem("authUser"));  
    if (authUser!==null){
    await (axios.defaults.headers.common['access-token'] = "Bearer " + localStorage.getItem("token")) ;
 
     const response = await axios.post('user/info/user-parent/'+U_ID );
 const data = await response.data;
 // alert(JSON.stringify(data.users))
   return   await data.details ;
  
  }
  }
  catch (e) {
    console.log('We have the error', e);
  }
}
export const getPartnership = async (U_ID) => {
  try {      

    const authUser=JSON.parse(localStorage.getItem("authUser"));  
    if (authUser!==null){
    await (axios.defaults.headers.common['access-token'] = "Bearer " + localStorage.getItem("token")) ;
  const response = await axios.post('user/info/partnership/'+U_ID );
 const data = await response.data;
 // alert(JSON.stringify(data.users))
   return   await data.details ;
  
  }
  }
  catch (e) {
    console.log('We have the error', e);
  }
}


export const getCricket = async () => {
  try { 
 
           
     const authUser=JSON.parse(localStorage.getItem("authUser"));  

      if (authUser!==null){
      await (axios.defaults.headers.common['access-token'] = "Bearer " + localStorage.getItem("token")) ;
    const response = await axios.post('user/info/cricket/'+authUser.ID+"/"+authUser.U_Type);
   const data = await response.data; 
     return   await data.cricket ;
  
  }
  }
  catch (e) {
    console.log('We have the error', e);
  }
}
export const getEvents = async () => {
  try { 
 
    
     const authUser=JSON.parse(localStorage.getItem("authUser"));  

      if (authUser!==null){
      await (axios.defaults.headers.common['access-token'] = "Bearer " + localStorage.getItem("token")) ;
    const response = await axios.post('user/sports/events/'+authUser.ID+"/"+authUser.U_Type);
   const data = await response.data; 
     

     return   await data.details ;
  
  }
  }
  catch (e) {
    console.log('We have the error', e);
  }
}

export const  getRejectedBets = async (EID) => {
  try { 
 
           
     const authUser=JSON.parse(localStorage.getItem("authUser"));  

      if (authUser!==null){
      await (axios.defaults.headers.common['access-token'] = "Bearer " + localStorage.getItem("token")) ;
    const response = await axios.post('user/sports/events/rejected/'+EID+"/"+authUser.ID+"/"+authUser.U_Type);
   const data = await response.data; 
     return   await data.bets ;
  
  }
  }
  catch (e) {
    console.log('We have the error', e);
  }
}



export const getMarket_PL = async () => {
  try { 
 
           
     const authUser=JSON.parse(localStorage.getItem("authUser"));  

      if (authUser!==null){
      await (axios.defaults.headers.common['access-token'] = "Bearer " + localStorage.getItem("token")) ;
    const response = await axios.post('user/market/pl/'+authUser.ID);
   const data = await response.data; 
     return   await data.events ;
  
  }
  }
  catch (e) {
    console.log('We have the error', e);
  }
}
export const getMyLedger = async (Parent) => {
  try { 
 
           
     const authUser=JSON.parse(localStorage.getItem("authUser"));  
 
      if (authUser!==null){
      await (axios.defaults.headers.common['access-token'] = "Bearer " + localStorage.getItem("token")) ;
    const response = await axios.post('user/finance/my-ledger/'+authUser.ID );
    const data = await response.data; 
     

     return   await data.ledger ;
   
  }
  }
  catch (e) {
    console.log('We have the error', e);
  }
}
export const getMatchLedger = async (Parent) => {
  try { 
 
           
     const authUser=JSON.parse(localStorage.getItem("authUser"));  
 
      if (authUser!==null){
      await (axios.defaults.headers.common['access-token'] = "Bearer " + localStorage.getItem("token")) ;
    const response = await axios.post('user/finance/match-ledger/'+authUser.ID );
    const data = await response.data; 
     

     return   await data.ledger ;
   
  }
  }
  catch (e) {
    console.log('We have the error', e);
  }
}

export const getCashTransactions = async (U_ID,Parent) => {
  try { 
 
           
     const authUser=JSON.parse(localStorage.getItem("authUser"));  
 
      if (authUser!==null){
      await (axios.defaults.headers.common['access-token'] = "Bearer " + localStorage.getItem("token")) ;
    const response = await axios.post('user/finance/cash-transaction/'+U_ID+"/"+Parent);
   const data = await response.data; 
     return   await data.ledger ;
  
  }
  }
  catch (e) {
    console.log('We have the error', e);
  }
}
export const getDetailedLedger = async (U_ID,U_Type) => {
  try { 
 
            
 
      if (U_ID!==null){
      await (axios.defaults.headers.common['access-token'] = "Bearer " + localStorage.getItem("token")) ;
    const response = await axios.post('user/finance/ledger/detailed/'+U_ID+"/"+U_Type);
   const data = await response.data; 
   
    

     return   await data.ledger ;
  }
  }
  catch (e) {
    console.log('We have the error', e);
  }
}
export const getMatchFinalReport = async (eid,U_ID,Parent,Day) => {
  try {   if (U_ID!==null){
     
        await (axios.defaults.headers.common['access-token'] = "Bearer " + localStorage.getItem("token")) ;
    const response = await axios.post('user/sports/event/'+eid+'/match-final-report/'+U_ID+"/"+Parent+'/'+Day );
   const data = await response.data;  
 
     return   await data.report ;
  }
   
  }
  catch (e) {
    console.log('We have the error', e);
  }
}

export const getEventDetails = async (eid) => {
  try { 
 
           
     const authUser=JSON.parse(localStorage.getItem("authUser"));  

      if (authUser!==null){
      await (axios.defaults.headers.common['access-token'] = "Bearer " + localStorage.getItem("token")) ;
    const response = await axios.post('user/sports/event/'+eid);
   const data = await response.data;  
     return   await data.event ;
  
  }
  }
  catch (e) {
    console.log('We have the error', e);
  }
}
export const getMatchOddsLivePLBooks = async (eid) => {
  
          
  try { 
 
           
    const authUser=JSON.parse(localStorage.getItem("authUser"));  

     if (authUser!==null){
     await (axios.defaults.headers.common['access-token'] = "Bearer " + localStorage.getItem("token")) ;
   const response = await axios.post('user/sports/event/'+eid+"/match-odds-live-pl-books/"+authUser.ID);
  const data = await response.data;  

    return   await data.Books ;
 
 }
 }
 catch (e) {
   console.log('We have the error', e);
 }
     
   
}


export const getLiveFancyBets = async (eid,bet) => {
  try { 
 
           
     const authUser=JSON.parse(localStorage.getItem("authUser"));  

      if (authUser!==null){
      await (axios.defaults.headers.common['access-token'] = "Bearer " + localStorage.getItem("token")) ;
    const response = await axios.post('user/sports/event/'+eid+"/fancy/"+bet+"/"+authUser.ID);
   const data = await response.data;  
     return   await data.bets ;
  
  }
  }
  catch (e) {
    console.log('We have the error', e);
  }
}

export const getLiveMatchBets = async (eid ) => {
  try { 
 
           
     const authUser=JSON.parse(localStorage.getItem("authUser"));  

      if (authUser!==null){
      await (axios.defaults.headers.common['access-token'] = "Bearer " + localStorage.getItem("token")) ;
    const response = await axios.post('user/sports/event/'+eid+"/match/"+authUser.ID);
   const data = await response.data;  
     return   await data.bets ;
  
  }
  }
  catch (e) {
    console.log('We have the error', e);
  }
}

export const getLiveCompletedFancyBets = async (eid ) => {
  try { 
 
           
     const authUser=JSON.parse(localStorage.getItem("authUser"));  

      if (authUser!==null){
      await (axios.defaults.headers.common['access-token'] = "Bearer " + localStorage.getItem("token")) ;
    const response = await axios.post('user/sports/event/'+eid+"/completed-fancy/"+authUser.ID);
   const data = await response.data;  
     return   await data.bets ;
  
  }
  }
  catch (e) {
    console.log('We have the error', e);
  }
}

export const getCompletedFancyBetsPL = async (eid ) => {
  try { 
 
           
     const authUser=JSON.parse(localStorage.getItem("authUser"));  

      if (authUser!==null){
      await (axios.defaults.headers.common['access-token'] = "Bearer " + localStorage.getItem("token")) ;
    const response = await axios.post('user/sports/event/'+eid+"/completed-fancy-pl/"+btoa(false)+"/"+authUser.ID);
   const data = await response.data;  
     return   await data.bets ;
  
  }
  }
  catch (e) {
    console.log('We have the error', e);
  }
}
export const getCompletedMatchBetsPL = async (eid ) => {
  try { 
 
           
     const authUser=JSON.parse(localStorage.getItem("authUser"));  

      if (authUser!==null){
      await (axios.defaults.headers.common['access-token'] = "Bearer " + localStorage.getItem("token")) ;
    const response = await axios.post('user/sports/event/'+eid+"/completed-match-pl/"+authUser.ID);
   const data = await response.data;  
     return   await data.bets ;
  
  }
  }
  catch (e) {
    console.log('We have the error', e);
  }
}
export const getLiveCompletedFancyBetsPL = async (eid,bid ) => {
  try { 
 
           
     const authUser=JSON.parse(localStorage.getItem("authUser"));  

      if (authUser!==null){
      await (axios.defaults.headers.common['access-token'] = "Bearer " + localStorage.getItem("token")) ;
    const response = await axios.post('user/sports/event/'+eid+"/completed-fancy-pl/"+bid+"/"+authUser.ID);
   const data = await response.data;  
     return   await data.bets ;
  
  }
  }
  catch (e) {
    console.log('We have the error', e);
  }
}
export const getLiveCompletedFancySegment = async (Data,SegmentID ) => {
  try { 
 
           
      
      if (Data.U_ID!==null){

      await (axios.defaults.headers.common['access-token'] = "Bearer " + localStorage.getItem("token")) ;
    const response = await axios.post('user/sports/event/'+Data.EID+"/completed-fancy-segment/"+Data.BID+"/"+SegmentID);
   const data = await response.data;  
     return   await data.segment ;
  
  }
  }
  catch (e) {
    console.log('We have the error', e);
  }
}
export const getMatchBetsPL = async (eid ) => {
  try { 
 
           
     const authUser=JSON.parse(localStorage.getItem("authUser"));  

      if (authUser!==null){
      await (axios.defaults.headers.common['access-token'] = "Bearer " + localStorage.getItem("token")) ;
    const response = await axios.post('user/sports/event/'+eid+"/match-pl/"+authUser.ID);
   const data = await response.data;  
     return   await data.bets ;
  
  }
  }
  catch (e) {
    console.log('We have the error', e);
  }
}
export const getFancyBetsPL = async (eid ) => {
  try { 
 
           
     const authUser=JSON.parse(localStorage.getItem("authUser"));  

      if (authUser!==null){
      await (axios.defaults.headers.common['access-token'] = "Bearer " + localStorage.getItem("token")) ;
    const response = await axios.post('user/sports/event/'+eid+"/fancy-pl/"+authUser.ID);
   const data = await response.data;  
     return   await data.bets ;
  
  }
  }
  catch (e) {
    console.log('We have the error', e);
  }
}









export const getRangeProfitLoss = async (eid,bet) => {
  try { 
 
           
     const authUser=JSON.parse(localStorage.getItem("authUser"));  

      if (authUser!==null){
      await (axios.defaults.headers.common['access-token'] = "Bearer " + localStorage.getItem("token")) ;
    const response = await axios.post('user/sports/event/'+eid+"/range/"+bet+"/"+authUser.ID);
   const data = await response.data;  
     return   await data.range ;
  
  }
  }
  catch (e) {
    console.log('We have the error', e);
  }
}
export const getAgentCommReport = async (Eid) => {
  try { 
 
           
     const authUser=JSON.parse(localStorage.getItem("authUser"));  

      if (authUser!==null){
      await (axios.defaults.headers.common['access-token'] = "Bearer " + localStorage.getItem("token")) ;
    const response = await axios.post('user/sports/events/agentcommreport/'+Eid +'/'+authUser.ID);
   const data = await response.data;  
     return   await data ;
  
  }
  }
  catch (e) {
    console.log('We have the error', e);
  }
}
export const updateUser = async (U_ID,Type,Value) => {
  try { 
       const authUser=JSON.parse(localStorage.getItem("authUser"));  

      if (authUser!==null){
      await (axios.defaults.headers.common['access-token'] = "Bearer " + localStorage.getItem("token")) ;
    const response = await (Type==="Status"? axios.post('user/info/update/status',{U_ID:U_ID,Type:Type,Value:Value,Updater:authUser.ID}): axios.post('user/info/update',{U_ID:U_ID,Type:Type,Value:Value,Updater:authUser.ID}));
   const data = await response.data;  

   if(data.success){
 return  {type:"Success",message:data.message}   ;
    
   }else{

    return  {type:"Error",message:data.error  }   ;
     
   }
    
  
  }
  }
  catch (e) {
    
    return  {type:"Error",message:"Something wrong! Please try again." }   ; ;
  }
}

export const makeTransactions = async (Params) => {
  try { 
 
     const authUser=JSON.parse(localStorage.getItem("authUser"));   
      if (authUser!==null){
    // Params._From=authUser.ID; 
     await (axios.defaults.headers.common['access-token'] = "Bearer " + localStorage.getItem("token")) ;
    const response = await axios.post('user/transactions/create',Params);
    const data = await response.data;  
   if(data.success){
      await getUserState() ;
     
       return  {type:"Success",message:data.message}   ;
 
      
    
   }else{

    return  {type:"Error",message:data.error  }   ;
     
   }
    
  
  }
  }
  catch (e) {
    alert(e.message)
 
    return  {type:"Error",message:"Something wrong! Please try again." }   ; 
  }
}
export const makeCashTransactions = async (Params) => {
  try { 
 
     const authUser=JSON.parse(localStorage.getItem("authUser"));  

      if (authUser!==null){
     Params.By=authUser.ID; 
     await (axios.defaults.headers.common['access-token'] = "Bearer " + localStorage.getItem("token")) ;
    const response = await axios.post('user/transactions/cash/create',Params);
   const data = await response.data;  

   if(data.success){
    await getUserState() ;
     
 return  {type:"Success",message:data.message}   ;
    
   }else{

    return  {type:"Error",message:data.error  }   ;
     
   }
    
  
  }
  }
  catch (e) {
    
    return  {type:"Error",message:"Something wrong! Please try again." }   ; ;
  }
}
export const changePassword = async (Params) => {
  try { 
 
     const authUser=JSON.parse(localStorage.getItem("authUser"));  

      if (authUser!==null){
     Params.ID=authUser.ID; 
     await (axios.defaults.headers.common['access-token'] = "Bearer " + localStorage.getItem("token")) ;
    const response = await axios.post('user/change-password',Params);
   const data = await response.data;  

   if(data.success){
 return  {type:"Success",message:data.message}   ;
    
   }else{

    return  {type:"Error",message:data.error  }   ;
     
   }
    
  
  }
  }
  catch (e) {
    
    return  {type:"Error",message:"Something wrong! Please try again." }   ; ;
  }
}

export const resetPassword = async (Params) => {
  try {  
     const authUser=JSON.parse(localStorage.getItem("authUser"));  

      if (authUser!==null){
     Params.U_ID=authUser.ID; 
     await (axios.defaults.headers.common['access-token'] = "Bearer " + localStorage.getItem("token")) ;
    const response = await axios.post('user/reset-password',Params);
   const data = await response.data;  

   if(data.success){
 return  {type:"Success",message:data.message}   ;
    
   }else{

    return  {type:"Error",message:data.error  }   ;
     
   }
    
  
  }
  }
  catch (e) {
    
    return  {type:"Error",message:"Something wrong! Please try again." }   ; ;
  }
}

export const getStatement = async (U_ID) => {
  try { 
 
     const authUser=JSON.parse(localStorage.getItem("authUser"));  

      if (authUser!==null){
    
     await (axios.defaults.headers.common['access-token'] = "Bearer " + localStorage.getItem("token")) ;
    const response = await axios.post('user/transactions/'+U_ID);
   const data = await response.data;  

   return   await data.transactions ;
  
    
  
  }
  }
  catch (e) {
    
    return  {type:"Error",message:"Something wrong! Please try again." }   ; ;
  }
}



export const getAgentsCommissionClients  = async () => {
  
          
  try { 
 
           
    const authUser=JSON.parse(localStorage.getItem("authUser"));  

     if (authUser!==null){
     await (axios.defaults.headers.common['access-token'] = "Bearer " + localStorage.getItem("token")) ;
   const response = await axios.post('user/finance/agents-commission/'+authUser.ID+'/clients');
  const data = await response.data;  

    return   await data.clients ;
 
 }
 }
 catch (e) {
   console.log('We have the error', e);
 }
     
   
}


export const getAgentsCommissionLenden  = async (C_ID) => {
  
          
  try { 
 
           
    const authUser=JSON.parse(localStorage.getItem("authUser"));  

     if (authUser!==null){
     await (axios.defaults.headers.common['access-token'] = "Bearer " + localStorage.getItem("token")) ;
   const response = await axios.post('user/finance/agents-commission-lenden/'+authUser.ID+'/'+C_ID);
  const data = await response.data;  

    return   await data.lenden ;
 
 }
 }
 catch (e) {
   console.log('We have the error', e);
 }
     
   
}

export const ResetAgentsCommissionLenden  = async (C_ID) => {
  
          
  try { 
 
           
    const authUser=JSON.parse(localStorage.getItem("authUser"));  

     if (authUser!==null){
     await (axios.defaults.headers.common['access-token'] = "Bearer " + localStorage.getItem("token")) ;
   const response = await axios.post('user/finance/reset-commission-lenden/'+authUser.ID+'/'+C_ID);
  const data = await response.data;  

    return   await data.success ;
 
 }
 }
 catch (e) {
   console.log('We have the error', e);
 }
     
   
}

export const getTransactions = async (U_ID) => {
  try { 
 
     const authUser=JSON.parse(localStorage.getItem("authUser"));   
      if (authUser!==null){
    
     await (axios.defaults.headers.common['access-token'] = "Bearer " + localStorage.getItem("token")) ;
    const response = await axios.post('user/transactions/'+authUser.ID);
   const data = await response.data;  

   return   await data.transactions ;
  
    
  
  }
  }
  catch (e) {
    
    return  {type:"Error",message:"Something wrong! Please try again." }   ; ;
  }
}





export const getActvityLogs = async (U_ID) => {
  try { 
 
     const authUser=JSON.parse(localStorage.getItem("authUser"));   
      if (authUser!==null){
    
     await (axios.defaults.headers.common['access-token'] = "Bearer " + localStorage.getItem("token")) ;
    const response = await axios.post('user/info/activities/'+U_ID);
   const data = await response.data;  

   return   await data.activities ;
  
    
  
  }
  }
  catch (e) {
    
    return  {type:"Error",message:"Something wrong! Please try again." }   ; ;
  }
}