import React, {  useRef,  useEffect,useState} from 'react'; import {   Table } from 'antd'; 
import { NotificationContainer } from 'react-notifications';
import Notification from "../../components/notification";
import {getDownlineUsers,updateUser} from "../../../appRedux/actions/rex";
const DeadUser = (props) => { 
const User_Type = props.User_Type;
const U_ID =props.U_ID; 
const [Users, setUsers] = useState([]); 


const UpdateUser = async(u_id,type,value) => {

  await updateUser(u_id,type,value).then((res)=>{
    getUsers();
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
    Notification(res.message,res.type )
  
  });
  
                                                
  
   
  };


const getUsers=()=>{
  getDownlineUsers(User_Type,U_ID?U_ID:null,"Inactive").then((result)=>{ 
 
 setUsers( result );  
 
});
}
 
useEffect(() => {
  const fetchData = async () => {
 
    getUsers();
  };

  fetchData();
},User_Type , []);
  const columns = [
    {
      title: 'Name',
      dataIndex: 'Code',
      key: 'Code', 
     },
     {
      title: 'FullName',
      dataIndex: 'Name',
      key: 'Name',
     },
     {
      title: 'Type',
      dataIndex: 'U_Type',
      key: 'U_Type',
     },
     {
      title: 'DOB',
      dataIndex: 'Date',
      key: 'Date',
     },
     {
      title: 'Action',
      dataIndex: 'Action',
      key: 'Action',
      render:(Status,row)=>(
<button type="button" className="ant-btn ant-btn-danger" style={{"color": "rgb(255, 255, 255)"}}  id={row.ID} code={row.Code} onClick={()=>UpdateUser(row.ID,"Status","Active")} ><span>Active</span></button>
      
      ),
     } 
      
  ];
  return <><Table width={100} size='small' bordered scroll={{ x: 'max-content' }}  columns={columns}  dataSource={Users?Users.length>0?Users:null:null}  />
  <NotificationContainer />
  </>;
};
export default DeadUser;