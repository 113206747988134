 
import React, { useState} from 'react';
import {Modal } from 'antd';  
import Notification from "../notification";   
import {resetPassword} from   "../../../appRedux/actions/rex"; 
const ResetPassword =(state)=>  { 
  const [_Modal, setModal] = useState({Visible:state.visible });    
  const [new_password, setNew_password] = useState('');

const handleChangePassword=async()=>{

  await resetPassword({New_Password:new_password,ID:state.id}) .then((res)=>{
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
    Notification(res.message,res.type )   ;          
 // state.refresh();
  });
  

}
  const handleOk = e => {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  
     setModal({Visible:false});   
   handleChangePassword()
    };
  const handleCancel = e => {
  
     setModal({Visible:false});
    
   
   }; 
  
  const handleNew_password = (e) => {
    // Update the state when the input changes
    setNew_password(e.target.value); 
  };
    return (
   
  <Modal   title={<h1>Reset Password</h1>} okText='Submit' cancelText='Return'  afterClose={state.CloseModal}  visible={_Modal.Visible}    onOk={handleOk}  onCancel={handleCancel} >
 
  <p>
    <input type="text" placeholder="New Password" className="ant-input"         onChange={handleNew_password}
      value={new_password}
      /></p> 
   
      
   </Modal> 
   
    );
 
}

export default ResetPassword; 





 