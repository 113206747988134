import React, { useEffect, useState } from "react";
import { Row, Table,Col,Select, Empty,DatePicker, Spin } from 'antd';
import moment from "moment";
import { getMatchLedger } from "../../../../appRedux/actions/rex";

const {  RangePicker} = DatePicker;

function onChange(date, dateString) {
  console.log(date, dateString);
}
const Option = Select.Option;

const MyLedger = () => {
  const [Ledger, setLedger] = useState([]);
  const [lenaTotal, setLenaTotal] = useState(0.00);
  const [denaTotal, setDenaTotal] = useState(0.00);
  const [Total, setTotal] = useState(0.00);
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      title: 'Time',
      dataIndex: 'Time',
      key: 'Time',
       hidden: true, 
    },
  {
      title: 'Date',
      dataIndex: 'Date',
      key: 'Date',
        sorter: (a, b) => new Date(a.Time) - new Date(b.Time),
      defaultSortOrder: 'descend',
      render: (Status,row) =>  (  moment(row.Time).format('DD-MM-YYYY')     ),
    },
    {
      title: 'Title',
      dataIndex: 'Title',
      key: 'Title',
    },
     {
      title: 'CR',
      dataIndex: 'CR',
      key: 'CR',
       render: (Status,row) =>  (   <span style={{color:"green"}}>  {parseFloat(row.CR).toFixed(2)}  </span>   ),
  
    },
    {
      title: 'DR',
      dataIndex: 'DR',
      key: 'DR',
         render: (Status,row) =>  ( <span style={{color:"red"}}>  {parseFloat(row.DR).toFixed(2)}  </span>  ),
    }  
    
  ];

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const result = await getMatchLedger(true);
        
        if (result && Array.isArray(result)) {
          setLedger(result);
  
          // Calculate Lena (total of Credit column)
          const lenaTotal = result.reduce((total, item) => total + parseFloat(item.CR || 0), 0);
          setLenaTotal(lenaTotal);
  
          // Calculate Dena (total of Debit column)
          const denaTotal = result.reduce((total, item) => total + parseFloat(item.DR || 0), 0);
          setDenaTotal(denaTotal);
  
       
        } else {
          // Handle the case where result is not an array
          console.error("Invalid result format:", result);
        }
      } catch (error) {
        // Handle errors from the asynchronous operation
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []); 
  const balance = parseFloat(lenaTotal - denaTotal).toFixed(2);
  const isNegativeBalance = balance < 0;

  return (
    <>

 
  
      <Row style={{margin:8}}>  
         <Col  md={8}  xl={8} sm={24} xs={24} style={{"text-align": "center" }}>
         <div style={{"marginTop": "10px"}}>
         <RangePicker className="gx-mb-3 gx-w-100"
                     ranges={{Today: [moment(), moment()], 'This Month': [moment(), moment().endOf('month')]}}
                     onChange={onChange}/>
        </div>
    </Col>


    <Col md={8} sm={24} xs={24} style={{ textAlign: "center" }}>
  <div style={{ marginTop: "10px" }}>
  <Select showSearch defaultValue={"all"}
   style={{"width": "100%"}} id="UplineSuper" width="100%"  >
            
            <Option value='all'>All</Option>
            <Option value='event'>Event</Option>
            <Option value='casino'>Casino</Option>
            <Option value='matka'>Matka</Option>
     

     </Select> 
    
  </div>
</Col>

    <Col  md={8} sm={24} xs={24} style={{"text-align": "center" }}>
         <div style={{"marginTop": "10px"}}>
        
        <table>
         <tr>
            <td style={{"font-size": "20px"}}>Total : </td>
            <td style={{"font-size": "20px", "color": isNegativeBalance?"red":"rgb(82, 196, 26)"}}>{balance}</td>
            </tr>
            </table>
          
        </div>
    </Col>
     
  </Row>
  {loading ? (
        <div style={{ textAlign: 'center', margin: '20px' }}>
          <Spin size="large" />
        </div>
      ) : (
        <Table width={100} size='small' pagination={false} bordered scroll={{ x: 'max-content' }} columns={columns.filter(column => !column.hidden)} dataSource={Ledger ? Ledger == null ? null : Ledger : null} />
        )}
         
              
  
 
     
    </>
  );
};

export default MyLedger;
