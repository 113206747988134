 import React, {  useRef,  useEffect,useState} from 'react'; 
 import { NotificationContainer } from 'react-notifications';
import Notification from "../../components/notification";
import { SearchOutlined } from '@ant-design/icons'; 
import {  useParams} from "react-router-dom"; 
import { Button, Input, Dropdown , Modal,  Table, Menu , Spin } from 'antd';
import {getDownlineUsers,updateUser} from "../../../appRedux/actions/rex";
import Highlighter from 'react-highlight-words';
import UserParnertshipDetails from "../../components/modals/UserParnertshipDetails";
import ResetPassword from "../../components/modals/ResetPassword";
import UserExpo from "../../components/modals/UserExpo";
import DepositeChips from "../../components/modals/DepositeChips";
import WithdrawalChips from "../../components/modals/WithdrawalChips";
import DepositeMatkaLimit from "../../components/modals/DepositeMatkaLimit";
import WithdrawalMatkaLimit from "../../components/modals/WithdrawalMatkaLimit"; 

const UserList = (props) => { 
  const [loading, setLoading] = useState(true);

   const [_Modal, setModal] = useState({Modal:null,Visible:false, Title:"Partnership Info - "});  

   
  var  showExpoModel = (e) => { 
    const id=  e.target.getAttribute('id')   ;
     
   setModal({Modal:UserExpo,Visible:true  ,Id:id });
  };

  var  showUserParnertshipDetails = (e) => {  
      const id=  e.target.getAttribute('id')   ;
     const code=  e.target.getAttribute('code')   ;
    setModal({Modal:UserParnertshipDetails,Visible:true  ,Code:code,Id:id});
    
  };

  var  showDepositModel = (e) => { 
    const id=  e.target.getAttribute('id')   ;
    const parent =  e.target.getAttribute('parent')   ;
 const code=  e.target.getAttribute('code')   ;
    const coins=  e.target.getAttribute('coins')   ;
     
   setModal({Modal:DepositeChips,Visible:true ,Coins:coins ,Code:code,Id:id,Parent:parent });
  };

  var  showResetPasswordModel = (e) => { 
    const id=  e.target.getAttribute('id')   ;  
   setModal({Modal:ResetPassword,Visible:true,Id:id });
  };

  
  var  showWithdrawalModel = (e) => { 
    const id=  e.target.getAttribute('id')   ;
    const parent =  e.target.getAttribute('parent')   ;

    const code=  e.target.getAttribute('code')   ;
    const coins=  e.target.getAttribute('coins')   ; 
   setModal({Modal:WithdrawalChips,Visible:true ,Coins:coins ,Code:code,Id:id,Parent:parent});
  };

  var  showDepositeMatkaLimit = (e) => {  
    const id=  e.target.getAttribute('id')   ;
   const code=  e.target.getAttribute('code')   ;
   const coins=  e.target.getAttribute('coins')   ; 
  setModal({Modal:DepositeMatkaLimit,Visible:true  ,Coins:coins  ,Code:code,Id:id});
  
};

var  showWithdrawalMatkaLimit = (e) => { 
  const id=  e.target.getAttribute('id')   ;
  const code=  e.target.getAttribute('code')   ;
  const coins=  e.target.getAttribute('coins')   ; 
 setModal({Modal:WithdrawalMatkaLimit,Visible:true ,Coins:coins ,Code:code,Id:id});
};

const UpdateUser = async(u_id,type,value) => {

await updateUser(u_id,type,value).then((res)=>{
  getUsers();
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           
  Notification(res.message,res.type )

});

                                              

 
};
  const CloseModal = e => {
    setModal({Visible:false });
  };
  const { U_ID} = useParams();  
   const authUser=JSON.parse(localStorage.getItem("authUser"));  
   
   const User_Type = props.User_Type;
   
   const DUser_Type = props.DUser_Type;    
 
      const Upliner=()=>{

        if(User_Type=="Admin"){
        return  "Admin" ;  
          }
          else if(User_Type=="Mini Admin"){
            return  "Admin" ;  
       
          }
          else if(User_Type=="Master"){
            return  "Mini Admin" ;  
       
          }
          else if(User_Type=="Super"){
            return  "Master" ;  
        
          }
          else if(User_Type=="Agent"){
            return  "Super Agent" ;  
       
          }
          else if(User_Type=="Client"){
            return  "Agent" ;  
       
           }
           
       
      };  

    const [Users, setUsers] = useState([]); 
    const getUsers=()=>{
       getDownlineUsers(User_Type,U_ID?U_ID:null,"Active").then((result)=>{ 
     
     setUsers( result );  
     setLoading(false);
  });
    }

 useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      getUsers();
    };

    fetchData();
},User_Type , []);

 const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
           <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
         
        
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
 
  const renderOptionMenu = (row) => {
    return (
       <Menu>
         <Menu.Item key="2"><a className="dropdown-link" coins={row.Coins} code={row.Code} parent={row.Parent_ID}  id ={row.ID} title="Deposite Chips" onClick={showDepositModel} >
         Deposit
        </a></Menu.Item>
        <Menu.Item key="2"><a className="dropdown-link"  coins={row.Coins} code={row.Code}   parent={row.Parent_ID}  id ={row.ID}   title="Withdraw Chips" onClick={showWithdrawalModel} >
        Withdrawn
        </a></Menu.Item>
      
      { User_Type=="Client"?null : <Menu.Item key="2"  ><a className="dropdown-link "  coins={row.Coins} code={row.Code}  parent={row.Parent_ID}   id ={row.ID}  title="Deposit Matka Limit" onClick={showDepositeMatkaLimit} >
        Deposit Matka Limit
        </a></Menu.Item>}
        { User_Type=="Client"?null :  <Menu.Item key="2"><a className="dropdown-link"  coins={row.Coins} code={row.Code}  parent={row.Parent_ID}   id ={row.ID}  title="Withdraw Matka Limit" onClick={showWithdrawalMatkaLimit} >
        Withdrawn Matka Limit
        </a></Menu.Item>
        }
       
     
        <Menu.Item key="2"><a className="dropdown-link" id ={row.ID} onClick={()=>UpdateUser(row.ID,"Status","Inactive")} >
        Inactive
        </a></Menu.Item>
        { User_Type=="Client"?<Menu.Item key="2"><a  id ={row.ID}  className="dropdown-link"  onClick={()=>UpdateUser(row.ID,"Block_Betting",row.Block_Betting==='True'?"False" :"True")}  >
        {row.Block_Betting==='True'?"UnBlock Betting" :'Block Betting'}  
        </a></Menu.Item> : null 
        }  
         <Menu.Item key="2"><a className="dropdown-link" id ={row.ID}  onClick={""} >
        Block Casino
        </a></Menu.Item>
       
        <Menu.Divider />
        
        
        <Menu.Item key="2"><a className="dropdown-link" href={"/client/update-"+(User_Type=="Mini Admin"?"mini":User_Type.toLowerCase()) +"/"+row.ID }>
        Edit
        </a></Menu.Item>
        <Menu.Item key="2"><a className="dropdown-link" href={"/client/statement/"+row.ID }>
         Statement
        </a></Menu.Item>
        <Menu.Item key="2"><a className="dropdown-link" href={"/client/account-operations/"+row.ID }>
        Account Operations
        </a></Menu.Item>
        <Menu.Item key="2"><a className="dropdown-link" href={"/client/login-report/"+row.ID }>
        Login Report
        </a></Menu.Item> 

        <Menu.Item key="2"><a className="dropdown-link"  id ={row.ID}   onClick={showResetPasswordModel}>
        Reset Password
        </a></Menu.Item>
       
    
{ User_Type=="Client"?null  
       :  
       <Menu.Item key="2"><a className="dropdown-link" href={"/client/list-"+DUser_Type.toLowerCase()+"/"+row.ID }>
         Downline
        </a></Menu.Item> }
         
      </Menu>
    );
  }; 

 
  const columns = [
    {
      title: '#',
      dataIndex: 'sn',
      key: 'sn', 
      render:(Status,row)=>(

        <button type="button" className="ant-btn ant-dropdown-trigger ant-btn-primary ant-btn-sm" style={{"margin-bottom": "0px"}} id={row.ID} code={row.Code}  onClick={showUserParnertshipDetails}>
  <i className="icon icon-add"></i></button>
      ),
     },
    {
      title: '',
      dataIndex: 'optn',
      key: 'optn', 
       render: (Status,row) =>  (
        <Dropdown overlay={renderOptionMenu(row)} trigger={['click']}
        >
<button type="button" className="ant-btn ant-dropdown-trigger ant-btn-primary ant-btn-sm" style={{"margin-bottom": "0px"}} ant-click-animating-without-extra-node="false">
  <i className="icon icon-dropdown"></i></button>

        
        </Dropdown>
      ),
     },
    // {
    //   title: 'ID',
    //   dataIndex: 'ID',
    //   key: 'ID',
       
    // },
    {
      title: 'Code',
      dataIndex: 'Code',
      key: 'Code', 
      render: (Status,row) =>  (
       <>{DUser_Type  ?<a className="dropdown-link" href={"/client/list-"+DUser_Type.toLowerCase()+"/"+row.ID }>
        {row.Code +' 👁'}
        </a>:row.Code}
        </> 
       
     ),
    },
    {
      title: 'Name',
      dataIndex: 'Name',
      key: 'Name',
       ...getColumnSearchProps('Name'),
    },
    {
      title:Upliner,
      dataIndex: 'Parent',
      key: 'Parent',
       },
    {
      title: 'Date',
      dataIndex: 'Date',
      key: 'Date',
        },
      {
        title: 'Share %',
        dataIndex: 'Share',
        key: 'Share',
        
      },
          {
        title: 'Password',
        dataIndex: 'Password',
        key: 'Password',
        render: (Status,row) =>  (
          <>{authUser.U_Type=="Admin"?row.Password:"*******"}
           </> 
          
        ),
      },    {
        title: 'Exposure',
        dataIndex: 'Exposure',
        key: 'Exposure',
        visible:(props.User_Type=="Client"?true:false),
        render: (Status,row) =>  (
          <span  onClick={row.Exposure>0? showExpoModel:null }  id ={row.ID}  style={row.Exposure>0?{"cursor": "pointer", "color": "rgb(3, 143, 222)"}:null}>{row.Exposure}</span> 
        ),
      },
 
      {
        title: User_Type+' Comm %',
        dataIndex: User_Type+'-Comm',
        key: User_Type+'-Comm',
         children: [
          {
            title: 'Match',
            dataIndex: 'Match',
            key: 'Match',
              },{
            title: 'SSN',
            dataIndex: 'SSN',
            key: 'SSN',
            },]
      },
      {
        title: 'Coins',
        dataIndex: 'Coins',
        key: 'Coins',
        render: (Status,row) =>  (
          parseFloat(row.Coins - row.Exposure).toFixed(2)  
          
          ),
      },
      {
        title: 'Status',
        dataIndex: 'Status',
        key: 'Status',
        
      },
     
      {
        title: 'Dead',
        dataIndex: 'Dead',
        key: 'Dead',
        
      } 
  ];
  return     (<>  <Spin spinning={loading} tip="Loading...">
  <Table width={100} size='small' bordered  scroll={{x:true}}  columns={columns.filter((column) => column.visible !== false)}    dataSource={Users?Users.length>0?Users:null:null}  />
  </Spin>
{_Modal.Visible?<_Modal.Modal refresh={()=>getUsers()} visible={_Modal.Visible} CloseModal={CloseModal} parent={_Modal.Parent}  id={_Modal.Id} code={_Modal.Code} coins={_Modal.Coins} after={_Modal.Coins}  />:null}
  


<NotificationContainer />
 
 </>
 
 )
  ;
};
export default UserList;