import React,{useEffect,useState } from "react";   
import { useParams  } from "react-router-dom";  
import {  Table , Spin, Col, Button } from 'antd';
 import {getLiveCompletedFancySegment } from "../../../appRedux/actions/rex";  
      

 const customEmptyText = 'No Data';

 const locale = {
   emptyText: customEmptyText,
 };

const Segments = (props) => {
   
    const [isLoading, setIsLoading] = useState(false);
  const [ CompletedFanciesSegment,setCompletedFanciesSegment ] = useState([])  
  

const columns = [
   
  {
    title: 'A/C Name',
    dataIndex: 'Name',
    key: 'Name',
    render:(Status,row)=>(
        
        <>
        {props.AccountType=="Client"? <span>{row.Name}</span> :
        <button onClick={()=>props.SetSegmentID(props.AccountType,row.ID)} className="ant-tag ant-tag-has-color" style={{ backgroundColor: 'rgb(255, 85, 0)' }}>
                 {row.Name}
                </button>
                }
    </>


    ), 
       },
  {
    title: 'P&L',
    dataIndex: 'PL',
    key: 'PL',  render:(Status,row)=>( 
 
     <span  style={{"color":row.PL<0?"red":"green"}} >{ row.PL.toFixed(2)}</span>
     
    ), 
  },
  {
    title: 'Comm.',
    dataIndex: 'Comm',
    key: 'Comm',
    render:(Status,row)=>( 
 
       row.Comm.toFixed(2) 
        
       ), 
       } 
          
 ];

   const GetCompletedBetPLSegment =async()=>{
 await getLiveCompletedFancySegment(props.Data ,props.SegmentID).then(async(result)=>{  
       
    setCompletedFanciesSegment(result);
    setIsLoading(false);
     });


   }
  useEffect(() => {
   const fetchData = async () => {
    setIsLoading(true);
  await   GetCompletedBetPLSegment();


   };

   fetchData();
},   [props.SegmentID]);
 
 




  return ( 
   
   <Col xl={6} md={6} sm={24} xs={24}>
   
  <div className="ant-card ant-card-bordered">
  <div className="ant-card-head">
    <div className="ant-card-head-wrapper">
      <div className="ant-card-head-title">{props.AccountType+" P&L"}</div> 
    </div>
  </div>
  <div className="ant-card-body" style={{ padding: 0 }}>

  <Spin spinning={isLoading}>
      <Table width={100} size='small' bordered  pagination={false} locale={locale} scroll={{x: 'max-content'}}   columns={columns} dataSource={CompletedFanciesSegment?CompletedFanciesSegment==null?null:CompletedFanciesSegment:null} />
      </Spin>
  </div>
</div>
                </Col>







  );
};

export default  Segments;
