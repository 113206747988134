 import React, {  useRef,  useEffect,useState} from 'react';

 import {getActvityLogs } from "../../../appRedux/actions/rex";
 import { Table } from 'antd';
const AccountOperations = (props) => {  
  const [Actvities, setActvities] = useState([]); 

  const getActvities=()=>{
    getActvityLogs(props.U_ID).then((result)=>{ 
       setActvities( result );  
  
});
 }

  useEffect(() => {
    const fetchData = async () => {
   
      getActvities();
    };

    fetchData();
}, []);
  const columns = [ 
    {
      title: 'Date',
      dataIndex: 'Date',
      key: 'Date',
       
    },
    {
      title: 'Operation',
      dataIndex: 'Operation',
      key: 'Operation', 
    } ,
    {
      title: 'Done By',
      dataIndex: 'Done_By',
      key: 'Done_By', 
    } ,
    {
      title: 'Description',
      dataIndex: 'Description',
      key: 'Description', 
    }  
     
  ];
  return     (<><Table width={100} size='small' bordered scroll={{ x: true }}  columns={columns}  dataSource={Actvities}  />
 
  


 
 </>
 
 )
  ;
};
export default AccountOperations;