import React, { useEffect, useState } from "react";
import { Row, Table, Empty, Spin } from 'antd';
import { getMyLedger } from "../../../../appRedux/actions/rex";
import moment from 'moment';
const MyLedger = () => {
  const [Ledger, setLedger] = useState([]);
  const [lenaTotal, setLenaTotal] = useState(0.00);
  const [denaTotal, setDenaTotal] = useState(0.00);
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      title: 'Time',
      dataIndex: 'Time',
      key: 'Time',
       hidden: true, 
    },
  {
      title: 'Date',
      dataIndex: 'Date',
      key: 'Date',
        sorter: (a, b) => new Date(a.Time) - new Date(b.Time),
      defaultSortOrder: 'descend',
      
      render: (Status,row) =>  (  moment(row.Time).format('DD-MM-YYYY')     ),
    },
    {
      title: 'Collection Name',
      dataIndex: 'Collection_Name',
      key: 'Collection_Name',
    },
    {
      title: 'Debit',
      dataIndex: 'Debit',
      key: 'Debit',
      align: 'right',
      render: (Status,row) =>  (    parseFloat(row.Debit).toFixed(2)   ),
  
    },
    {
      title: 'Credit',
      dataIndex: 'Credit',
      key: 'Credit',
      align: 'right',
        render: (Status,row) =>  (    parseFloat(row.Credit).toFixed(2)   ),
    },
    {
      title: 'Balance',
      dataIndex: 'Balance',
      key: 'Balance',
      align: 'right',
    },
    {
      title: 'Payment Type',
      dataIndex: 'Payment_Type',
      key: 'Payment_Type',
    },
    {
      title: 'Remark',
      dataIndex: 'Remark',
      key: 'Remark',
    }
  ];

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const result = await getMyLedger(false);
        
        if (result && Array.isArray(result)) {
          setLedger(result);
  
          // Calculate Lena (total of Credit column)
          const lenaTotal = result.reduce((total, item) => total + parseFloat(item.Credit || 0.00), 0.00);
          setLenaTotal(lenaTotal);
  
          // Calculate Dena (total of Debit column)
          const denaTotal = result.reduce((total, item) => total + parseFloat(item.Debit || 0.00), 0.00);
          setDenaTotal(denaTotal);
        } else {
          // Handle the case where result is not an array
          console.error("Invalid result format:", result);
        }
      } catch (error) {
        // Handle errors from the asynchronous operation
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  const balance = lenaTotal - denaTotal;
  const isNegativeBalance = balance < 0;

  return (
    <>
      <Row className="gx-text-center">
        <div class="ant-col ant-col-xs-12 ant-col-sm-8 ant-col-md-8 ant-col-xl-8">
          <h3 style={{ "padding": "5px", "color": "green" }}><strong>Lena : {lenaTotal.toFixed(2)}</strong></h3>
        </div>
        <div class="ant-col ant-col-xs-12 ant-col-sm-8 ant-col-md-8 ant-col-xl-8">
          <h3 style={{ "padding": "5px", "color": "red" }}><strong>Dena : {-(denaTotal).toFixed(2)}</strong></h3>
        </div>
        <div class="ant-col ant-col-xs-24 ant-col-sm-8 ant-col-md-8 ant-col-xl-8">
          <h3 style={{ "padding": "5px" }}>
            <strong>
              <span style={{ "padding": "20px", "color": isNegativeBalance ? "red" : "green" }}>
                Balance: {isNegativeBalance ? `${balance.toFixed(2)} (Dena)` : `${balance.toFixed(2)} (Lena)`}
              </span>
            </strong>
          </h3>
        </div>
      </Row>
      {loading ? (
        <div style={{ textAlign: 'center', margin: '20px' }}>
          <Spin size="large" />
        </div>
      ) : (
        <Table width={100} size='small' pagination={false} bordered scroll={{ x: 'max-content' }} columns={columns.filter(column => !column.hidden)} dataSource={Ledger ? Ledger == null ? null : Ledger : null} />
      )}
    </>
  );
};

export default MyLedger;
