import React from "react";
import { Col, Row } from "antd";

import { Link } from "react-router-dom";
import Auxiliary from "util/Auxiliary";
import { DatePicker ,tableMerge} from "antd";
import GoBack from "../../components/buttons/GoBack"
import moment from "moment";
const RangePicker = DatePicker.RangePicker; 

function onChange(date, dateString) {
}


const ListAgent = () => {

    return (<>
<div className="ant-card ant-card-bordered">
   <div className="ant-card-head">
      <div className="ant-card-head-wrapper">
         <div className="ant-card-head-title">
            <h1>Cricket Detail</h1>
         </div>
         <div className="ant-card-extra"><span><button type="button" className="ant-btn gx-text-white ant-btn-primary" style={{"font-weight": "bold"}}><span>Back</span></button></span></div>
      </div>
   </div>
   <div className="ant-card-body">
      <div className="ant-row">
         <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-xl-8" style={{"text-align": "center","marginRight":"auto","marginLeft":"auto"}}>
        <div style={{"marginTop": "10px", "marginBottom": "10px"}}>
        <RangePicker className="gx-mb-3 gx-w-100"
                   ranges={{Today: [moment(), moment()], 'This Month': [moment(), moment().endOf('month')]}}
                   onChange={onChange}
      />
        </div>
     
   
         </div>
         <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-xl-24">
            <div className="ant-table-wrapper">
               <div className="ant-spin-nested-loading">
                  <div className="ant-spin-container">
                     <div className="ant-table ant-table-small ant-table-bordered ant-table-scroll-position-left">
                        <div className="ant-table-content">
                           <div className="ant-table-scroll">
                              <div tabindex="-1" className="ant-table-body" style={{"overflow-x": "scroll"}}>
                                 <table className="ant-table-fixed">
                                     
                                    <thead className="ant-table-thead">
                                       <tr>
                                          <th className="">
                                             <span className="ant-table-header-column">
                                                <div><span className="ant-table-column-title"></span><span className="ant-table-column-sorter"></span></div>
                                             </span>
                                          </th>
                                          <th className="">
                                             <span className="ant-table-header-column">
                                                <div><span className="ant-table-column-title">Event Id</span><span className="ant-table-column-sorter"></span></div>
                                             </span>
                                          </th>
                                          <th className="">
                                             <span className="ant-table-header-column">
                                                <div><span className="ant-table-column-title">Market Name</span><span className="ant-table-column-sorter"></span></div>
                                             </span>
                                          </th>
                                          <th className="">
                                             <span className="ant-table-header-column">
                                                <div><span className="ant-table-column-title">Time</span><span className="ant-table-column-sorter"></span></div>
                                             </span>
                                          </th>
                                          <th className="">
                                             <span className="ant-table-header-column">
                                                <div><span className="ant-table-column-title">Won by</span><span className="ant-table-column-sorter"></span></div>
                                             </span>
                                          </th>
                                          <th className="ant-table-align-right" style={{"text-align": "right"}}>
                                             <span className="ant-table-header-column">
                                                <div><span className="ant-table-column-title">Profit Loss</span><span className="ant-table-column-sorter"></span></div>
                                             </span>
                                          </th>
                                       </tr>
                                    </thead>
                                    <tbody className="ant-table-tbody">
                                       <tr className="ant-table-row ant-table-row-level-0" data-row-key="0">
                                          <td className=""><button type="button" className="ant-btn ant-dropdown-trigger ant-btn-primary ant-btn-sm" style={{"marginBottom": "0px"}}><i className="icon icon-dropdown"></i></button></td>
                                          <td className="">32191850</td>
                                          <td className=""><span>Lahore Qalandars v Peshawar Zalmi</span></td>
                                          <td className="">
                                             <div>17-03-2023 07:30 PM</div>
                                          </td>
                                          <td className=""></td>
                                          <td className="" style={{"text-align": "right"}}><span style={{"color": "green"}}>0</span></td>
                                       </tr>
                                       <tr className="ant-table-row rowHighlight ant-table-row-level-0" data-row-key="1">
                                          <td className=""><button type="button" className="ant-btn ant-dropdown-trigger ant-btn-primary ant-btn-sm" style={{"marginBottom": "0px"}}><i className="icon icon-dropdown"></i></button></td>
                                          <td className="">32183236</td>
                                          <td className=""><span>India v Australia</span></td>
                                          <td className="">
                                             <div>17-03-2023 01:30 PM</div>
                                          </td>
                                          <td className=""></td>
                                          <td className="" style={{"text-align": "right"}}><span style={{"color": "green"}}>0</span></td>
                                       </tr>
                                       <tr className="ant-table-row ant-table-row-level-0" data-row-key="2">
                                          <td className=""><button type="button" className="ant-btn ant-dropdown-trigger ant-btn-primary ant-btn-sm" style={{"marginBottom": "0px"}}><i className="icon icon-dropdown"></i></button></td>
                                          <td className="">32183024</td>
                                          <td className=""><span>New Zealand v Sri Lanka</span></td>
                                          <td className="">
                                             <div>17-03-2023 03:30 AM</div>
                                          </td>
                                          <td className=""></td>
                                          <td className="" style={{"text-align": "right"}}><span style={{"color": "green"}}>0</span></td>
                                       </tr>
                                       <tr className="ant-table-row rowHighlight ant-table-row-level-0" data-row-key="3">
                                          <td className=""><button type="button" className="ant-btn ant-dropdown-trigger ant-btn-primary ant-btn-sm" style={{"marginBottom": "0px"}}><i className="icon icon-dropdown"></i></button></td>
                                          <td className="">32177964</td>
                                          <td className=""><span>South Africa v West Indies</span></td>
                                          <td className="">
                                             <div>16-03-2023 08:30 PM</div>
                                          </td>
                                          <td className="">NONE</td>
                                          <td className="" style={{"text-align": "right"}}><span style={{"color": "green"}}>0</span></td>
                                       </tr>
                                       <tr className="ant-table-row ant-table-row-level-0" data-row-key="4">
                                          <td className=""><button type="button" className="ant-btn ant-dropdown-trigger ant-btn-primary ant-btn-sm" style={{"marginBottom": "0px"}}><i className="icon icon-dropdown"></i></button></td>
                                          <td className="">32188376</td>
                                          <td className=""><span>World Giants v Asia Lions</span></td>
                                          <td className="">
                                             <div>16-03-2023 08:00 PM</div>
                                          </td>
                                          <td className="">World Giants</td>
                                          <td className="" style={{"text-align": "right"}}><span style={{"color": "green"}}>0</span></td>
                                       </tr>
                                       <tr className="ant-table-row rowHighlight ant-table-row-level-0" data-row-key="5">
                                          <td className=""><button type="button" className="ant-btn ant-dropdown-trigger ant-btn-primary ant-btn-sm" style={{"marginBottom": "0px"}}><i className="icon icon-dropdown"></i></button></td>
                                          <td className="">32181223</td>
                                          <td className=""><span>Islamabad United v Peshawar Zalmi</span></td>
                                          <td className="">
                                             <div>16-03-2023 07:30 PM</div>
                                          </td>
                                          <td className="">Peshawar Zalmi</td>
                                          <td className="" style={{"text-align": "right"}}><span style={{"color": "green"}}>0</span></td>
                                       </tr>
                                       <tr className="ant-table-row ant-table-row-level-0" data-row-key="6">
                                          <td className=""><button type="button" className="ant-btn ant-dropdown-trigger ant-btn-primary ant-btn-sm" style={{"marginBottom": "0px"}}><i className="icon icon-dropdown"></i></button></td>
                                          <td className="">32182960</td>
                                          <td className=""><span>Delhi Capitals Women v Gujarat Giants Women</span></td>
                                          <td className="">
                                             <div>16-03-2023 07:30 PM</div>
                                          </td>
                                          <td className="">Gujarat Giants Women</td>
                                          <td className="" style={{"text-align": "right"}}><span style={{"color": "green"}}>0</span></td>
                                       </tr>
                                       <tr className="ant-table-row rowHighlight ant-table-row-level-0" data-row-key="7">
                                          <td className=""><button type="button" className="ant-btn ant-dropdown-trigger ant-btn-primary ant-btn-sm" style={{"marginBottom": "0px"}}><i className="icon icon-dropdown"></i></button></td>
                                          <td className="">32183097</td>
                                          <td className=""><span>Nepal v UAE</span></td>
                                          <td className="">
                                             <div>16-03-2023 08:45 AM</div>
                                          </td>
                                          <td className="">Nepal</td>
                                          <td className="" style={{"text-align": "right"}}><span style={{"color": "green"}}>0</span></td>
                                       </tr>
                                       <tr className="ant-table-row ant-table-row-level-0" data-row-key="8">
                                          <td className=""><button type="button" className="ant-btn ant-dropdown-trigger ant-btn-primary ant-btn-sm" style={{"marginBottom": "0px"}}><i className="icon icon-dropdown"></i></button></td>
                                          <td className="">32185727</td>
                                          <td className=""><span>India Maharajas v World Giants</span></td>
                                          <td className="">
                                             <div>15-03-2023 08:00 PM</div>
                                          </td>
                                          <td className="">World Giants</td>
                                          <td className="" style={{"text-align": "right"}}><span style={{"color": "green"}}>0</span></td>
                                       </tr>
                                       <tr className="ant-table-row rowHighlight ant-table-row-level-0" data-row-key="9">
                                          <td className=""><button type="button" className="ant-btn ant-dropdown-trigger ant-btn-primary ant-btn-sm" style={{"marginBottom": "0px"}}><i className="icon icon-dropdown"></i></button></td>
                                          <td className="">32178707</td>
                                          <td className=""><span>UP Warriorz Women v RC Bangalore Women</span></td>
                                          <td className="">
                                             <div>15-03-2023 07:30 PM</div>
                                          </td>
                                          <td className="">RC Bangalore Women</td>
                                          <td className="" style={{"text-align": "right"}}><span style={{"color": "green"}}>0</span></td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                        </div>
                     </div>
                     <ul className="ant-pagination ant-table-pagination mini" unselectable="unselectable">
                        <li title="Previous Page" className="ant-pagination-disabled ant-pagination-prev" aria-disabled="true">
                           <a className="ant-pagination-item-link">
                              <i aria-label="icon: left" className="anticon anticon-left">
                                 <svg viewBox="64 64 896 896" focusable="false" className="" data-icon="left" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                    <path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 0 0 0 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"></path>
                                 </svg>
                              </i>
                           </a>
                        </li>
                        <li title="1" className="ant-pagination-item ant-pagination-item-1 ant-pagination-item-active" tabindex="0"><a>1</a></li>
                        <li title="2" className="ant-pagination-item ant-pagination-item-2" tabindex="0"><a>2</a></li>
                        <li title="3" className="ant-pagination-item ant-pagination-item-3" tabindex="0"><a>3</a></li>
                        <li title="4" className="ant-pagination-item ant-pagination-item-4" tabindex="0"><a>4</a></li>
                        <li title="5" className="ant-pagination-item ant-pagination-item-5" tabindex="0"><a>5</a></li>
                        <li title="6" className="ant-pagination-item ant-pagination-item-6" tabindex="0"><a>6</a></li>
                        <li title="Next Page" tabindex="0" className=" ant-pagination-next" aria-disabled="false">
                           <a className="ant-pagination-item-link">
                              <i aria-label="icon: right" className="anticon anticon-right">
                                 <svg viewBox="64 64 896 896" focusable="false" className="" data-icon="right" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                    <path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 0 0 302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 0 0 0-50.4z"></path>
                                 </svg>
                              </i>
                           </a>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
            </>
               );
};

                export default ListAgent;
