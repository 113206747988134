import React  from "react";  
import { Link ,useParams} from "react-router-dom"; 
import LoginReports from "../../components/tables/LoginReport"
import GoBack from "../../components/buttons/GoBack"
const LoginReport = () => {
   
 const {U_Type } = useParams(); 
  
     
 

    return (<> 
<div className="ant-card ant-card-bordered">
   <div className="ant-card-head">
      <div className="ant-card-head-wrapper">
         <div className="ant-card-head-title">
            <span>
               <h1 style={{"display": "inline-block"}}>Login Report</h1>
            </span>
         </div>
         <div className="ant-card-extra"><span><GoBack/></span></div>
      </div>
   </div>
   <div className="ant-card-body">
    
       
            <LoginReports  />
         
   </div>
</div> 
            </>
               );
 

 
}


 
 
 
export default LoginReport;
