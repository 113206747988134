import React from "react";
import { Row,Col,DatePicker, Select, Space} from 'antd';
import GoBack from "../../../components/buttons/GoBack"
 
import Ledger from "./ledger" 
const {  RangePicker} = DatePicker;

function onChange(date, dateString) {
  console.log(date, dateString);
}
const Option = Select.Option;
const LedgerMatch = () => {
   return ( 


      <>
      <div className="ant-card ant-card-bordered">
         <div className="ant-card-head">
            <div className="ant-card-head-wrapper">
               <div className="ant-card-head-title">
                  <span>
                     <h1 style={{"display": "inline-block"}}>Match Ledger</h1>
                  </span>
               </div>
               <div className="ant-card-extra"><span><GoBack/></span></div>
            </div>
         </div>
         <div className="ant-card-body">
           
   
  <Ledger/>
         </div>
      </div>
                  </>
  
  
  
  
  
  
  
    );
};

export default LedgerMatch;
