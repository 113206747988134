import React, { useState,useEffect } from 'react';
import { Row, Col, DatePicker, Select  } from 'antd';
import moment from "moment";
import Cients from "./clients" 
import AllClients from "./all-clients" 
import SingleClient from "./single-client" 
 
 
 
const Commissions = (props) => { 
  const [Client, setClient] = useState("All");
  const [ResetCount, setResetCount] = useState(0);
  const [timeRange, setTimeRange] = useState([]);  
  

  const { RangePicker } = DatePicker;
 
  const onChange = (dates, dateStrings) => {
    console.log(dateStrings); 
    setTimeRange(dates);  
  } 

  const HandleClientChange = (value) => {
    setClient(value);
   
 };

  
 useEffect(() => {
  const defaultRange = [moment().startOf('month'), moment().endOf('month')];
  setTimeRange(defaultRange);
 
  onChange(defaultRange, [moment(defaultRange[0]), moment(defaultRange[1])]);
}, []); 


return (
 <> <Row style={{"margin": "8px"}}>
    <Col xl={8} md={8} sm={24} xs={24} style={{ "text-align": "center"  }}>
      <div style={{ "marginTop": "10px" }}>
      <RangePicker
  className="gx-mb-2 gx-w-100"
  ranges={{
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'day'), moment().subtract(1, 'day').endOf('day')],
    'This Week': [moment().startOf('week'), moment().endOf('week')],
    'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  }}
  defaultValue={[moment().startOf('month'), moment().endOf('month')]}
  onChange={onChange}
/>
      </div>
    </Col>
    <Col xl={8} md={8} sm={24} xs={24} style={{ "text-align": "left"  }}>
    <Cients timeRange={timeRange} resetCount={ResetCount} client={Client} handleClientChange={HandleClientChange}/>
    </Col>
  </Row>
     <Row>
     <Col xl={24} md={24} sm={24} xs={24}>
    {Client=="All"?<AllClients timeRange={timeRange} resetCount={ResetCount}  set_resetCount={setResetCount} client={Client} />:<SingleClient timeRange={timeRange} client={Client} />} 
    </Col>
     </Row>

 </>
);
};

export default Commissions;