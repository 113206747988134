import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form  } from 'antd'; 
import { getSelectUplineSuper } from '../../../../appRedux/actions/rex';
import { Select } from 'antd';

const FormItem = Form.Item;
const { Option } = Select;

const UserSelector = ({ Type, onUserChange,form  }) => {
  const [Users, setUsers] = useState([]);

  const Users_Code = () => {
    if (Type === 'Admin') {
      return 'Admin';
    } else if (Type === 'Mini Admin') {
      return 'MI';
    } else if (Type === 'Master') {
      return 'MA';
    } else if (Type === 'Super') {
      return 'SA';
    } else if (Type === 'Agent') {
      return 'A';
    } else if (Type === 'Client') {
      return 'C';
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getSelectUplineSuper(Type);
        setUsers(data);
         // Clear the selection when new data is loaded
        form.setFieldsValue({ User: undefined, Type: undefined,Amount: "" ,Remark: "" });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [Type ]);

  const userCode = Users_Code();

  return (
    <FormItem
      name="Users"
      label={Type === 'Super' ? Type + " Agent" : Type}
      wrapperCol={{
        span: 15,
      }}
    >
      {form.getFieldDecorator('User', {
        rules: [{ required: true, message: (Type === 'Super' ? "Please select a " + Type + " Agent" : "Please select a " + Type) }],
      })(
        <Select
          value={"Cash"}
          placeholder={Type === 'Super' ? "Select " + Type + " Agent" : "Select " + Type}
          id="User"
          showSearch={true}
          onChange={onUserChange}
          width="100%"
          allowClear
        >
          {Users.map((user) => (
            <Option value={userCode + user.ID} key={user.ID}>
              {'(' + userCode + user.ID + ') ' + user.Name}
            </Option>
          ))}
        </Select>
      )}
    </FormItem>
  );
};

UserSelector.propTypes = {
  Type: PropTypes.string.isRequired,
  onUplineChange: PropTypes.func.isRequired,
  validateStatus: PropTypes.string,
  help: PropTypes.node,
  rules: PropTypes.arrayOf(PropTypes.object), // Additional validation rules
  form: PropTypes.object.isRequired, // Add this prop to get access to the form instance
};

export default UserSelector;