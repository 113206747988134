import React from "react";
import {Route, Switch} from "react-router-dom";
import CasinoDetails from "./casino-details/index";  
import TodayPandl from "./today-pandl/index";    
const Main = ({match}) => (
  
  <Switch>
  <Route path={`${match.url}/casino-details`} component={CasinoDetails}/> 
    <Route path={`${match.url}/today-pandl`} component={TodayPandl}/>   
  </Switch>
);

export default Main;
