import React, { useEffect, useState } from "react";
import { Row, Col, Select, Table, Empty, Spin } from 'antd'; 
import { getFancyBetsPL } from "../../../appRedux/actions/rex";
import { Typography } from 'antd';

const { Text } = Typography;
const Option = Select.Option;
const customEmptyText = (
  <div>
    <Empty description={<Text disabled><h2>No Bets available on this match</h2></Text>} />
  </div>
);

const FancyLoss = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [ FancyBets, setFancyBets] = useState([]); 
  const [selectedUser, setSelectedUser] = useState("All"); 
  const [Minis, setMinis] = useState([]);
  const [Masters, setMasters] = useState([]);
  const [Supers, setSupers] = useState([]);
  const [Agents, setAgents] = useState([]);
  const [Clients, setClients] = useState([]);

   

  const columns = [
    {
      title: 'Rate',
      dataIndex: 'Rate',
      key: 'Rate' ,
      render: (text) => parseFloat(text).toFixed(2),
    },
    {
      title: 'Amount',
      dataIndex: 'Amount',
      key: 'Amount',
      render: (text) => parseFloat(text).toFixed(2),
    },
    {
      title: 'Type',
      dataIndex: 'Type',
      key: 'Type',
    },
    {
      title: 'Team',
      dataIndex: 'Team',
      key: 'Team',
    },
    {
      title: 'Client',
      dataIndex: 'Client',
      key: 'Client', 
      render: (Status,row) => (row.Client),
    
    },
    {
      title: 'Agent',
      dataIndex: 'Agent',
      key: 'Agent' 
    },
    {
      title: 'Date',
      dataIndex: 'Date',
      key: 'Date'
    },
    {
      title: 'Loss',
      dataIndex: 'Loss',
      key: 'Loss' ,
      render: (text) => parseFloat(text).toFixed(2),
    },
    {
      title: 'Profit',
      dataIndex: 'Profit',
      key: 'Profit' ,
      render: (text) => parseFloat(text).toFixed(2),
    },
    {
      title: 'Volume',
      dataIndex: 'Volume',
      key: 'Volume' ,
      render: (text) => parseFloat(text).toFixed(2),
    },
  ];

  const GetFancyBetsPL = async () => {
    try {
      setIsLoading(true);
      const result = await getFancyBetsPL(props.EID);

      const _Minis = Array.from(new Set(result.map(bet => bet.Mini)));
       setMinis(_Minis);
     const _Masters = Array.from(new Set(result.map(bet => bet.Master)));
     setMasters(_Masters);
    const _Supers = Array.from(new Set(result.map(bet => bet.Super)));
    setSupers(_Supers);
     const _Agents = Array.from(new Set(result.map(bet => bet.Agent)));
     setAgents(_Agents);
     const _Clients = Array.from(new Set(result.map(bet => bet.Client)));
     setClients(_Clients);

      
      const filteredBets = selectedUser === "All"
      ? result
      : result.filter((bet) => (bet.Mini === selectedUser ||  bet.Master === selectedUser ||  bet.Super === selectedUser ||  bet.Agent === selectedUser ||  bet.Client === selectedUser));
   setFancyBets(filteredBets);
       
  
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    GetFancyBetsPL();
  }, [props.EID,selectedUser]);

  const handleUserChange = (value) => {
     setSelectedUser(value);
  };


  const getRowClassName = (record) => {
    return record.Type =="NO"  ? 'laybet' : 'backbet';
  };

  return (
    <>
     <Row style={{ margin: "8px" }}>
      <Col xl={4} md={4} sm={4} xs={24} style={{ textAlign: "center" }}>
          <div style={{ marginBottom: "20px" }}>
            <Select
              style={{ width: "100%" }}
                 placeholder={`Select Mini Admin`}
              onChange={handleUserChange}
              allowClear
              showSearch
              optionFilterProp="children"
            >
              <Option value="All">All Mini Admins</Option>
              {Minis.map(Mini  => (
                <Option key={Mini} value={Mini}>
                  {Mini}
                </Option>
              ))}
            </Select>
          </div>
        </Col> 

        <Col xl={4} md={4} sm={4} xs={24} style={{ textAlign: "center" }}>
          <div style={{ marginBottom: "20px" }}>
            <Select
              style={{ width: "100%" }}
               placeholder={`Select Master`}
              onChange={handleUserChange}
              allowClear
              showSearch
              optionFilterProp="children"
            >
              <Option value="All">All Masters</Option>
              {Masters.map(Master  => (
                <Option key={Master} value={Master}>
                  {Master}
                </Option>
              ))}
            </Select>
          </div>
        </Col> 

        <Col xl={4} md={4} sm={4} xs={24} style={{ textAlign: "center" }}>
          <div style={{ marginBottom: "20px" }}>
            <Select
              style={{ width: "100%" }}
               placeholder={`Select Super Agent`}
              onChange={handleUserChange}
              allowClear
              showSearch
              optionFilterProp="children"
            >
              <Option value="All">All Super Agents</Option>
              {Supers.map(Super  => (
                <Option key={Super} value={Super}>
                  {Super}
                </Option>
              ))}
            </Select>
          </div>
        </Col> 


        <Col xl={4} md={4} sm={4} xs={24} style={{ textAlign: "center" }}>
          <div style={{ marginBottom: "20px" }}>
            <Select
              style={{ width: "100%" }}
              placeholder={`Select Agent`}
              onChange={handleUserChange}
              allowClear
              showSearch
              optionFilterProp="children"
            >
              <Option value="All">All Agents</Option>
              {Agents.map(Agent  => (
                <Option key={Agent} value={Agent}>
                  {Agent}
                </Option>
              ))}
            </Select>
          </div>
        </Col> 

        <Col xl={4} md={4} sm={4} xs={24} style={{ textAlign: "center" }}>
          <div style={{ marginBottom: "20px" }}>
            <Select
              style={{ width: "100%" }}
              placeholder={`Select Client`}
              onChange={handleUserChange}
              allowClear
              showSearch
              optionFilterProp="children"
            >
              <Option value="All">All Clients</Option>
              {Clients.map(Client  => (
                <Option key={Client} value={Client}>
                  {Client}
                </Option>
              ))}
            </Select>
          </div>
        </Col> 

     


      </Row>

      <Spin spinning={isLoading}>
        {FancyBets && FancyBets.length > 0 ? (
          <Table
            width={100}
            size="small"
            bordered
            pagination={false}
            scroll={{ x: 'max-content' }}
            rowClassName={getRowClassName}
            locale={{ emptyText: customEmptyText }}
            columns={columns}
            dataSource={FancyBets}
          />
        ) : (
          <Empty description={<Text disabled><h2>No Bets available on this match</h2></Text>} />
        )}
      </Spin>
    </>
  );
};

export default FancyLoss;
