import React, { useEffect, useState } from 'react'; 
import {connect} from "react-redux";
import { Form, Row, Col,Select, Modal,  Input,InputNumber,  Button ,Card} from 'antd';  
import {NotificationContainer} from "react-notifications";
import {createUser} from "../../../appRedux/actions/Auth"; 
import SelectUpline from "../../components/SelectUpline";
import GoBack from "../../components/buttons/GoBack"
 

 
const FormItem = Form.Item;
const Option = Select.Option;
const genPass = Math.floor(1000 + Math.random() * 9000);
class CreateClient extends React.Component {
  constructor(props){  
    super(props); 
     this.state = {  
      ShowCommissions: false, isSubmitting: false,
      }   
    if((props.authUser.U_Type==="Admin"||props.authUser.U_Type==="Mini Admin"||props.authUser.U_Type==="Master"||props.authUser.U_Type==="Super") ){
      this.state = {  
        ShowForm:false  ,Upliners:null, ShowUpliners:true
        }  
    }else{
      this.state = {  
        ShowForm:true  ,Upliners:props.authUser, ShowUpliners:false
        } 

    }

   
  }  
  onUplineChange = (data)   => { 
   
    this.setState({
      Upliners:data
   });
    this.setState({
   ShowForm: true 
 });


};
   
    onCommission_TypeChange =  event  => {
     
    switch (event ) {
      case 'No Comm':
        
        this.setState({
          ShowCommissions: false,
        });

        return;

      case 'Bet by bet':
        
        this.setState({
          ShowCommissions: true,
        });
        return;

            
    }
  };

 onFinish  = (e) => {
        e.preventDefault();
         if (this.state.isSubmitting) {
          return; // Do nothing if form is already submitting
        }
      this.props.form.validateFields((err, values) => {
      

        values.Parent= this.state.Upliners.ID;
          values.U_Type="Client"

          if (!err) {
            this.setState({ isSubmitting: true });
         this.props.createUser(values) 
         .finally(() => {
          this.setState({ isSubmitting: false }); // Reset form submission state
        });
        }
      });
    };


  
  

  render() {
    const {getFieldDecorator } = this.props.form;     
    const {getFieldValue } = this.props.form;     
       const validateCoins = (rule, value, callback) => {
        const myCoins = getFieldValue('MyCoins'); 
        if (value > myCoins) {
          callback('Coins must be less than or equal to My Coins');
        } else {
          callback();
        }
      };    
  
    return (
      <>
           {this.state.ShowUpliners ?  
   
      <SelectUpline  Type='Agent'  onUplineChange = {this.onUplineChange}/>
                 

      : null}
        

{
              this.state.ShowForm?
         
      <div  className="ant-card ant-card-bordered">
         <div className="ant-card-head">
            <div className="ant-card-head-wrapper">
               <div className="ant-card-head-title">
                  <h1>Create Client</h1>
               </div>
               <div className="ant-card-extra"><span><GoBack/></span></div>
            </div>
         </div>
         <div className="ant-card-body">
            <div className="ant-spin-nested-loading">
               <div className="ant-spin-container">
               <Form
               name="advanced_search"
            className="ant-form ant-form-horizontal"
            onSubmit= {this.onFinish}
            style={{"marginTop": "30px","marginLeft": "5px", "marginBottom": "100px"}}
            labelAlign="left"
          labelCol={{
            span: 8,
          }} 
          
          colon={false} 
          >
              
                            <Row>
                                 <Col lg={12} md={12} sm={12} xs={24} >
               

                                 <FormItem   name="Name"
                    label="Name"  wrapperCol={{
                      span: 15,
                     }}
                    > 
                  {getFieldDecorator('Name', {
                      rules: [{
                      required: true,   message: 'Please enter name',
                    }],
                  })(
                    <Input  
                    id="fullName"
                     placeholder="Enter full name" />
                  )}
                </FormItem>


                              
                 
              </Col>
                           </Row>
                           <Row>
                                 <Col lg={12} md={12} sm={12} xs={24} >
               


                                 <FormItem   name="Reference"
                    label="Reference"  wrapperCol={{
                      span: 15,
                     }}
                    > 
                  {getFieldDecorator('Reference', {
                      rules: [{
                      required: true,   message: 'Please specify reference',
                    }],
                  })(
                    <Input  
                    id="Reference"
                     value={"26066"}
                   placeholder="Enter Reference" />
                  )}
                </FormItem>



 
                 
              </Col>
                           </Row>
                           <Row>
                                      <Col lg={12} md={12} sm={12} xs={24} >
                    
     
                                      <FormItem   name="MyCoins"
                         label="My Coins"  wrapperCol={{
                           span: 15,
                          }}
                         > 
                    


                      {getFieldDecorator('MyCoins', {
            initialValue: this.state.Upliners.Balance, // Set your initial value here
          })(<Input  id="myCoins" disabled placeholder="0" />)}
                        
                     </FormItem>
     
     
      
                      
                   </Col>
           
                                </Row>
                                <Row>
                                      <Col lg={12} md={12} sm={12} xs={24} >
                    
     
                                      <FormItem   name="Coins"
                         label="Coins"  wrapperCol={{
                           span: 15,
                          }}
                          
                         
                         > 
                       {getFieldDecorator('Coins', {
                           rules: [{
                           required: true,   message: 'Please enter valid Coins',
                           
                         },{validator: validateCoins }],
                       })(
                         <InputNumber   min={0} max={99999999999999999}   placeholder="Client Coins" id="Coins" step={1} style={{"width": "100%"}} />
           
                       )}
                     </FormItem>
     
     
     
      
                       
                      
                   </Col>
                                </Row>
            
                           <Row>
                                 <Col lg={12} md={12} sm={12} xs={24} >
               

                                 <FormItem   name="Password"
                    label="Password"  wrapperCol={{
                      span: 15,
                     }}
                    > 
                  {getFieldDecorator('Password', {
                     initialValue: genPass,
                     rules: [{
                      required: true,   message: 'Please enter password',
                    }],
                  })(
                    <Input   
                    id="Password"
                      placeholder='Password' 
                   />
                  )}
                </FormItem>


                            
                 
              </Col>
                           </Row>
                           {/* <Row>
                                 <Col lg={12} md={12} sm={12} xs={24} >
               

                                 <FormItem   name="Mobile_Charges"
                    label="Client Mobile Charge"  wrapperCol={{
                      span: 15,
                     }}
                    > 
                  {getFieldDecorator('Mobile_Charges', {
                        initialValue: 0,
                        rules: [{
                      required: true,   message: 'Invalid Mobile Charge',
                    }],
                  })(
                    <InputNumber   min={0} max={99999999999999999}   placeholder="Client Mobile charges" id="Mobile_Charges" step={1} style={{"width": "100%"}} />
      
   
                  )}
                </FormItem>

                            
                 
              </Col>
                           </Row>
                 */}
                   
                     <div className="ant-row"></div>
                     <div className="ant-row">
                        <div className="ant-col ant-col-xs-24 ant-col-sm-12 ant-col-md-12 ant-col-xl-12">
                           <h1>Client Match Share and Commission</h1>
                        </div>
                     </div>
      
       
                  
                  
                           <Row>
                                 <Col lg={12} md={12} sm={12} xs={24} >
               


                                 <FormItem   name="MyCommType"
                         label="My Comm type"  wrapperCol={{
                           span: 15,
                          }}
                         > 
                       
     <Select disabled value={this.state.Upliners.Commission_Type}  placeholder="My Comm type" id="MyCommType"
                width="100%"  allowClear>
                 <Option  value="No Comm">No Comm</Option>
                 <Option  value="Bet by bet">Bet by bet</Option>
               </Select>
                       
                     </FormItem>

                                  
                  
                 
              </Col>
                        
              <Col lg={12} md={12} sm={12} xs={24} >
               


              <FormItem   name="Commission_Type"
                    label="Comm type"  wrapperCol={{
                      span: 15,
                     }}
                    > 
                  {getFieldDecorator('Commission_Type', {
                      rules: [{
                      required: true,   message: 'Please select commission type',
                    }],
                  })(
                   
<Select  placeholder="Commission Type" id="Commission_Type" 
           width="100%"   onChange={this.onCommission_TypeChange} allowClear>
            <Option value="No Comm">No Comm</Option>
            <Option value="Bet by bet">Bet by bet</Option>
          </Select>
      
                  )}
                </FormItem>

 
      
      
      </Col>
      
                           </Row>
                         
             {
              this.state.ShowCommissions?
<>
              <Row>
   <Col lg={12} md={12} sm={12} xs={24} >
               
                 
   <Form.Item  
     name="MyMatchComm"
     label="My Match comm(%)"
     wrapperCol={{
     span: 15,
     }}
     >
        <InputNumber value={this.state.Upliners.Match_Commission} disabled  min={0} max={100}   placeholder="My Match commission" id="MyMatchComm" step={0.1} style={{"width": "100%"}} />
     
                     
        
     
     </Form.Item> 
     


</Col>
   
<Col lg={12} md={12} sm={12} xs={24} >

<FormItem   name="Match_Commission"
label="Match comm(%)"  wrapperCol={{
 span: 15,
}}
> 
{getFieldDecorator('Match_Commission', {
 rules: [{
 required: true,   message: 'Please enter odds commission',
}],
})(



<InputNumber    min={0} max={100}   placeholder="Match commission" id="Match_Commission" step={0.1} style={{"width": "100%"}} />



)}
</FormItem>





</Col>


              </Row>
              <Row>
                                 <Col lg={12} md={12} sm={12} xs={24} >
               



                                 <FormItem   name="MySessComm"
                         label="My Sess comm(%)"  wrapperCol={{
                           span: 15,
                          }}
                         > 
                   
     
     <InputNumber disabled value={this.state.Upliners.Session_Commission}  min={0} max={100}   placeholder="My Session commission" id="MySessComm" step={0.1} style={{"width": "100%"}} />
     
           
                     
                     </FormItem>




 
                  
                 
              </Col>
                        
              <Col lg={12} md={12} sm={12} xs={24} >
               

              <FormItem   name="Session_Commission"
                    label="Sess comm(%)"  wrapperCol={{
                      span: 15,
                     }}
                    > 
                  {getFieldDecorator('Session_Commission', {
                      rules: [{
                      required: true,   message: 'Please enter session commission',
                    }],
                  })(
                   
                    <InputNumber    min={0} max={100}   placeholder="Session commission" id="Session_Commission" step={0.1} style={{"width": "100%"}} />


      
                  )}
                </FormItem>



 
      
      
      </Col>
      
                           </Row>
                  



</>
              :

              <div></div>
            }
                           
                    
                    
                       
                     
      
                
                  
         <Row>
                                 <Col lg={12} md={12} sm={12} xs={24} >
               

                                 <FormItem   name="MyCasinoComm"
                         label="My Casino comm(%)"  wrapperCol={{
                           span: 15,
                          }}
                         > 
                         
                         
     <InputNumber  disabled value={this.state.Upliners.Casino_Commission} min={0} max={100}   placeholder="My Casino commission" id="MyCasinoComm" step={0.1} style={{"width": "100%"}} />
     
     
            
                     </FormItem>


 


 
                  
                 
              </Col>
                        
              <Col lg={12} md={12} sm={12} xs={24} >
               


              <FormItem   name="Casino_Commission"
                    label="Casino comm(%)"  wrapperCol={{
                      span: 15,
                     }}
                    > 
                  {getFieldDecorator('Casino_Commission', {
                      rules: [{
                      required: true,   message: 'Please enter valid Casino commission',
                    }],
                  })(
                   
                    <InputNumber     min={0} max={100}   placeholder="Casino commission" id="Casino_Commission" step={0.1} style={{"width": "100%"}} />


      
                  )}
                </FormItem>
             
      
      
      </Col>
      
                           </Row>
                  
                   
                     
      
       
                  
         <Row>
                                 <Col lg={12} md={12} sm={12} xs={24} >
               

                                 <FormItem   name="MyMatkaComm"
                         label="My Matka comm(%)"  wrapperCol={{
                           span: 15,
                          }}
                         > 
                          <InputNumber  disabled  value={this.state.Upliners.Matka_Commission} min={0} max={100}   placeholder="My Matka commission" id="MyMatkaComm" step={0.1} style={{"width": "100%"}} />
       
      
                     </FormItem>

                                   
                  
                 
              </Col>
                        
              <Col lg={12} md={12} sm={12} xs={24} >
               

              <FormItem   name="Matka_Commission"
                    label="Matka comm(%)"  wrapperCol={{
                      span: 15,
                     }}
                    > 
                  {getFieldDecorator('Matka_Commission', {
                      rules: [{
                      required: true,   message: 'Please enter valid Matka commission',
                    }],
                  })(
                   
                   
<InputNumber     min={0} max={100}   placeholder="Matka commission" id="Matka_Commission" step={0.1} style={{"width": "100%"}} />
  

      
                  )}
                </FormItem>

 
      
      
      </Col>
      
                           </Row>
                  
                   
                
                
                      <Row> 
                        <div className="ant-col ant-col-24 gx-text-right">
                     
               
                        <Button type="primary" className="ant-btn ant-btn-primary" htmlType="submit">Submit</Button> </div>
                      </Row>
                    
                  </Form>
      
      
               </div>
            </div>
         </div>
      </div>
      
      :null}
      
      
       
          
      <NotificationContainer/>
      
           
      
                      </>
    );
  }
}

const WrappedCreateClient = Form.create()(CreateClient);


const mapStateToProps = () => {
  const authUser =  JSON.parse(localStorage.getItem("authUser")); 
 
  return {authUser}
};
export default connect(mapStateToProps, {createUser})(WrappedCreateClient);
