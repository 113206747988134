import React,{useEffect,useState } from "react";   
import { useParams  } from "react-router-dom";  
import {  Alert, Row} from 'antd';  
 import   Segments   from "./segments";       
 import   ProfitLoss   from "./profit-loss"; 
import GoBack from "../../components/buttons/GoBack"

const PL_Segment = () => {
  const authUser=JSON.parse(localStorage.getItem("authUser"));  
 
  const { eid,bid  } = useParams()    
  const [ SegmentInputData,setSegmentInputData ] = useState({EID:eid,BID:bid,U_ID:authUser.ID})  ;
  const [ SegmentIDs,setSegmentID ] = useState({Admin:null,Mini:null,Master:null,Super:null,Agent:null})  ;
 
 const setSegment_ID=(Type,Value)=>{
  
  setSegmentID((prevSegmentIDs) => ({
    ...prevSegmentIDs,
    [Type]: Value,
  }));

 }
const setDefaultSegment_ID=()=>{

  
  if(authUser.U_Type=="Admin"){
    setSegment_ID("Admin",authUser.ID);
  }
  else if(authUser.U_Type=="Mini Admin"){
     setSegment_ID("Mini",authUser.ID);
  }
  else if(authUser.U_Type=="Master"){
     setSegment_ID("Master",authUser.ID);
  }
  else if(authUser.U_Type=="Super"){
      setSegment_ID("Super",authUser.ID);
  }
  else if(authUser.U_Type=="Agent"){
     setSegment_ID("Agent",authUser.ID);
  } 

} 

  useEffect( () => {
  setDefaultSegment_ID();
  setSegmentInputData({EID:eid,BID:bid,U_ID:authUser.ID});
  

  },  []);


 const getSegments =()=>{

return(
  <>
  {authUser.U_Type=="Admin"?
 <>
 <Segments Data={SegmentInputData}  SetSegmentID={setSegment_ID}   SegmentID={SegmentIDs.Admin} AccountType="Mini"/> 
   <Segments Data={SegmentInputData}  SetSegmentID={setSegment_ID}   SegmentID={SegmentIDs.Mini} AccountType="Master"/>
   <Segments Data={SegmentInputData}  SetSegmentID={setSegment_ID}   SegmentID={SegmentIDs.Master} AccountType="Super"/>
   <Segments Data={SegmentInputData}  SetSegmentID={setSegment_ID}   SegmentID={SegmentIDs.Super} AccountType="Agent"/>
   <Segments  Data={SegmentInputData} SetSegmentID={setSegment_ID}   SegmentID={SegmentIDs.Agent} AccountType="Client"/>
   </>
   :
   
   authUser.U_Type=="Mini Admin"?
 <> 
   <Segments Data={SegmentInputData}  SetSegmentID={setSegment_ID}   SegmentID={SegmentIDs.Mini}  AccountType="Master"/>
   <Segments Data={SegmentInputData}  SetSegmentID={setSegment_ID}   SegmentID={SegmentIDs.Master}  AccountType="Super"/>
   <Segments Data={SegmentInputData}  SetSegmentID={setSegment_ID}   SegmentID={SegmentIDs.Super}  AccountType="Agent"/>
   <Segments Data={SegmentInputData}  SetSegmentID={setSegment_ID}   SegmentID={SegmentIDs.Agent}  AccountType="Client"/>
   </>
   :
   
   authUser.U_Type=="Master"?
 <> 
   <Segments Data={SegmentInputData}  SetSegmentID={setSegment_ID}   SegmentID={SegmentIDs.Master}  AccountType="Super"/>
   <Segments Data={SegmentInputData}  SetSegmentID={setSegment_ID}   SegmentID={SegmentIDs.Super}  AccountType="Agent"/>
   <Segments Data={SegmentInputData}  SetSegmentID={setSegment_ID}   SegmentID={SegmentIDs.Agent}  AccountType="Client"/>
   </>
   :
   authUser.U_Type=="Super"?
 <> 
   <Segments  Data={SegmentInputData} SetSegmentID={setSegment_ID}   SegmentID={SegmentIDs.Super}  AccountType="Agent"/>
   <Segments Data={SegmentInputData}  SetSegmentID={setSegment_ID}   SegmentID={SegmentIDs.Agent}  AccountType="Client"/>
   </>
   :
   authUser.U_Type=="Agent"?
 <> 
   <Segments Data={SegmentInputData}  SetSegmentID={setSegment_ID}  SegmentID={SegmentIDs.Agent}  AccountType="Client"/>
   </>
   
   :null


   
   }
</>
)

 }


  return ( 
       <>
   <Row>
   {getSegments()}
   </Row>
 
  <div className="ant-card ant-card-bordered">
  <div className="ant-card-head">
    <div className="ant-card-head-wrapper">
      <div className="ant-card-head-title">Event Profit and Loss</div>
      <div className="ant-card-extra"><span><GoBack/></span></div>
    </div>
  </div>
  <div className="ant-card-body" style={{ padding: 0 }}> 
  
<ProfitLoss EID={eid} BID={bid}/>
  </div>
</div>
 </>






  );
};

export default PL_Segment;
