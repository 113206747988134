 
import React, { useState} from 'react';
import {Modal } from 'antd';  
import Notification from "../../../routes/components/notification";  
import {changePassword} from   "../../../appRedux/actions/rex"; 
const ChangePassword =(state)=>  { 
  const [_Modal, setModal] = useState({Visible:state.visible });    
  const [old_password, setOld_password] = useState('');
  const [new_password, setNew_password] = useState('');
const handleChangePassword=async()=>{

  await changePassword({Old_Password:old_password ,New_Password:new_password}) .then((res)=>{
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
    Notification(res.message,res.type )   ;          
 // state.refresh();
  });
  

}
  const handleOk = e => {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  
     setModal({Visible:false});   
   handleChangePassword()
    };
  const handleCancel = e => {
  
     setModal({Visible:false});
    
   
   };
   const handleOld_password = (e) => {
    // Update the state when the input changes
    setOld_password(e.target.value); 
  };
  
  const handleNew_password = (e) => {
    // Update the state when the input changes
    setNew_password(e.target.value); 
  };
    return (
   
  <Modal   title={<h1>Change Password</h1>} okText='Submit' cancelText='Return'  afterClose={state.CloseModal}  visible={_Modal.Visible}    onOk={handleOk}  onCancel={handleCancel} >
 <p>
  <input type="password" placeholder="Enter old password" className="ant-input"         onChange={handleOld_password}
      value={old_password}
      /></p>
  <p>
    <input type="password" placeholder="Enter new password" className="ant-input"         onChange={handleNew_password}
      value={new_password}
      /></p> 
   
      
   </Modal> 
   
    );
 
}

export default ChangePassword; 





 