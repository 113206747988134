 
import React, { useState,useEffect} from 'react';
import {Modal ,Typography} from 'antd'; 
import {  Table , Empty, Spin, Row,Col  } from 'antd';
import {getUserExpo}  from "../../../appRedux/actions/rex";
 
     
const { Text } = Typography;
const customEmptyText = (
 <div>
 <Empty  description={<Text disabled><h2>No Data available</h2></Text>} />
</div>
);


const UserExpo =(state)=>  { 
  const [_Modal, setModal] = useState({Visible:state.visible });  
  const [isLoading, setIsLoading] = useState(false);
 
 
  const [Exposure, setExposure] = useState([]); 

  const columns = [
    {
      title: 'Market',
      dataIndex: 'Market',
      key: 'Market', 
     },
     {
      title: 'Stake',
      dataIndex: 'Stake',
      key: 'Stake',
     },
     {
      title: 'Time',
      dataIndex: 'Time',
      key: 'Time',
     }  ];
       
const handleCancel = e => {
  
  setModal({Visible:false});
 

}; 

const getExpo=async()=>{
  await getUserExpo(state.id) .then((res)=>{
    setExposure(res) 
 setIsLoading(false);
});
}
 

   
useEffect(() => {
  const fetchData = async () => {
    setIsLoading(true);

    getExpo();
  };

  fetchData();
}, [state]);




    return (
   
  <Modal   title={<h1>{"User Exposure"}</h1>}   footer={null} afterClose={state.CloseModal}  visible={_Modal.Visible}      onCancel={handleCancel} >
   <Spin spinning={isLoading}>
      <Table width={100} size='small' bordered      scroll={{ x: true }}   pagination={false}    locale={{ emptyText:customEmptyText , }} columns={columns} dataSource={Exposure ? Exposure == null ? null : Exposure : null}/>
 </Spin>  
   
      
   </Modal> 
   
    );
 
}

export default UserExpo; 





 