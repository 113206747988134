import React from "react";
import {Route, Switch} from "react-router-dom";
import Cricket from "./cricket/index";   

const SportsDetails = ({match}) => (
  <Switch>
   
     <Route path={`${match.url}/cricket`} component={Cricket}/>   
  </Switch>
);

export default SportsDetails;
