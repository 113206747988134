import {   Table } from 'antd';
import React  from 'react'; 
const DeletedTransaction = (props) => {
  
const data = props.data; 
 
  const columns = [
    {
      title: 'Date',
      dataIndex: 'Date',
      key: 'Date', 
     },
     {
      title: 'Post Date',
      dataIndex: 'Post Date',
      key: 'Post Date',
     },
     {
      title: 'Collection',
      dataIndex: 'Collection',
      key: 'Collection',
     },
     {
      title: 'Debit',
      dataIndex: 'Debit',
      key: 'Debit',
     },
     {
      title: 'Credit',
      dataIndex: 'Credit',
      key: 'Credit',
     } ,
     {
      title: 'Payment Type',
      dataIndex: 'Payment Type',
      key: 'Payment Type',
     } ,
     {
      title: 'Remark',
      dataIndex: 'Remark',
      key: 'Remark',
     } ,
     {
      title: 'Done By',
      dataIndex: 'Done By',
      key: 'Done By',
     } ,
     {
      title: 'Deleted By',
      dataIndex: 'Deleted By',
      key: 'Deleted By',
     } 
      
  ];
  return <Table   width={100} size='small' bordered scroll={"left"}   columns={columns} dataSource={data} />;
};
export default DeletedTransaction;