import { SearchOutlined } from '@ant-design/icons';
import { Button, Input,  Table } from 'antd';
import React, {  useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
const Partnership = (props) => {
  
const data = props.data; 
 
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
           <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
         
        
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

 
  

 
  const columns = [
    {
      title: '#',
      dataIndex: 'SN',
      key: 'SN', 
     },
     {
      title: 'Date',
      dataIndex: 'Date',
      key: 'Date',
     },
     {
      title: 'Post Date',
      dataIndex: 'Post_Date',
      key: 'Post_Date',
     },
     {
      title: 'Collection Name',
      dataIndex: 'Collection_Name',
      key: 'Collection_Name',
     },
     {
      title: 'Debit',
      dataIndex: 'Debit',
      key: 'Debit',
     },
     {
      title: 'Credit',
      dataIndex: 'Credit',
      key: 'Credit',
     },
     {
      title: 'Balance',
      dataIndex: 'Balance',
      key: 'Balance',
     },
     ,
     {
      title: 'Payment Type',
      dataIndex: 'Payment_Type',
      key: 'Payment_Type',
     },
     ,
     {
      title: 'Remark',
      dataIndex: 'Remark',
      key: 'Remark',
     },
     ,
     {
      title: 'Done By',
      dataIndex: 'Done_By',
      key: 'Done_By',
     },
      
  ];
  return <Table   width={100} size='small' bordered scroll={"left"}   columns={columns} dataSource={data} />;
};
export default Partnership;