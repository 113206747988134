 
import React, { useState} from 'react';
import {Modal } from 'antd'; 
import {makeTransactions}  from "../../../appRedux/actions/rex";
import Notification  from "../../components/notification";
const DepositeChips =(state)=>  { 
  const [_Modal, setModal] = useState({Visible:state.visible });  
  const [chipsValue, setChipsValue] = useState('');

const handleDeposit=async()=>{ 
   
  await makeTransactions({_From:state.parent, _To:state.id, Ref_Type: "Transfer", Amount :chipsValue,Action:"Diposit"}) .then((res)=>{
    
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
    Notification(res.message,res.type )
  state.refresh();
  });
  

}
  const handleOk = e => {
   setModal({Visible:false, Title:"Deposite Chips - "});       
 if (chipsValue){ 
    
   handleDeposit();
  
  }else{
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            
    Notification( "Chips not valid!","Error")
    state.refresh();
  }

   };
  const handleCancel = e => {
  
     setModal({Visible:false});
    
   
   };
   const handleInputChange = (e) => {
    // Update the state when the input changes
    setChipsValue(e.target.value);
  };
    return (
   
  <Modal   title={<h1>{"Deposite Chips - "+state.code}</h1>} okText='Submit' cancelText='Return'  afterClose={state.CloseModal}  visible={_Modal.Visible}    onOk={handleOk}  onCancel={handleCancel} >
  <h1>Curr Coins : {state.coins}</h1><p>
  <input
            type="number"
            placeholder="chips"
            className="ant-input"
            value={chipsValue}
            onChange={handleInputChange}
          />
          </p>
    
   
      
   </Modal> 
   
    );
 
}

export default DepositeChips; 





 