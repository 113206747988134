import React from "react";
import {connect} from "react-redux";
import Partnership from "../../../components/tables/Partnership"
import GoBack from "../../../components/buttons/GoBack"


class LedgerPartnership extends React.Component {


   constructor(props){  
      super(props); 
       this.state = {  
        data: [
         {
           key: '1',
           User_Detail: 'G. A (MA6)',
           Balance: '10469.5',
         },
         {
           key: '2',
           User_Detail: 'Amit ji (MA955)',
           Balance: '47227.3', 
         },
        ]
        }   
      
  
     
    }  

render(){
   return ( 


      <>
      <div className="ant-card ant-card-bordered">
         <div className="ant-card-head">
            <div className="ant-card-head-wrapper">
               <div className="ant-card-head-title">
                  <span>
                     <h1 style={{"display": "inline-block"}}>Master Partnership</h1>
                  </span>
               </div>
               <div className="ant-card-extra"><span><GoBack/></span></div>
            </div>
         </div>
         <div className="ant-card-body">
            
         <form className="ant-form ant-form-horizontal" style={{"margin": "5px"}}>
   <div className="ant-row">
      <div className="ant-col ant-col-xs-24 ant-col-sm-12 ant-col-md-8 ant-col-xl-8">
         <span className="ant-calendar-picker" tabindex="0">
            <span className="ant-calendar-picker-input ant-input">
               <input readonly="" placeholder="Start date" className="ant-calendar-range-picker-input" tabindex="-1" value="2023-04-01"/>
                  <span className="ant-calendar-range-picker-separator"> ~ </span>
                  <input readonly="" placeholder="End date" className="ant-calendar-range-picker-input" tabindex="-1" value="2023-04-30"/>
                     <i aria-label="icon: close-circle" tabindex="-1" className="anticon anticon-close-circle ant-calendar-picker-clear"></i>
                     <i aria-label="icon: calendar" className="anticon anticon-calendar ant-calendar-picker-icon"></i></span></span></div>
      <div className="ant-col ant-col-xs-24 ant-col-sm-12 ant-col-md-8 ant-col-xl-8">
         <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label ant-form-item-label-left ant-col-xs-24 ant-col-sm-8 ant-col-md-8 ant-col-lg-8"><label for="user_id" className="ant-form-item-required ant-form-item-no-colon" title="Client">Client</label></div>
            <div className="ant-col ant-form-item-control-wrapper ant-col-xs-24 ant-col-sm-15 ant-col-md-15 ant-col-lg-15">
               <div className="ant-form-item-control has-success">
                  <span className="ant-form-item-children">
                     <div id="user_id" className="ant-select ant-select-enabled" style={{"width": "100%"}}>
                        <div className="ant-select-selection
                           ant-select-selection--single" role="combobox" aria-autocomplete="list" aria-haspopup="true" aria-controls="d3475fce-2353-419d-acb9-7ff03d30700c" aria-expanded="false" data-__meta="[object Object]" data-__field="[object Object]" tabindex="0">
                           <div className="ant-select-selection__rendered">
                              <div unselectable="on" className="ant-select-selection__placeholder" style={{"display": "none", "user-select": "none"}}>Select Client</div>
                              <div className="ant-select-selection-selected-value" title="MA7 (Pradeep)" style={{"display": "block", "opacity": "1"}}>MA7 (Pradeep)</div>
                              <div className="ant-select-search ant-select-search--inline" style={{"display": "none"}}>
                                 <div className="ant-select-search__field__wrap">
                                    <input id="user_id" autocomplete="off" className="ant-select-search__field" value=""/>
                                    <span className="ant-select-search__field__mirror">&nbsp;</span></div>
                              </div>
                           </div>
                           <span className="ant-select-arrow" unselectable="on" style={{"user-select": "none"}}>
                              <i aria-label="icon: down" className="anticon anticon-down ant-select-arrow-icon">
                                 <svg viewBox="64 64 896 896" focusable="false" className="" data-icon="down" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                    <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path>
                                 </svg>
                              </i>
                           </span>
                        </div>
                     </div>
                  </span>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div className="ant-row">
      <div className="ant-col ant-col-xs-24 ant-col-sm-12 ant-col-md-8 ant-col-xl-8">
         <input type="number" min="0" precision="0" placeholder="Enter share" className="ant-input" value="0" style={{"width": "100%"}}/></div>
      <div className="ant-col ant-col-xs-24 ant-col-sm-12 ant-col-md-8 ant-col-xl-8"><button type="submit" className="ant-btn ant-btn-primary"><span>Submit</span></button></div>
   </div>
</form>
         </div>
      </div>
             
      <div className="ant-card ant-card-bordered">
   <div className="ant-card-body" style={{"padding": "0px"}}>
      <div className="ant-row gx-center">
         <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-xl-24">
<Partnership/>


         </div>
      </div>
   </div>
</div> 
             
             
             
             
                  </>
  
  
  
  
  
  
  
    );}
};

const mapStateToProps = () => {
  const authUser =  JSON.parse(localStorage.getItem("authUser")); 
 
  return {authUser}
};
export default connect(mapStateToProps)(LedgerPartnership);
 