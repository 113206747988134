// Login.js
import React, { useState ,useEffect} from 'react';
import io from 'socket.io-client';

export const ApiSocket = () => {  

    const [socket, setSocket] = useState(null);
    const [messages, setMessages] = useState([]);
    
    useEffect(() => {
      // Connect to WebSocket server with token
      const token = JSON.parse(localStorage.getItem('token'));
const socket = io.connect('ws://localhost:8081', {
  auth: { token},
  transports: ['websocket'],
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: 999999
});
      
      socket.on('connect', () => {
        socket.emit('bets', "hi");
         console.log('Connected to WebSocket server');
      });
      
      socket.on('disconnect', () => {
        console.log('Disconnected from WebSocket server');
      });
  
      // Handle incoming messages from server
      socket.on('message', (message) => {
        console.log('Received message:', message);
        setMessages(prevMessages => [...prevMessages, message]);
      });
  
      setSocket(socket);
  
      return () => {
        // Cleanup on unmount
        if (socket) {
          socket.disconnect();
        }
      };
    }, []);
  
    const sendMessage = (message) => {
      if (socket) {
        socket.emit('message', message);
      }
    };
   

  return  false;
};
 
