import { Row, Col,  Table,  Spin } from 'antd';
 
import React, { useState, useEffect } from 'react'; 
import {getMarket_PL} from "../../appRedux/actions/rex"
const Markets = () => {
     const [loading, setLoading] = useState(true);
   
   const [Events, setEvents] = useState([]);

   useEffect(() => {
      const fetchData = async () => {
     
    await getMarket_PL().then((result)=>{ 
    setEvents( result);  
    setLoading(false);
    });
      };

      fetchData();
  }, []);

 
  const columns = [
    {
      title: 'Title',
      dataIndex: 'Event_Name',
      key: 'Event-Name',
      render: (Status,row) =>  (
        <a href={"/event-pnl/"+row.Event_Id}><span>{row.Event_Name}</span> </a> 
      ),
      },
     {
      title: 'Sport',
      dataIndex: 'Sport',
      key: 'Sport',
     },
     {
      title: 'Open Date',
      dataIndex: 'Time',
      key: 'Time',
     },
     {
      title: 'Declared',
      dataIndex: 'Declared',
      key: 'Declared',
     },
     
    {
      title: 'Won by',
      dataIndex: 'Won_by',
      key: 'Won-by',
       },
    {
      title: 'Profit Loss',
      dataIndex: 'Profit_Loss',
      key: 'Profit-Loss',
      align: 'right',
      render: (Status,row) =>  (
      <span style={{color:row.Profit_Loss<0?"red":"green"}}>{parseFloat(row.Profit_Loss).toFixed(2)}</span> 
       ),
        }
      
  ];


return (
  <Row>
 
    <Col xl={24} md={24} sm={24} xs={24}>
      <Spin spinning={loading} tip="Loading...">
      <Table width={100} size='small' bordered scroll={{ x: 'max-content' }} pagination={false} columns={columns} dataSource={Events ? Events == null ? null : Events : null} />
    
      </Spin>
    </Col>
  </Row>
);
};

export default Markets;