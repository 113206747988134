import React  from 'react'; 
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET
} from "../../constants/ActionTypes";
 
import {  Modal} from 'antd'; 
import { NotificationManager} from "react-notifications"; 
import IntlMessages from "util/IntlMessages";
import axios from 'util/Api' 

const modal = Modal.info; 
const authUser = JSON.parse(localStorage.getItem("authUser"));
   
const createNotification = (type) => {

      
  return () => {
    switch (type) {
      case 'info':
        NotificationManager.info(<IntlMessages id="notification.infoMsg"/>);
        break;
        case 'success':
          NotificationManager.success(<IntlMessages id="notification.successMessage"/>, <IntlMessages
          id="notification.titleHere"/>);
        break;
        case 'success.top':
          NotificationManager.success(<IntlMessages id="notification.successTopMessage"/>, <IntlMessages
          id="notification.titleHere"/>);
        break;
      case 'warning':
        NotificationManager.warning(<IntlMessages id="notification.warningMessage"/>, <IntlMessages
          id="notification.closeAfter3000ms"/>, 3000);
        break;
      case 'error':
        NotificationManager.error(<IntlMessages id="notification.errorMessage"/>, <IntlMessages
          id="notification.clickMe"/>, 5000, () => {
          alert('callback');
        });
        break;
      default:
        NotificationManager.info(<IntlMessages id="notification.infoMsg"/>);
        break;

    }
  };
};
const showSuccess=(ID)=>{

  NotificationManager.success(<IntlMessages id="Success"/>, <IntlMessages
  id="User Added"/>);
  modal({
    title:'ID: ' + ID,
    content: '',
    onOk() {
      window.history.back();
    } 
  });
 
}
const showUpdateSuccess=(ID)=>{

  NotificationManager.success(<IntlMessages id="Success"/>, <IntlMessages
  id="User Updated!"/>);
  
 
}
export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const userSignUp = ({email, password, name}) => {
  console.log(email, password);
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('auth/register', {
        email: email,
        password: password,
        name: name
      }
    ).then(({data}) => {
      console.log("data:", data);
      if (data.result) {
        localStorage.setItem("token", JSON.stringify(data.token.access_token));
        axios.defaults.headers.common['access-token'] = "Bearer " + data.token.access_token;
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: USER_TOKEN_SET, payload: data.token.access_token});
        dispatch({type: USER_DATA, payload: data.user});
      } else {
        console.log("payload: data.error", data.error);
        dispatch({type: FETCH_ERROR, payload: "Network Error"});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const createUser = (values  ) => { 
    
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('user/create',  values
    ).then(({data}) => {
       if (data.success===true) {
         showSuccess(data.ID);
          getUserState() ;
     
        dispatch({type: FETCH_SUCCESS});
      } else {
         dispatch({type: FETCH_ERROR, payload: data.message});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      
    });
  }
};

export const updateUser = (values  ) => { 
    
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('user/update',  values
    ).then(({data}) => {
       if (data.success===true) {
        showUpdateSuccess(data.ID);
        dispatch({type: FETCH_SUCCESS});
      } else {
         dispatch({type: FETCH_ERROR, payload: data.message});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      
    });
  }
};
export const userSignIn = ({name, password}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
   
      
     axios.post('user/signin', {
      name: name,
        password: password,
      }
    ).then(({data}) => {
        if (data.success===true) {
      localStorage.setItem("token", JSON.stringify(data.token)); 
      localStorage.setItem("authUser", JSON.stringify( data.userInfo)); 
      axios.defaults.headers.common['access-token'] = "Bearer " + data.token;
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: USER_TOKEN_SET, payload:data.token});
      } else {
         
         dispatch({type: FETCH_ERROR, payload: data.message});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

 
export const getUser = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('user/info',
    ).then(({data}) => {
       if (data.success===true) {
         localStorage.setItem("authUser", JSON.stringify( data.user)); 
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: USER_DATA, payload: data.user});
      } else {
        dispatch({type: FETCH_START});
         localStorage.removeItem("token");
        localStorage.removeItem("authUser"); 
      dispatch({type: FETCH_SUCCESS});
       dispatch({type: SIGNOUT_USER_SUCCESS});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      });
  }
};

export const getUserState = async() => {
   
    
  return await  axios.post('user/info',
    ).then(({data}) => {
       if (data.success===true) {
         localStorage.setItem("authUser", JSON.stringify( data.user)); 
        
       return true
      } else {
 
        return false
     
      }
    }).catch(function (error) {
      return false
      });
   
};

export const userSignOut = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    localStorage.removeItem("token");
    localStorage.removeItem("authUser"); 
    dispatch({type: FETCH_SUCCESS});
      dispatch({type: SIGNOUT_USER_SUCCESS});
     
  }
};

