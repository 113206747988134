import React,{useState}  from "react";  
import {   useParams} from "react-router-dom"; 
import { Row,Col,DatePicker ,Radio, RadioChangeEvent } from 'antd';
import moment from "moment";
import Statements from "../../components/tables/Statement"
import GoBack from "../../components/buttons/GoBack"
const {  RangePicker} = DatePicker;
const Statement = () => {
   
 const {U_ID } = useParams();   
 const [TotalCount, setTotalCount] = useState(0);
 const [value4, setValue4] = useState('all');
 const HandleTotalCount=(Count=0)=>{

   setTotalCount(Count);

 }
  
 const onChange4 = ({ target: { value } }) => {
    console.log('radio4 checked', value);
   setValue4(value);
 };
    return (<> 
<div className="ant-card ant-card-bordered">
   <div className="ant-card-head">
      <div className="ant-card-head-wrapper">
         <div className="ant-card-head-title">
            <span>
               <h1 style={{"display": "inline-block"}}>List Of All Transactions ({TotalCount})</h1>
            </span>
         </div>
         <div className="ant-card-extra"><span><GoBack/></span></div>
      </div>
   </div>
   <div className="ant-card-body">

   <Row> 
   <Col lg={8} md={8} sm={24} xs={24} style={{"text-align": "center","margin-left":"20px"}}>
       <div style={{"marginTop": "10px"}}>
       <RangePicker className="gx-mb-2 gx-w-100"
                   ranges={{Today: [moment(), moment()], 'This Month': [moment(), moment().endOf('month')]}}
                   />
      </div>
  </Col>
  <Col lg={8} md={8} sm={24} xs={24} style={{"text-align": "center","margin-left":"20px"}}>
       <div style={{"marginTop": "10px"}}>
      
       <Radio.Group defaultValue="all"onChange={onChange4}   buttonStyle="solid">
      <Radio.Button value="all">All</Radio.Button>
      <Radio.Button value="pl">P&L</Radio.Button>
      <Radio.Button value="pdc">PDC</Radio.Button>
      <Radio.Button value="comm">Comm.</Radio.Button>
      <Radio.Button value="account">Account.</Radio.Button>
    </Radio.Group>
   
      </div>
  </Col>
  
</Row>
       
      <div className="ant-table-wrapper">
         <div className="ant-spin-nested-loading">
            <div className="ant-spin-container">
            <Statements  U_ID={U_ID} setTotalCount={HandleTotalCount} />
     
               </div>
         </div>
       
      </div>
   </div>
</div> 
            </>
               );
 

 
}


 
 
 
export default Statement;
