import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import GoBack from "../../components/buttons/GoBack"
import ProfitLoss from "./profit-loss";
const FancySlips = () => {

   const { eid } = useParams()
   return (


      <>

    


         <div className="ant-card ant-card-bordered">
            <div className="ant-card-head gx-mb-3 ">
               <div className="ant-card-head-wrapper">
                  <div className="ant-card-head-title">
                     <span>
                        <h1 style={{ "display": "inline-block" }}>Fancy Bets</h1>
                     </span>
                  </div>
                  <div className="ant-card-extra"><span><GoBack /></span></div>
               </div>
            </div>
            <div className="ant-card-body ">

               <ProfitLoss EID={eid} />


            </div>
         </div>
      </>







   );
};

export default FancySlips;
