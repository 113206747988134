import React from 'react'; 
import {connect} from "react-redux"; 
import Markets_PL from "./Markets_PL"; 
     
const Markets = () => {
    
   const authUser=JSON.parse(localStorage.getItem("authUser"));  

    return (
      <>
          
          
      
          <div className="ant-card ant-card-bordered gx-card" style={{"padding": "20px"}}>
      
  {(authUser.U_Type==="Admin")?
          <div className="ant-row">
      <div className="ant-col gx-text-center ant-col-xs-12 ant-col-sm-12 ant-col-md-4 ant-col-lg-4 ant-col-xl-4">
         <a href="/message-to-player">
            <div className="ant-card ant-card gx-card-full gx-p-3 btn-grad ant-card-bordered">
               <div className="ant-card-body">
                  <p className="gx-mb-0" style={{"color": "rgb(255, 255, 255)"}}>Player Message</p>
               </div>
            </div>
         </a>
      </div>
      <div className="ant-col gx-text-center ant-col-xs-12 ant-col-sm-12 ant-col-md-4 ant-col-lg-4 ant-col-xl-4">
         <a href="/message-to-upline">
            <div className="ant-card ant-card gx-card-full gx-p-3 btn-grad ant-card-bordered">
               <div className="ant-card-body">
                  <p className="gx-mb-0" style={{"color": "rgb(255, 255, 255)"}}>Dealer Message</p>
               </div>
            </div>
         </a>
      </div>
     
   </div>
  :null}
   <div className="ant-row">
      <div className="ant-col gx-text-center ant-col-xs-12 ant-col-sm-12 ant-col-md-4 ant-col-lg-4 ant-col-xl-4">
         <a href="/account-statement">
            <div className="ant-card ant-card gx-card-full gx-p-3 btn-grad ant-card-bordered">
               <div className="ant-card-body">
                  <p className="gx-mb-0" style={{"color": "rgb(255, 255, 255)"}}>Statements</p>
               </div>
            </div>
         </a>
      </div>
      <div className="ant-col gx-text-center ant-col-xs-12 ant-col-sm-12 ant-col-md-4 ant-col-lg-4 ant-col-xl-4">
         <a href="/account-operations">
            <div className="ant-card ant-card gx-card-full gx-p-3 btn-grad ant-card-bordered">
               <div className="ant-card-body">
                  <p className="gx-mb-0" style={{"color": "rgb(255, 255, 255)"}}>A/c operations</p>
               </div>
            </div>
         </a>
      </div>
      <div className="ant-col gx-text-center ant-col-xs-12 ant-col-sm-12 ant-col-md-4 ant-col-lg-4 ant-col-xl-4">
         <a href="/profitandloss">
            <div className="ant-card ant-card gx-card-full gx-p-3 btn-grad ant-card-bordered">
               <div className="ant-card-body">
                  <p className="gx-mb-0" style={{"color": "rgb(255, 255, 255)"}}>Profit &amp; Loss</p>
               </div>
            </div>
         </a>
      </div>
      <div className="ant-col gx-text-center ant-col-xs-12 ant-col-sm-12 ant-col-md-4 ant-col-lg-4 ant-col-xl-4">
         <a href="/casinoprofitandloss">
            <div className="ant-card ant-card gx-card-full gx-p-3 btn-grad ant-card-bordered">
               <div className="ant-card-body">
                  <p className="gx-mb-0" style={{"color": "rgb(255, 255, 255)"}}>Casino Profit &amp; Loss</p>
               </div>
            </div>
         </a>
      </div>
   </div>
   <div className="ant-row">
      <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 ant-col-xl-24">
         <div className="ant-card ant-card-bordered">
            <div className="ant-card-body">
               
                                             <Markets_PL/>
            </div>
         </div>
      </div>
    </div>

    </div>

      
                      </>
    );
    }

 
 
export default Markets; 
