import React from "react";
import {Button, Checkbox, Form, Input} from "antd";
import {connect} from "react-redux";

import {userSignIn} from "../appRedux/actions/Auth";
import InfoView from "components/InfoView";

const FormItem = Form.Item;

class SignIn extends React.Component {

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.userSignIn(values);
      }
    });
  };

  componentDidUpdate() {
    if (this.props.token !== null) {
      this.props.history.push('/');
    }
  }

  render() {
    const {getFieldDecorator} = this.props.form;

    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content gx-new-login">
             
            <div className="gx-app-login-content" style={{"margin": "0 auto"}}>
              

            <div class="gx-app-logo-content" style={{backgroundColor: "rgb(0, 0, 0)", margin: "auto"}}>
              <div class="gx-app-logo" style={{margin: "auto"}}>
                <img alt="example"  src="/assets/images/logo.png"/>
                  </div>
                  </div>
              <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">

                <FormItem>
                  {getFieldDecorator('name', {
                    initialValue: "admin",
                    rules: [{
                      required: true,   message: 'The input is not valid user ID!',
                    }],
                  })(
                    <Input placeholder="User ID"/>
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('password', {
                    initialValue: "ipshu@5630",
                    rules: [{required: true, message: 'Please input your Password!'}],
                  })(
                    <Input type="password" placeholder="Password"/>
                  )}
                </FormItem>
          
                <Form.Item>
                <Button type="primary" className="gx-mb-0" style={{width: '100%', background: 'rgb(224, 182, 20)'}} htmlType="submit">
                  Login
                </Button> 
              </Form.Item>
               
              </Form>
            </div>
            <InfoView/>
          </div>
        </div>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({auth}) => {
  const {token} = auth;
  return {token}
};

export default connect(mapStateToProps, {userSignIn})(WrappedNormalLoginForm);
