 
const { calculatePercentage,calculateTotalPL, getCompanyCommissionOnSessionBet, getCompanyCommissionOnOddsByBet, getMyCommissionOnOddsBet, getBetProfitLoss, getCommissionRateOnBet, getCommission_ID, calculateWinLoss } = require('./rex-help');
 
 async function CalculateEventPL(Events ) {
let EventBalance=0.00;
    for (const Event of Events) {
     if (Event.Event_Id===33095223){
        let U_Type ;
        let TotalSessionPL = 0.00;
        let TotalSessionCommission = 0.00;
        let TotalMatchPL = 0.00;
        let TotalMatchCommission = 0.00;
       let TotalShare = 0.00;

                      if (Event.Sessions.length > 0) {
                        for (const Session of Event.Sessions) {
                          U_Type=Session.U_Type;
                          Session.Commission_ID = getCommission_ID(Session)
                          Session.WinLoss = calculateWinLoss(Session)
                          Session.ProfitLoss = getBetProfitLoss(Session)
                          Session.CommissionRate = getCommissionRateOnBet(Session)
                        
                          Session.MyBetCommission = getCompanyCommissionOnSessionBet(Session)
                       

                           
                            TotalSessionPL=TotalSessionPL+  Session.ProfitLoss;
                            TotalSessionCommission=TotalSessionCommission+Session.MyBetCommission;
                              
                            TotalShare = Session.MyShare;
                          }
 
                       
                    }

                    if (Event.Odds.length > 0) {
                      for (const Odd of Event.Odds) {
                        U_Type=Odd.U_Type;
                     
                        Odd.Commission_ID = getCommission_ID(Odd)
                        Odd.WinLoss = calculateWinLoss(Odd)
                        Odd.ProfitLoss = getBetProfitLoss(Odd)
                        Odd.CommissionRate = getCommissionRateOnBet(Odd)
                      
                       

                         
                          TotalMatchPL=TotalMatchPL+  Odd.ProfitLoss;
                             
                          TotalShare = Odd.MyShare;
                        }

                     
                  }

                    
                 
                  Event.TotalSessionPL = TotalSessionPL;
                  Event.TotalSessionCommission = TotalSessionCommission;
                  Event.TotalMatchPL = TotalMatchPL;
                  
 
                  Event.TotalMatchCommission =(Event.Odds.length>0?getCompanyCommissionOnOddsByBet(Event.Odds ):0.00);
                  Event.TotalCommission =Event.TotalSessionCommission+  Event.TotalMatchCommission ;
                  Event.TotalShare = TotalShare;
                  Event.TotalPL =TotalSessionPL+ TotalMatchPL;
              
                
    Event.Sessions = []
                  Event.Odds = []

 
               if (U_Type ==="Client") { 
                Event.Total_Amount =  (Event.TotalPL+( Event.TotalMatchCommission+ Event.TotalSessionCommission))
               }else{
                Event.Total_Amount =    (Event.TotalPL-( Event.TotalMatchCommission+ Event.TotalSessionCommission))
                 
                  
                 }  
                 Event.Share =     calculatePercentage(Event.Total_Amount, Event.TotalShare);
                 Event.Net_Amount =  Event.Total_Amount-calculatePercentage(Event.Total_Amount,  Event.TotalShare)    ;







                 if (U_Type ==="Client" &&  Event.Net_Amount <0 ) { 
                  Event.Balance=   Math.abs(Event.Net_Amount)
                   }
                   else if( U_Type ="Client" && Event.Net_Amount >0) {
                 
                    Event.Balance=    -Event.Net_Amount
                    } else{
                 
                      Event.Balance=       Event.Net_Amount
                     }   
                     
                   EventBalance=EventBalance+Event.Balance;


                   
   alert(JSON.stringify(Event ))

       
  


    }
  }

  Events.Balance=EventBalance;
  

    return Events.Balance

}

export const _getUserLedger =async( User)=> {

  User.Balance= await CalculateEventPL(User.Events);
  
return User

}

