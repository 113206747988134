


import React, { useState} from 'react';
import {Modal } from 'antd';
import {makeTransactions}  from "../../../appRedux/actions/rex";
import Notification  from "../../components/notification";
const WithdrawalChips =(state)=>  { 
  const [_Modal, setModal] = useState({Visible:state.visible });  
  const [chipsValue, setChipsValue] = useState('');

  const handleWithdraw=async()=>{

    await makeTransactions({_From:state.parent, _To:state.id, Ref_Type: "Transfer", Amount :chipsValue,Action:"Withdraw"}) .then((res)=>{
      
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               
      Notification(res.message,res.type )
    state.refresh();
    });
    
  
  }

  const handleOk = e => {
    setModal({Visible:false, Title:"Partnership Info - "});                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          
    if (chipsValue){ 
      if (chipsValue>0){ 

   handleWithdraw();
   
      }else{
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            
        Notification( "Insufficient Chips to withdraw!","Error");
        state.refresh();
      }
  }else{
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            
    Notification( "Chips not valid!","Error");
     state.refresh();
  }
   };
  

  const handleCancel = e => {
  
     setModal({Visible:false});
  
   };
   const handleInputChange = (e) => {
    // Update the state when the input changes
    setChipsValue(e.target.value);
  };
    return (
   
  <Modal   title={<h1>{"Withdrawal Chips - "+state.code}</h1>} okText='Submit' cancelText='Return'  afterClose={state.CloseModal}  visible={_Modal.Visible}    onOk={handleOk}  onCancel={handleCancel} >
  <h1>Curr Coins : {state.coins}</h1><p>
     
    <input
            type="number"
            placeholder="chips"
            className="ant-input"
            value={chipsValue}
            onChange={handleInputChange}
          />
      </p>
   </Modal>
    );
 
}

export default WithdrawalChips; 





 