
 

const FindJsonValue = (array, whereKey, whereValue, resKey)=> {

    var length = Object.keys(array).length;
   
    for (var i = 0; i < length; i++) {

        if (array[i][whereKey] == whereValue) {
             return array[i][resKey];

        }
       
       
    }

}


const findNestedKey=(entireObj, keyToFind)=> {
    let foundValue;
    JSON.stringify(entireObj, (_, nestedValue) => {
          if (  nestedValue[keyToFind]) {
            foundValue = nestedValue[keyToFind];
            
       }
        return nestedValue;
    });
        
       return foundValue;
};
const findNestedObj=(entireObj, keyToFind, valToFind)=> {
    let foundObj;
    JSON.stringify(entireObj, (_, nestedValue) => {
       
          if (  nestedValue[keyToFind] == valToFind) {
            foundObj = nestedValue;
            
       }
        return nestedValue;
    });
    return foundObj;
};
const FindJson=(array, whereKey, whereValue)=> {

    var length = Object.keys(array).length;

    for (var i = 0; i < length; i++) {

        if (array[i][whereKey] == whereValue) {
            return array[i] ;

        }


    }

}

const SetJsonValueKeyValue=(array, whereKey, whereValue, setKey, setValue)=> {
    var length = Object.keys(array).length;

    for (var i = 0; i < length; i++) {

        if (array[i][whereKey] == whereValue) {
           
        array[i][setKey] = setValue;

        }
    }
}

  
const SetJsonValue=(array, whereKey , setValue)=> {
    var length = Object.keys(array).length;

    for (var i = 0; i < length; i++) {

            
        array[i][whereKey] = setValue;

        
    }
}

module.exports = {FindJsonValue, findNestedObj, FindJson, SetJsonValue,findNestedKey};