  
 
import React  from "react"; 
 import { useParams  } from "react-router-dom"; 
const TV = () => {  
   
  
  const { eid } = useParams();
   return (
    
    <><iframe id="tv" width="100%" allowfullscreen="" frameborder="0"  referrerpolicy="no-referrer" class="live-tv-frame" src={"https://tv.yply.xyz/?eid="+eid} height="168"></iframe>
   </>     
        
  );
}

export default TV;
