import React, { useEffect, useState } from 'react'; 
import {connect} from "react-redux";
import { Form, Row, Col,Select, Modal,  Input,InputNumber,  Button ,Card} from 'antd';  
import {NotificationContainer} from "react-notifications";
import {updateUser} from "../../../appRedux/actions/Auth";  
import {getUserParent} from "../../../appRedux/actions/rex";  
import GoBack from "../../components/buttons/GoBack"
 

 
const FormItem = Form.Item;
const Option = Select.Option;
const genPass = Math.floor(1000 + Math.random() * 9000);
class UpdateClient extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      ShowCommissions: false,
      ShowForm: false,
      Upliners: {}, // Initialize Upliners as an empty object
      ShowUpliners: false,
    };
  }

  async componentDidMount() {
    const { U_ID } = this.props.match.params;

    try {
      // Fetch Upliners data using await in componentDidMount
      const Details = await getUserParent(U_ID); 
      // Update the state with the fetched data
      this.setState({ Upliners:Details.upliner,User:Details.user, ShowForm: true,ShowCommissions: Details.user.Commission_Type=="Bet by bet"?true:false 
      });
    } catch (error) {
      console.error('Error fetching Upliners data:', error);
    }
  }
  

    onCommission_TypeChange = event   => {
    switch (event ) {
      case 'No Comm':
        
        this.setState({
          ShowCommissions: false,
        });

        return;

      case 'Bet by bet':
        
        this.setState({
          ShowCommissions: true,
        });
        return;

            
    }
  };

  onFinish  = (e) => {
        e.preventDefault();
      this.props.form.validateFields((err, values) => {
      
       
       
    values.Updater= this.props.authUser.ID;
    values.ID= this.state.User.ID;  
          if (!err) {
 
         this.props.updateUser(values) ;
         
        }
      });
    };
  
  

  render() {
    const {getFieldDecorator } = this.props.form;      
  
    return (
      <> 
        

{
              this.state.ShowForm?
         
      <div  className="ant-card ant-card-bordered">
         <div className="ant-card-head">
            <div className="ant-card-head-wrapper">
               <div className="ant-card-head-title">
                  <h1>Update Client</h1>
               </div>
               <div className="ant-card-extra"><span><GoBack/></span></div>
            </div>
         </div>
         <div className="ant-card-body">
            <div className="ant-spin-nested-loading">
               <div className="ant-spin-container">
               <Form
               name="advanced_search"
            className="ant-form ant-form-horizontal"
            onSubmit= {this.onFinish}
            style={{"marginTop": "30px","marginLeft": "5px", "marginBottom": "100px"}}
            labelAlign="left"
          labelCol={{
            span: 8,
          }} 
          
          colon={false} 
          >
              
                            <Row>
                                 <Col lg={12} md={12} sm={12} xs={24} >
               

                                 <FormItem   name="Name"
                    label="Name"  wrapperCol={{
                      span: 15,
                     }}
                    > 
                  {getFieldDecorator('Name', {
                             initialValue: this.state.User.Name,
                      rules: [{
                      required: true,   message: 'Please enter name',
                    }],
                  })(
                    <Input  
                    id="fullName"
                     placeholder="Enter full name" />
                  )}
                </FormItem>


                              
                 
              </Col>
                           </Row>
                           <Row>
                                 <Col lg={12} md={12} sm={12} xs={24} >
               


                                 <FormItem   name="Reference"
                    label="Reference"  wrapperCol={{
                      span: 15,
                     }}
                    > 
                  {getFieldDecorator('Reference', {
                             initialValue: this.state.User.Reference,
                      rules: [{
                      required: true,   message: 'Please specify reference',
                    }],
                  })(
                    <Input  
                    id="Reference"
                     value={"26066"}
                   placeholder="Enter Reference" />
                  )}
                </FormItem>



 
                 
              </Col>
                           </Row>
                           
                           
            
                           <Row>
                                 <Col lg={12} md={12} sm={12} xs={24} >
               

                                 <FormItem   name="Password"
                    label="Password"  wrapperCol={{
                      span: 15,
                     }}
                    > 
                  {getFieldDecorator('Password', {
                             initialValue: this.state.User.Password, 
                     rules: [{
                      required: true,   message: 'Please enter password',
                    }],
                  })(
                    <Input   
                    id="Password"
                      placeholder='Password' 
                   />
                  )}
                </FormItem>


                            
                 
              </Col>
                           </Row>
                        
                   
                     <div className="ant-row"></div>
                     <div className="ant-row">
                        <div className="ant-col ant-col-xs-24 ant-col-sm-12 ant-col-md-12 ant-col-xl-12">
                           <h1>Match Share and Commission</h1>
                        </div>
                     </div>
      
       
                  
                  
                           <Row>
                                 <Col lg={12} md={12} sm={12} xs={24} >
               


                                 <FormItem   name="MyCommType"
                         label="AGENT Comm type"  wrapperCol={{
                           span: 15,
                          }}
                         > 
                       
     <Select disabled value={this.state.Upliners.Commission_Type}  placeholder="My Comm type" id="MyCommType"
                width="100%"  allowClear>
                 <Option  value="No Comm">No Comm</Option>
                 <Option  value="Bet by bet">Bet by bet</Option>
               </Select>
                       
                     </FormItem>

                                  
                  
                 
              </Col>
                        
              <Col lg={12} md={12} sm={12} xs={24} >
               


              <FormItem   name="Commission_Type"
                    label="Comm type"  wrapperCol={{
                      span: 15,
                     }}
                    > 
                  {getFieldDecorator('Commission_Type', {
                             initialValue: this.state.User.Commission_Type,
                      rules: [{
                      required: true,   message: 'Please select commission type',
                    }],
                  })(
                   
<Select  placeholder="Commission Type" id="Commission_Type" 
           width="100%"   onChange={this.onCommission_TypeChange} allowClear>
            <Option value="No Comm">No Comm</Option>
            <Option value="Bet by bet">Bet by bet</Option>
          </Select>
      
                  )}
                </FormItem>

 
      
      
      </Col>
      
                           </Row>
                         
             {
              this.state.ShowCommissions?
<>
              <Row>
   <Col lg={12} md={12} sm={12} xs={24} >
               
                 
   <Form.Item  
     name="MyMatchComm"
     label="AGENT Match comm(%)"
     wrapperCol={{
     span: 15,
     }}
     >
        <InputNumber value={this.state.Upliners.Match_Commission} disabled  min={0} max={100}   placeholder="My Match commission" id="MyMatchComm" step={0.1} style={{"width": "100%"}} />
     
                     
        
     
     </Form.Item> 
     


</Col>
   
<Col lg={12} md={12} sm={12} xs={24} >

<FormItem   name="Match_Commission"
label="Match comm(%)"  wrapperCol={{
 span: 15,
}}
> 
{getFieldDecorator('Match_Commission', {
                             initialValue: this.state.User.Match_Commission,
 rules: [{
 required: true,   message: 'Please enter odds commission',
}],
})(



<InputNumber    min={0} max={100}   placeholder="Match commission" id="Match_Commission" step={0.1} style={{"width": "100%"}} />



)}
</FormItem>





</Col>


              </Row>
              <Row>
                                 <Col lg={12} md={12} sm={12} xs={24} >
               



                                 <FormItem   name="MySessComm"
                         label="AGENT Sess comm(%)"  wrapperCol={{
                           span: 15,
                          }}
                         > 
                   
     
     <InputNumber disabled value={this.state.Upliners.Session_Commission}  min={0} max={100}   placeholder="My Session commission" id="MySessComm" step={0.1} style={{"width": "100%"}} />
     
           
                     
                     </FormItem>




 
                  
                 
              </Col>
                        
              <Col lg={12} md={12} sm={12} xs={24} >
               

              <FormItem   name="Session_Commission"
                    label="Sess comm(%)"  wrapperCol={{
                      span: 15,
                     }}
                    > 
                  {getFieldDecorator('Session_Commission', {
                             initialValue: this.state.User.Session_Commission,
                      rules: [{
                      required: true,   message: 'Please enter session commission',
                    }],
                  })(
                   
                    <InputNumber    min={0} max={100}   placeholder="Session commission" id="Session_Commission" step={0.1} style={{"width": "100%"}} />


      
                  )}
                </FormItem>



 
      
      
      </Col>
      
                           </Row>
                  



</>
              :

              <div></div>
            }
                           
                    
                    
                       
                     
      
                
                  
         <Row>
                                 <Col lg={12} md={12} sm={12} xs={24} >
               

                                 <FormItem   name="MyCasinoComm"
                         label="AGENT Casino comm(%)"  wrapperCol={{
                           span: 15,
                          }}
                         > 
                         
                         
     <InputNumber  disabled value={this.state.Upliners.Casino_Commission} min={0} max={100}   placeholder="My Casino commission" id="MyCasinoComm" step={0.1} style={{"width": "100%"}} />
     
     
            
                     </FormItem>


 


 
                  
                 
              </Col>
                        
              <Col lg={12} md={12} sm={12} xs={24} >
               


              <FormItem   name="Casino_Commission"
                    label="Casino comm(%)"  wrapperCol={{
                      span: 15,
                     }}
                    > 
                  {getFieldDecorator('Casino_Commission', {
                             initialValue: this.state.User.Casino_Commission,
                      rules: [{
                      required: true,   message: 'Please enter valid Casino commission',
                    }],
                  })(
                   
                    <InputNumber     min={0} max={100}   placeholder="Casino commission" id="Casino_Commission" step={0.1} style={{"width": "100%"}} />


      
                  )}
                </FormItem>
             
      
      
      </Col>
      
                           </Row>
                  
                   
                     
      
       
                  
         <Row>
                                 <Col lg={12} md={12} sm={12} xs={24} >
               

                                 <FormItem   name="MyMatkaComm"
                         label="AGENT Matka comm(%)"  wrapperCol={{
                           span: 15,
                          }}
                         > 
                          <InputNumber  disabled  value={this.state.Upliners.Matka_Commission} min={0} max={100}   placeholder="My Matka commission" id="MyMatkaComm" step={0.1} style={{"width": "100%"}} />
       
      
                     </FormItem>

                                   
                  
                 
              </Col>
                        
              <Col lg={12} md={12} sm={12} xs={24} >
               

              <FormItem   name="Matka_Commission"
                    label="Matka comm(%)"  wrapperCol={{
                      span: 15,
                     }}
                    > 
                  {getFieldDecorator('Matka_Commission', {
                             initialValue: this.state.User.Matka_Commission,
                      rules: [{
                      required: true,   message: 'Please enter valid Matka commission',
                    }],
                  })(
                   
                   
<InputNumber     min={0} max={100}   placeholder="Matka commission" id="Matka_Commission" step={0.1} style={{"width": "100%"}} />
  

      
                  )}
                </FormItem>

 
      
      
      </Col>
      
                           </Row>
                  
                   
                
                
                      <Row> 
                        <div className="ant-col ant-col-24 gx-text-right">
                     
               
                        <Button type="primary" className="ant-btn ant-btn-primary" htmlType="submit">Submit</Button> </div>
                      </Row>
                    
                  </Form>
      
      
               </div>
            </div>
         </div>
      </div>
      
      :null}
      
      
       
          
      <NotificationContainer/>
      
           
      
                      </>
    );
  }
}

const WrappedUpdateClient = Form.create()(UpdateClient);


const mapStateToProps = () => {
  const authUser =  JSON.parse(localStorage.getItem("authUser")); 
 
  return {authUser}
};
export default connect(mapStateToProps, {updateUser})(WrappedUpdateClient);
