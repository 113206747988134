import React,{useState} from 'react'; 
import { useParams} from "react-router-dom";  
import { Form, Row, Col,Select   } from 'antd';  
import {NotificationContainer} from "react-notifications";
import DeadUser from "../../components/tables/DeadUser"  
import GoBack from "../../components/buttons/GoBack" 
const FormItem = Form.Item;
const Option = Select.Option;
const Deads  = () => { 
   const authUser=JSON.parse(localStorage.getItem("authUser"));  
     
   const {U_Type } = useParams();  
  const [User_Type, setUser_Type] = useState(
   ()=>{


      
 if(U_Type.toLowerCase()==="admin"){
     return   "Admin"

   }
   else if(U_Type.toLowerCase()==="mini"){
         return   "Mini Admin" 
   }
   else if(U_Type.toLowerCase()==="master"){
         return   "Master"
   }
   else if(U_Type.toLowerCase()==="super"){
         return    "Super"
   }
   else if(U_Type.toLowerCase()==="agent"){
        return   "Agent"
   }
   else if(U_Type.toLowerCase()==="client"){
          return   "Client"
   }
    
  
   
   
   }
   
   );

 
  // Step 2: Attach the onChange event handler
  const handleTypeChange = (value) => {
   if(value=="admin"){
      setUser_Type("Admin");
 
      }
      else if(value=="mini"){
         setUser_Type("Mini Admin");
      }
      else if(value=="master"){
         setUser_Type("Master");
      }
      else if(value=="super"){
         setUser_Type("Super");
      }
      else if(value=="agent"){
         setUser_Type("Agent");
      }
      else if(value=="client"){
         setUser_Type("Client");
      }
  };

   
       
   
 
    
    
    return (
      <>
          
          
      
          <div  className="ant-card ant-card-bordered">
              <div className="ant-card-head">
                 <div className="ant-card-head-wrapper">
                    <div className="ant-card-head-title">
                       <h1>Dead {User_Type=='Super'?User_Type+" Agent":User_Type} </h1>
                    </div>
                    <div className="ant-card-extra"><span><GoBack/></span></div>
                 </div>
              </div>
              <div className="ant-card-body">
 
                
                    <Row style={{"margin": "8px"}}>
                   <Col  xl={8} md={8} sm={12} xs={24}  style={{"text-align": "center"}}>
                    
        <div style={{"margin-top": "10px", "margin-bottom": "10px"}}>

            
                           <Select
      style={{ width: '100%' }}
      value={User_Type}
      placeholder="Select Type"
      id="Select_Client"
      allowClear
      onChange={handleTypeChange} // Attach the onChange event handler
    >
      <Option value="0">Select Type</Option>
      {authUser.U_Type === 'Admin' ? (
        <Option value="mini">Mini Admin</Option>
      ) : null}
      {authUser.U_Type === 'Admin' || authUser.U_Type === 'Mini Admin' ? (
        <Option value="master">Master</Option>
      ) : null}
      {authUser.U_Type === 'Admin' ||
      authUser.U_Type === 'Mini Admin' ||
      authUser.U_Type === 'Master' ? (
        <Option value="super">Super</Option>
      ) : null}
      {authUser.U_Type === 'Admin' ||
      authUser.U_Type === 'Mini Admin' ||
      authUser.U_Type === 'Master' ||
      authUser.U_Type === 'Super' ? (
        <Option value="agent">Agent</Option>
      ) : null}
      {authUser.U_Type === 'Admin' ||
      authUser.U_Type === 'Mini Admin' ||
      authUser.U_Type === 'Master' ||
      authUser.U_Type === 'Super' ||
      authUser.U_Type === 'Agent' ? (
        <Option value="client">Client</Option>
      ) : null}
    </Select>
        </div>
             
                      
        
                    

                 
    
 </Col>
 
</Row>
                              
   <DeadUser U_ID={authUser.ID}  User_Type={User_Type}    />
               
                  
                     
                      
                        
                     
                     
           
           
                    
              </div>
           </div>
      
       
          
      <NotificationContainer/>
      
                      </>
    );
  
}
 
 
 
export default Deads;