import React from "react";
import {Route, Switch} from "react-router-dom";

import dashboard from "./main/dashboard/index";
import client from "./client/index";
import Casino from "./Casino/index";
import Events from "./events"; 
import EventPNL from "./event-pnl/index"; 
import Markets from "./markets/index"; 
import AccountStatement from "./account-statement/index"; 
import AccountOperations from "./account-operations/index";   
import ProfitAndLoss from "./profitandloss/index";     
import CasinoProfitAndLoss from "./casinoprofitandloss/index"; 
import MessageToUpline from "./message-to-upline/index"; 
import MessageToPlayer from "./message-to-player/index"; 

const App = ({match}) => (
  <div className="gx-main-content-wrapper"  style={{ "margin-bottom": "40px"}}>
    <Switch> 
    <Route path={`${match.url}dashboard`} component={dashboard}/> 
    <Route path={`${match.url}client`} component={client}/>
      <Route path={`${match.url}Casino`} component={Casino}/>
      <Route path={`${match.url}Events`} component={Events}/>
      <Route path={`${match.url}event-pnl/:eid`} component={EventPNL}/>
      <Route path={`${match.url}markets`} component={Markets}/>
      <Route path={`${match.url}account-statement`} component={AccountStatement}/>
      <Route path={`${match.url}account-operations`} component={AccountOperations}/>
      <Route path={`${match.url}profitandloss`} component={ProfitAndLoss}/>
      <Route path={`${match.url}casinoprofitandloss`} component={CasinoProfitAndLoss}/>
      <Route path={`${match.url}message-to-upline`} component={MessageToUpline}/>
      <Route path={`${match.url}message-to-player`} component={MessageToPlayer}/>
 
    </Switch>
  </div>
);

export default App;
