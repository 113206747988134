import React, { useState, useEffect } from 'react';
import {   Table,   Spin } from 'antd';
import { getAgentsCommissionLenden  } from "../../../../../appRedux/actions/rex";
import moment from 'moment';
 
 
const SingleClient = (props) => {
  const [loading, setLoading] = useState(false);
   const [Lenden, setLenden] = useState([]);
   const [formattedDate, setFormattedDate] = useState('');

  const columns = [
   
    {
      title: 'Date',
      dataIndex: 'Date',
      key: 'Date',
       
    },
   
    {
      title: 'Title',
      dataIndex: 'Title',
      key: 'Title' 
      },
     
      {
        title: 'MILA HAI',
        dataIndex: 'MILA',
        key:  'MILA',
         children: [
          {
            title: 'M.Comm',
            dataIndex: 'M_Comm_Mila',
            key: 'M_Comm_Mila',
            render: (Status,row) =>  (
              <span style={{"color": "green"}}>{row.M_Comm_Mila}</span> 
             ),
                }, {
                  title: 'S.Comm',
                  dataIndex: 'S_Comm_Mila',
                  key: 'S_Comm_Mila',
                  render: (Status,row) =>  (
                    <span style={{"color": "green"}}>{row.S_Comm_Mila}</span> 
                   ),  },
                {
                title: 'MTK.Comm',
                dataIndex: 'MTK_Comm_Mila',
                key: 'MTK_Comm_Mila',
                render: (Status,row) =>  (
                  <span style={{"color": "green"}}>{row.MTK_Comm_Mila}</span> 
                 ), },
                 {
                    title: 'T.Comm',
                    dataIndex: 'T_Comm_Mila',
                    key: 'T_Comm_Mila',
                    render: (Status,row) =>  (
                      <span style={{"color": "green"}}>{row.T_Comm_Mila}</span> 
                     ),  },
                    
              ]
      },

      {
        title: 'DENA HAI',
        dataIndex: 'DENA',
        key:  'DENA',
        children: [
          {
            title: 'M.Comm',
            dataIndex: 'M_Comm_Dena',
            key: 'M_Comm_Dena', 
            render: (Status,row) =>  (
              <span style={{"color": "red"}}>{row.M_Comm_Dena}</span> 
             ),
              }, {
                  title: 'S.Comm',
                  dataIndex: 'S_Comm_Dena',
                  key: 'S_Comm_Dena',
                  render: (Status,row) =>  (
                    <span style={{"color": "red"}}>{row.S_Comm_Dena}</span> 
                   ),  },
                {
                title: 'MTK.Comm',
                dataIndex: 'MTK_Comm_Dena',
                key: 'MTK_Comm_Dena',
                render: (Status,row) =>  (
                  <span style={{"color": "red"}}>{row.MTK_Comm_Dena}</span> 
                 ), },
                 {
                    title: 'T.Comm',
                    dataIndex: 'T_Comm_Dena',
                    key: 'T_Comm_Dena',
                    render: (Status,row) =>  (
                      <span style={{"color": "red"}}>{row.T_Comm_Dena}</span> 
                     ), 
                     },
                    
              ]
      },


      ]

      
 
  const GetAgentsCommissionLenden = async () => {
    try {
        const result = await getAgentsCommissionLenden(props.client);
      try{  if (result ){
          setLenden(result);
        }}catch{}
        
        finally{ setLoading(false);}
       
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      
  };
  }
  useEffect(() => {
    setLoading(true)
    const todayDate = moment().format('DD-MM-YYYY');
    setFormattedDate(todayDate);
    GetAgentsCommissionLenden();
  }, [props.client]);


  const filteredData = Lenden && Lenden.filter(item => {
    if (props.timeRange.length === 0) {
     return true; // Show all data if no time range is selected
   } else {
     const eventTime = moment(item.Time, 'DD-MM-YYYY HH:mm:ss');
     const startTime = moment(props.timeRange[0]).startOf('day');
     const endTime = moment(props.timeRange[1]).endOf('day');
 
     return eventTime.isBetween(startTime, endTime, null, '[]');
   }
 });



  let totalM_Comm_Mila = 0.00;
  let totalS_Comm_Mila = 0.00;
  let totalMTK_Comm_Mila = 0.00;
  let totalT_Comm_Mila = 0.00;

  let totalM_Comm_Dena = 0.00;
  let totalS_Comm_Dena = 0.00;
  let totalMTK_Comm_Dena = 0.00;
  let totalT_Comm_Dena = 0.00;
   
 
  if (filteredData) {
    filteredData.forEach((item) => {
      totalM_Comm_Mila += parseFloat(item.M_Comm_Mila) || 0.00;
      totalS_Comm_Mila += parseFloat(item.S_Comm_Mila) || 0.00;
      totalMTK_Comm_Mila += parseFloat(item.MTK_Comm_Mila) || 0.00;
      totalT_Comm_Mila += parseFloat(item.T_Comm_Mila) || 0.00;
      totalM_Comm_Dena += parseFloat(item.M_Comm_Dena) || 0.00;
      totalS_Comm_Dena += parseFloat(item.S_Comm_Dena) || 0.00;
      totalMTK_Comm_Dena += parseFloat(item.MTK_Comm_Dena) || 0.00;
      totalT_Comm_Dena += parseFloat(item.T_Comm_Dena) || 0.00;
       
    });
  }
  totalM_Comm_Mila = totalM_Comm_Mila.toFixed(2);
  totalS_Comm_Mila = totalS_Comm_Mila.toFixed(2);
  totalMTK_Comm_Mila = totalMTK_Comm_Mila.toFixed(2);
  totalT_Comm_Mila = totalT_Comm_Mila.toFixed(2);
  totalM_Comm_Dena = totalM_Comm_Dena.toFixed(2);
  totalS_Comm_Dena = totalS_Comm_Dena.toFixed(2);
  totalMTK_Comm_Dena = totalMTK_Comm_Dena.toFixed(2);
  totalT_Comm_Dena = totalT_Comm_Dena.toFixed(2); 
 
 
 





  const totalRow = {
    key: 'total',
    Code: 'Total',
    Date: formattedDate,
    Title: 'Total',
    M_Comm_Mila: totalM_Comm_Mila,
    S_Comm_Mila: totalS_Comm_Mila,
    MTK_Comm_Mila: totalMTK_Comm_Mila,
    T_Comm_Mila: totalT_Comm_Mila,

    M_Comm_Dena: totalM_Comm_Dena,
    S_Comm_Dena: totalS_Comm_Dena,
    MTK_Comm_Dena: totalMTK_Comm_Dena,
    T_Comm_Dena: totalT_Comm_Dena, 
  };

 

return (
 <>  
      <Spin spinning={loading} tip="Loading...">
        <Table width={100} size='small' bordered scroll={{ x: true }} columns={columns} dataSource={filteredData == null && filteredData && filteredData.length ?[totalRow] :  [...filteredData, totalRow]}  />
      </Spin>
     

 </>
);
};

export default SingleClient;