import React from "react";  
import GoBack from "../components/buttons/GoBack"
import { Row,Col  } from 'antd';  
const MessageToUpline = () => {
    
 
   
    return (<> 
<div className="ant-card ant-card-bordered">
   <div className="ant-card-head">
      <div className="ant-card-head-wrapper">
         <div className="ant-card-head-title">
            <span>
               <h1 style={{"display": "inline-block"}}>Set Message for Dealer </h1>
            </span>
         </div>
         <div className="ant-card-extra"><span><GoBack/></span></div>
      </div>
   </div>
   <div className="ant-card-body">
   <form className="ant-form ant-form-horizontal">
      <div className="ant-row ant-form-item">
         <div className="ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-8">
            <label for="dealer_msg" className="ant-form-item-required ant-form-item-no-colon" title="Dealer Message">Dealer Message</label>
            </div>
            <div className="ant-col ant-form-item-control-wrapper ant-col-xs-24 ant-col-sm-15 ant-col-md-15 ant-col-lg-15">
               <div className="ant-form-item-control">
                  <span className="ant-form-item-children">
                     <textarea rows="4" placeholder="Dealer Message" id="dealer_msg" data-__meta="[object Object]" data-__field="[object Object]" className="ant-input" spellcheck="false"></textarea>
                     </span>
                     </div>
                     </div>
                     </div>
                     <div className="ant-row ant-form-item">
                        <div className="ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-8">
                           <label className="ant-form-item-no-colon" title="&nbsp;">&nbsp;</label>
                           </div>
                           <div className="ant-col ant-form-item-control-wrapper ant-col-xs-24 ant-col-sm-15 ant-col-md-15 ant-col-lg-15">
                              <div className="ant-form-item-control">
                                 <span className="ant-form-item-children">
                                    <button type="submit" className="ant-btn ant-btn-primary">
                                       <span>Submit</span>
                                       </button></span>
                                       </div>
                                       </div>
                                       </div>
                                       </form>
       
       
      <div className="ant-table-wrapper">
         <div className="ant-spin-nested-loading">
            <div className="ant-spin-container">
         
               </div>
         </div>
       
      </div>
   </div>
</div> 
            </>
               );
 

 
}


 
 
 
export default MessageToUpline;
