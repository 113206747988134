
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css'

const Notification=(msg,type)=>{

         if (type=="Info"){
      
        NotificationManager.info(type, msg, 3000);
         
        }
        else if (type=="Success"){
      
          NotificationManager.success(type, msg, 3000);
           
          }
          else if (type=="Warning"){
      
            NotificationManager.warning(type, msg, 3000);
             
            }
            else if (type=="Error"){
      
              NotificationManager.error(type, msg, 3000);
               
              }
      
}
export default Notification;