import React, { useEffect, useState } from "react";
import { Row, Table,Menu, Empty, Dropdown, Spin } from 'antd';
import { getCashTransactions } from "../../../../appRedux/actions/rex";

const CashTransactions = (props) => {
  const [Ledger, setLedger] = useState([]);
  const [lenaTotal, setLenaTotal] = useState(0.00);
  const [denaTotal, setDenaTotal] = useState(0.00);
  const [loading, setLoading] = useState(true);
  const renderOptionMenu = (row) => {
    return (
      <Menu>
     <Menu.Item key="1"><span className="dropdown-link">
       Delete
        </span> </Menu.Item>
        
      </Menu>
    );
  }; 


  const columns = [
    
    {
      title: '#', 
      key: '#',
      render: (Status,row) =>  (
        <Dropdown overlay={renderOptionMenu(row)} trigger={['click']}
        >
<button type="button" className="ant-btn ant-dropdown-trigger ant-btn-primary ant-btn-sm" style={{"margin-bottom": "0px"}} ant-click-animating-without-extra-node="false">
  <i className="icon icon-dropdown"></i></button>

        
        </Dropdown>
      ),
     },
    {
      title: 'Date',
      dataIndex: 'Date',
      key: 'Date',
    },
    {
      title: 'Post Date',
      dataIndex: 'Post_Date',
      key: 'Post_Date',
    },
    {
      title: 'Collection Name',
      dataIndex: 'Collection_Name',
      key: 'Collection_Name',
    },
    {
      title: 'Debit',
      dataIndex: 'Debit',
      key: 'Debit',
      align: 'right',
    },
    {
      title: 'Credit',
      dataIndex: 'Credit',
      key: 'Credit',
      align: 'right',
    },
    {
      title: 'Balance',
      dataIndex: 'Balance',
      key: 'Balance',
      align: 'right',
    },
    {
      title: 'Payment Type',
      dataIndex: 'Payment_Type',
      key: 'Payment_Type',
    },
    {
      title: 'Remark',
      dataIndex: 'Remark',
      key: 'Remark',
    },
    {
      title: 'Done By',
      dataIndex: 'Done_By',
      key: 'Done_By',
    }
  ];

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const result = await getCashTransactions(props.U_ID,false);
        
        if (result && Array.isArray(result)) {
          setLedger(result);
  
          // Calculate Lena (total of Credit column)
          const lenaTotal = result.reduce((total, item) => total + parseFloat(item.Credit ||0.00), 0.00);
          setLenaTotal(lenaTotal);
  
          // Calculate Dena (total of Debit column)
          const denaTotal = result.reduce((total, item) => total + parseFloat(item.Debit || 0.00),0.00);
          setDenaTotal(denaTotal);
        } else {
          // Handle the case where result is not an array
          console.error("Invalid result format:", result);
        }
      } catch (error) {
        // Handle errors from the asynchronous operation
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [props]);
  const balance = lenaTotal - denaTotal;
  const isNegativeBalance = balance < 0.00;

  return (
    <>
      <Row className="gx-center">
        <div class="ant-col ant-col-xs-12 ant-col-sm-7 ant-col-md-7 ant-col-xl-7">
          <h3 style={{ "padding": "20px", "color": "rgb(214, 75, 75)" }}>Dena : {Math.abs(denaTotal).toFixed(2)}</h3>
       </div>
        <div class="ant-col ant-col-xs-12 ant-col-sm-7 ant-col-md-7 ant-col-xl-7">
           <h3 style={{ "padding": "20px", "color": "rgb(51, 181, 28)" }}>Lena : {Math.abs(lenaTotal).toFixed(2)}</h3>
        </div>
        <div class="ant-col ant-col-xs-24 ant-col-sm-7 ant-col-md-7 ant-col-xl-7">
          <h3 style={{ "padding": "20px" }}>
           
              <span style={{ "padding": "20px", "color": isNegativeBalance ? "rgb(214, 75, 75)" : "rgb(51, 181, 28)" }}>
                Balance: {isNegativeBalance ? `${Math.abs(balance).toFixed(2)} (Dena)` : `${Math.abs(balance).toFixed(2)} (Lena)`}
              </span>
            
          </h3>
        </div>
        <div class="ant-col ant-col-xs-24 ant-col-sm-3 ant-col-md-3 ant-col-xl-3" style={{"padding": "20px"}}>
        <a href="/client/deletedlenden/2895"><button type="button" class="ant-btn ant-btn-primary"><span>Deleted</span></button></a>
        </div>
      
      </Row>
      {loading ? (
        <div style={{ textAlign: 'center', margin: '20px' }}>
          <Spin size="large" />
        </div>
      ) : (
        <Table width={100} size='small' bordered scroll={{ x: 'max-content' }} columns={columns} dataSource={Ledger ? Ledger == null ? null : Ledger : null} />
      )}
    </>
  );
};

export default CashTransactions;
