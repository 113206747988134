import React,{useEffect,useState } from "react";    
import {  Table , Empty } from 'antd';
import { useParams  } from "react-router-dom";  
 import {getRangeProfitLoss } from "../../../appRedux/actions/rex";
 import { Typography } from 'antd';
 
 const { Text } = Typography;
 const customEmptyText = (
  <div>
  <Empty  description={<Text disabled><h2>No Bets available on this session</h2></Text>} />
</div>
);

const RangeProfitLoss = (props) => { 
  const [ Range,setRange ] = useState([])  
 
   const { eid } = useParams()  

   

    const GetRangeProfitLoss =async()=>{

  await getRangeProfitLoss(eid,props.Bet).then(async(result)=>{  
       setRange(result);


      });


    }
   useEffect(() => {
    const fetchData = async () => {
   
   await   GetRangeProfitLoss();


    };

    fetchData();
},  [props.Bet]);
 
const columns = [
   
  {
    title: 'Runs',
    dataIndex: 'Range',
    key: 'Range',
    render:(Status,row)=>(
         <span style={{"font-weight": "bold", "font-size": "12px"}}>{row.Range}</span>
       
       
            ),
           },
  
  {
    title: 'P|L',
    dataIndex: 'ProfitLoss',
    key: 'ProfitLoss',
    render:(Status,row)=>(
      (row.ProfitLoss<0?
        <span style={{"font-weight": "bold", "font-size": "12px", "color": "red"}}>{row.ProfitLoss}</span>
      :  <span style={{"font-weight": "bold", "font-size": "12px", "color": "rgb(59, 145, 234) "}}>{row.ProfitLoss}</span>
      )
       
            ),
  }
]; 
  return ( 
    <>
  <div className="ant-card ant-card-bordered">
  
  <div className="ant-card-body" style={{ padding: 0 }}>
    <div style={{"max-height": "500px", "overflow-y": "scroll" }}>
   <Table width={100} size='small' bordered pagination={false}  scroll={{x: 'max-content'}}  columns={columns} dataSource={Range?Range==null?null:Range:null} />
   </div>
   </div>
    </div>            </>







  );
};

export default RangeProfitLoss;
