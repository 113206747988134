import React, { useEffect, useState } from "react";
import { Row, Col, Select, Table, Empty, Spin } from 'antd';
import { getCompletedMatchBetsPL } from "../../../appRedux/actions/rex";
import { Typography } from 'antd';

const { Text } = Typography;
const Option = Select.Option;
const customEmptyText = (
  <div>
    <Empty description={<Text disabled><h2>No Bets available on this match</h2></Text>} />
  </div>
);

const ProfitLoss = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [completedMatchBets, setCompletedMatchBets] = useState([]);
  const [selectedUser, setSelectedUser] = useState("All Users");
  const [totalPL, setTotalPL] = useState(0);
  const [uniqueUsernames, setUniqueUsernames] = useState([]);
  const [allUsersCount, setAllUsersCount] = useState(0);

  const columns = [
    {
      title: 'Username',
      dataIndex: 'Username',
      key: 'Username',
      render: (text, record) => (
        <span>{record.Name} ({record.Username})</span>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'Date',
      key: 'Date',
    },
    {
      title: 'F. Name',
      dataIndex: 'F_Name',
      key: 'F_Name',
    },
    {
      title: 'Result',
      dataIndex: 'Result',
      key: 'Result',
    },
    {
      title: 'Back/Lay',
      dataIndex: 'Type',
      key: 'Type',
    },
    {
      title: 'Value',
      dataIndex: 'Value',
      key: 'Value',
      render: (text) => parseFloat(text).toFixed(2),
    },
    {
      title: 'Volume',
      dataIndex: 'Volume',
      key: 'Volume',
      render: (text) => parseFloat(text).toFixed(2),
    },
    {
      title: 'Stake',
      dataIndex: 'Stake',
      key: 'Stake',
      render: (text) => parseFloat(text).toFixed(2),
    },
    {
      title: 'P&L',
      dataIndex: 'PL',
      key: 'PL',
      render: (text) => parseFloat(text).toFixed(2),
    },
  ];

  const GetCompletedMatchBetPL = async () => {
    try {
      setIsLoading(true);
      const result = await getCompletedMatchBetsPL(props.EID);

      const usernames = Array.from(new Set(result.map(bet => bet.Username)));
      setUniqueUsernames(usernames);

      const allUsersCount = new Set(result.map(bet => bet.Username)).size;
      setAllUsersCount(allUsersCount);

      const filteredBets = selectedUser === "All Users"
        ? result
        : result.filter((bet) => bet.Username === selectedUser);

      const userTotalPL = filteredBets.reduce((acc, bet) => acc + parseFloat(bet.PL), 0);

      setCompletedMatchBets(filteredBets);
      setTotalPL(userTotalPL);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    GetCompletedMatchBetPL();
  }, [props.EID, selectedUser]);

  const handleUserChange = (value) => {
    setSelectedUser(value);
  };

  const getRowClassName = (record) => {
    return record.PL > 0 ? 'bg-green' : 'bg-red';
  };

  return (
    <>
      <Row style={{ margin: "8px" }}>
        <Col xl={5} md={5} sm={5} xs={24} style={{ textAlign: "center" }}>
          <div style={{ marginBottom: "20px" }}>
            <Select
              style={{ width: "100%" }}
              value={selectedUser}
              placeholder={`All Users (${allUsersCount})`}
              onChange={handleUserChange}
              allowClear
              showSearch
              optionFilterProp="children"
            >
              <Option value="All Users">All Users ({allUsersCount})</Option>
              {uniqueUsernames.map(username => (
                <Option key={username} value={username}>
                  {username}
                </Option>
              ))}
            </Select>
          </div>
        </Col>

        <Col xl={5} md={5} sm={5} xs={24} style={{ textAlign: "center" }}>
          <div style={{ marginBottom: "20px" }}>
            <table>
              <tbody>
                <tr>
                  <td style={{ fontSize: "20px" }}> <strong>Total P/L: </strong></td>
                  <td style={{ fontSize: "20px", color: totalPL < 0 ? "red" : "rgb(82, 196, 26)" }}>
                    <strong>{totalPL.toFixed(2)}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Col>
      </Row>

      <Spin spinning={isLoading}>
        {completedMatchBets && completedMatchBets.length > 0 ? (
          <Table
            width={100}
            size="small"
            bordered
            pagination={false}
            scroll={{ x: 'max-content' }}
            rowClassName={getRowClassName}
            locale={{ emptyText: customEmptyText }}
            columns={columns}
            dataSource={completedMatchBets}
          />
        ) : (
          <Empty description={<Text disabled><h2>No Bets available on this match</h2></Text>} />
        )}
      </Spin>
    </>
  );
};

export default ProfitLoss;
