import React,{useEffect,useState } from "react";  
import {Row, Col } from 'antd'; 
import { useParams  } from "react-router-dom";   
import   CompletedBets   from "./completedbets";   

 
     
const EventPNL = () => {
   
 
  const { eid } = useParams()  
      
  return ( 
    <>
        
        <CompletedBets eid={eid}/>
       
      
     
                </>







  );
};

export default EventPNL;
