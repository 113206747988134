import {   Table } from 'antd';
import React  from 'react'; 
const ProfitAndLoss = (props) => {
  
const data = props.data; 
 
  const columns = [
    {
      title: 'Title',
      dataIndex: 'Title',
      key: 'Title',
     },
     {
      title: 'P&L',
      dataIndex: 'P&L',
      key: 'P&L',
     },
      {
        title: 'Comm+',
        dataIndex: 'Comm_P',
        key: 'Comm_P',
       } ,
       {
        title: 'Comm-',
        dataIndex: 'Comm_M',
        key: 'Comm_M',
       } ,
     {
      title: 'Net P&L',
      dataIndex: 'Net P&L',
      key: 'Net P&L',
     } ,
     {
      title: 'Action',
      dataIndex: 'Action',
      key: 'Action',
     } ,
      
      
  ];
  return <Table   width={100} size='small' bordered scroll={"left"}   columns={columns} dataSource={data} />;
};
export default ProfitAndLoss;