 
import React ,{useState,useEffect} from 'react'; 
import {   Table } from 'antd';
import {getTransactions } from "../../../appRedux/actions/rex";
const Transactions = (props) => {


  const [Transactions, setTransactions] = useState([]); 
  const _getTransactions=()=>{
     getTransactions(props.U_ID).then((result)=>{ 
      props.setTotalCount(result.length)
    setTransactions( result );  
   
});
  }
  

  useEffect(() => {
    const fetchData = async () => {
   
      _getTransactions();
    };

    fetchData();
},   []);
  const columns = [
    {
      title: 'Date',
      dataIndex: 'Date',
      key: 'Date',
     },
     {
      title: 'Description',
      dataIndex: 'Description',
      key: 'Description',
     },
     {
      title: 'Prev. Bal',
      dataIndex: 'Prev_Bal',
      key: 'Prev_Bal',
      render:(Status,row)=>(
        <span>{ parseFloat(row.Prev_Bal).toFixed(2)}</span>
        
              ),
     },
     {
      title: 'CR',
      dataIndex: 'Credit',
      key: 'Credit',
      render:(Status,row)=>(
<span style={{color:"green"}}>{ parseFloat(row.Credit  ).toFixed(2)}</span>

      ),
     },
     {
      title: 'DR',
      dataIndex: 'Debit',
      key: 'Debit',
      render:(Status,row)=>(
<span style={{color:"red"}}>{ parseFloat(row.Debit ).toFixed(2)}</span>

      ),

     } ,
     {
      title: 'Comm+',
      dataIndex: 'Commission',
      key: 'Commission', 
      render:(Status,row)=>(
<span style={{color:"green"}}>{ parseFloat( row.Commission ).toFixed(2)}</span>

      ),
     } ,
     {
      title: 'Comm-',
      dataIndex: 'Commission',
      key: 'Commission',
      render:(Status,row)=>(
<span style={{color:"red"}}>0.00</span>

      ),
     } ,
     {
      title: 'Balance',
      dataIndex: 'Balance',
      key: 'Balance',
      render:(Status,row)=>(
<span>{ parseFloat(row.Balance ).toFixed(2)}</span>

      ),
     } 
      
  ];
  return <Table   width={100} size='small' bordered scroll={"left"}   columns={columns}  dataSource={Transactions?Transactions.length>0?Transactions:null:null}  />;
};
export default Transactions;