import React, {Component} from "react";
import {Layout,Dropdown, Icon, Menu, Popover} from "antd";
import {Link} from "react-router-dom"; 
import  U_Menu from "./U_Menu";
import {switchLanguage, toggleCollapsedSideNav} from "../../appRedux/actions/Setting";
import {userSignOut} from "appRedux/actions/Auth";
import {NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE} from "../../constants/ThemeSetting";
import Auxiliary from "util/Auxiliary"; 

import {connect} from "react-redux";

const {Header} = Layout;

class Topbar extends Component {

  state = {
    searchText: '',
  };
  

    menu = (
   <U_Menu userSignOut={()=> this.props.userSignOut()}/>
      
  );
  render() { 
    const {locale, width, navCollapsed, navStyle} = this.props;
    const authUser = JSON.parse(localStorage.getItem("authUser"));
     return (
      <Auxiliary>
            <Header>
          {navStyle === NAV_STYLE_DRAWER || ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) ?
            <div className="gx-linebar">
              <i className="gx-icon-btn icon icon-menu gx-text-white"
                 onClick={() => {
                   this.props.toggleCollapsedSideNav(!navCollapsed);
                 }}
              />
            </div> : null}
          <Link to="/dashboard"  style={{"width": "20%"}} className="gx-d-block gx-d-lg-none gx-pointer">
            <img alt="proinplay" src={"/assets/images/logo.png"}/></Link>
     <ul className="gx-header-notifications gx-ml-auto">
       <li className="gx-user-nav">
       <Dropdown overlay={this.menu} trigger={['click']}>
        <span className="gx-pointer gx-link gx-text-white ant-dropdown-link ant-dropdown-trigger">
        {authUser ? authUser.Name : "Loading"} <Icon type="down"/>
        </span>
      </Dropdown>
          
       </li>
    </ul>
      
        </Header>
       
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({settings }) => {
   
  const {locale, navStyle, navCollapsed, width} = settings;
  return {locale, navStyle, navCollapsed, width}
};

export default connect(mapStateToProps,  {toggleCollapsedSideNav, switchLanguage,userSignOut})(Topbar);
