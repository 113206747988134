import React, { useState, useEffect } from 'react';
import firebase from '../../../../util/firebase';  

function ScoreBoard(props) {
  const [data, setData] = useState([]);












  
  useEffect(() => {
  
  }, []); 
  return (
     <></>
  );
}

export default ScoreBoard;
