import React,{useEffect,useState } from "react";   
import { useParams  } from "react-router-dom";  
 import {getEventDetails } from "../../../appRedux/actions/rex";
     
const Title = () => {
   
  const [ Event,setEvent ] = useState({})  
 
   const { eid } = useParams()  
    
   useEffect(() => {
    const fetchData = async () => {
   
      await getEventDetails(eid).then((result)=>{  
        setEvent(result)

      });



    };

    fetchData();
}, []);
 
  return ( 
    <>
  
  <div
        className="ant-col ant-col-xs-16 ant-col-sm-16 ant-col-md-16 ant-col-xl-16"
        style={{ display: "inline-grid" }}
      >
        <span>{Event?Event.Event_Name:null}</span>
        <span>{Event?Event.Time:null}</span>
      </div>
                </>







  );
};

export default Title;
