import React  from "react"; 
import { useHistory } from 'react-router-dom';

const GoBack=()=>{
    const history = useHistory();

    const handleGoBack = () => {
      history.goBack(); // This will navigate back to the previous page/view
    };

    return (
      <button type="button" onClick={handleGoBack} className="ant-btn ant-btn-primary"><span>Back</span></button>
      );
}
export default GoBack;