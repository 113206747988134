import React from 'react'; 
import { useParams} from "react-router-dom";    
import DeletedTransaction from "../../components/tables/DeletedTransaction"    
import GoBack from "../../components/buttons/GoBack"
const DeletedLenden  = () => { 
   const authUser=JSON.parse(localStorage.getItem("authUser"));  
     
   const {U_Type } = useParams(); 



   
    
   
 
    
    
    return (
      <>
          
          
      
          <div  className="ant-card ant-card-bordered">
              <div className="ant-card-head">
                 <div className="ant-card-head-wrapper">
                    <div className="ant-card-head-title">
                       <h1>Deleted Lenden</h1>
                    </div>
                    <div className="ant-card-extra"><span><GoBack/></span></div>
                 </div>
              </div>
              <div className="ant-card-body">
 
                
                      
<DeletedTransaction/>
               
                  
                     
                      
                        
                     
                     
           
           
                    
              </div>
           </div>
      
        
                      </>
    );
  
}
 
 
 
export default DeletedLenden;