import React,{useEffect,useState } from "react";    
import {  Table , Empty , Spin} from 'antd';
import { useParams  } from "react-router-dom"; 
 import {getLiveMatchBets } from "../../../appRedux/actions/rex";
 import { Typography } from 'antd';
 
 const { Text } = Typography;
 const customEmptyText = (
  <div>
  <Empty  description={<Text disabled><h2>No Bets available on this match</h2></Text>} />
</div>
);

const MatchBets = (props) => { 
  const [isLoading, setIsLoading] = useState(false);
  const [ MatchBets,setMatchBets ] = useState(false)  
   const { eid } = useParams()  

   

    const GetMatchBets =async()=>{

  await getLiveMatchBets(eid).then(async(result)=>{  
        
    setMatchBets(result);
 
    setIsLoading(false);
      });


    }  
    useEffect(() => {
      setIsLoading(true);

      GetMatchBets(); // This will run only once when the component mounts
    }, [props.Bet]);
   
 
const columns = [
   
  {
    title: 'Rate',
    dataIndex: 'Rate',
    key: 'Rate',
       },
     {
    title: 'Amount',
    dataIndex: 'Amount',
    key: 'Amount',
       },
  {
    title: 'Type',
    dataIndex: 'Type',
    key: 'Type',
     },
  {
    title: 'Team',
    dataIndex: 'Team',
    key: 'Team',
  },
  {
    title: 'Client',
    dataIndex: 'Client',
    key: 'Client',
  }
  ,
  {
    title: 'Agent',
    dataIndex: 'Agent',
    key: 'Agent',
  }
  ,
  {
    title: 'Date',
    dataIndex: 'Date',
    key: 'Date',
  }
  ,
  {
    title: 'Loss',
    dataIndex: 'Loss',
    key: 'Loss',
  }
  ,
  {
    title: 'Profit',
    dataIndex: 'Profit',
    key: 'Profit',
  }
];
const getRowClassName = (record) => { 
  if (record.Type == "LAGAI") {
   return 'backbet';  
 } else if (record.Type == "KHAI") {
      return 'laybet';  
  }
  return '';  
};
  return ( 
    <>
  {MatchBets ?
  <div className="ant-card ant-card-bordered"  style={{ padding: "0px", "max-height": "500px", "overflow-y": "scroll"}}>
  <div className="ant-card-head">
    <div className="ant-card-head-wrapper">
      <div className="ant-card-head-title">Match Bets - [ {MatchBets?MatchBets.length:0} ]</div>
       
    </div>
  </div>
  <div className="ant-card-body" style={{ padding: 0 }}>
  <Spin spinning={isLoading}>
   <Table width={100} size='small' bordered    pagination={false} scroll={{x: 'max-content'}} rowClassName={getRowClassName} locale={{ emptyText:customEmptyText , }} columns={columns} dataSource={MatchBets?MatchBets==null?null:MatchBets:null} />
 </Spin> 
  </div>
</div>
        :null  }      </>







  );
};

export default MatchBets;
