 
const { calculatePercentage,calculateTotalPL, getCompanyCommissionOnSessionBet, getCompanyCommissionOnOddsByBet, getMyCommissionOnOddsBet, getBetProfitLoss, getCommissionRateOnBet, getCommission_ID, calculateWinLoss } = require('./rex-help');
 
async function getUserBets(Bets, Users) {
    if (Bets.length > 0) {

        for (const User of Users) {
            let _Odds = []
            let _Sessions = []

            for (const Bet of Bets) {
                if (Bet.Event_Id == User.Event_Id) {
                    if (Bet.CID == User.CID) {

                        if (Bet.Game_Type === "Session") {
                            _Sessions.push(Bet)

                        } else
                            if (Bet.Game_Type === "Odds") {
                                _Odds.push(Bet)

                            }
                    }

                }

                User.Sessions = _Sessions;
                User.Odds = _Odds;

            }
        }
    }

}

async function getManageBets(Bets, Users) {
    const seenEventIds = new Set();


    if (Bets.length > 0) {

        for (const Bet of Bets) {
            Bet.Commission_ID = getCommission_ID(Bet)
            Bet.WinLoss = calculateWinLoss(Bet)
            Bet.ProfitLoss = getBetProfitLoss(Bet)
            Bet.CommissionRate = getCommissionRateOnBet(Bet)
            if (Bet.Game_Type === "Session") {

                Bet.BetCompanyCommission = getCompanyCommissionOnSessionBet(Bet)
                Bet.MyBetCommission = (Bet.Commission_ID == Bet.U_ID ? Bet.BetCompanyCommission : 0.00)
                Bet.MY_SessionPL = calculatePercentage(Bet.ProfitLoss - Bet.BetCompanyCommission, Bet.MyShare) + Bet.MyBetCommission;

            } else { Bet.BetCompanyCommission = 0.00 }

            Bet.ProfitLoss = getBetProfitLoss(Bet)
            if (!seenEventIds.has(Bet.CID+";"+Bet.Event_Id)) {
                seenEventIds.add(Bet.CID+";"+Bet.Event_Id);

                Users.push({ CID: Bet.CID, Event_Id: Bet.Event_Id, Sessions: [], Odds: [] })
            }

        }


    }


}
async function getManageEventBest(Events, Users) {

    if (Events.length > 0) {

        for (const Event of Events) {
            let _bets = []
            
                if (Event.Status =="Completed") {
                    for (const User of Users) {
                    if (User.Event_Id == Event.Event_Id) 
                    {
                        _bets.push(User)

                    }
                 
            }

            }
            Event.Bets = _bets;

        }
    }

}
 

async function CalculateEventPL(Events, Users) {

    for (const Event of Events) {
        let U_Type ;
        let Parent_Id ;
        let MyTotalSessionPL = 0.00;
        let MyTotalSessionCommission = 0.00;
        let MyTotalMatchPL = 0.00;
        let MyTotalMatchCommission = 0.00;
       let MyTotalShare = 0.00;

        let CompanyTotalSessionPL = 0.00; 
        let CompanyTotalSessionCommission = 0.00;
       
        let CompanyTotalMatchPL = 0.00;
        let CompanyTotalMatchCommission = 0.00;
        let CompanyTotalShare = 0.00;

        let CompanyTotalType="None";
 

        let CompanyTotal=0.00;
 
        let CompanyBalance=0.00;
 
 

//   if (Event.Event_Id=="33112475") {

        if (Event.Bets.length > 0) {
            for (const User of Event.Bets) {
                User.MatchPL=0.00;
                User.SessionPL=0.00;
                if (User.Event_Id == Event.Event_Id) {
                    let UserSessionPL = 0.00;
                    if (User.Sessions.length > 0) {
                        for (const Session of User.Sessions) {
                            U_Type=Session.U_Type;
                            Parent_Id=Session.Parent;
                            UserSessionPL = UserSessionPL + Session.MY_SessionPL;
                            CompanyTotalSessionPL=CompanyTotalSessionPL+  Session.ProfitLoss;
                            MyTotalSessionCommission=MyTotalSessionCommission+Session.MyBetCommission;
                            CompanyTotalSessionCommission= CompanyTotalSessionCommission+Session.BetCompanyCommission;
                            
                            MyTotalShare = Session.MyShare;
                            CompanyTotalShare= Session.CompanyShare;
                        }

                        User.Sessions = []
                        MyTotalSessionPL = MyTotalSessionPL + UserSessionPL;
                        User.SessionPL = UserSessionPL;
                    }

                    let UserMatchPL = 0.00;
                  
                    if (User.Odds.length > 0) {
                        for (const Odd of User.Odds) {
                            U_Type=Odd.U_Type;
                            Parent_Id=Odd.Parent;
                           
                            UserMatchPL = UserMatchPL + Odd.ProfitLoss;
                            CompanyTotalMatchPL=CompanyTotalMatchPL+Odd.ProfitLoss; 
                            MyTotalShare = Odd.MyShare;
                            CompanyTotalShare= Odd.CompanyShare;
                   

                        }
                       

 

                        User.MyShare = MyTotalShare;
                        // User.MatchPL = UserMatchPL;
                        User.My_MatchCommission = getMyCommissionOnOddsBet(User.Odds);
                       User.Company_Match_Commission = getCompanyCommissionOnOddsByBet(User.Odds);
                       MyTotalMatchPL =MyTotalMatchPL + calculateTotalPL(UserMatchPL, User.Company_Match_Commission, User.MyShare, User.My_MatchCommission)  ;
                       User.MatchPL =MyTotalMatchPL;
                       
                       MyTotalMatchCommission=MyTotalMatchCommission+ User.My_MatchCommission;
                       CompanyTotalMatchCommission= CompanyTotalMatchCommission+ User.Company_Match_Commission;
                    

                       // TotalEventNet_PL = TotalEventNet_PL + User.Net_PL

                    }   
                    
                     
                }

            }

        }

        Event.Bets = []

        Event.U_Type=U_Type;
        Event.Parent_Id=Parent_Id;

        Event.CompanyTotalSessionPL = CompanyTotalSessionPL;
        Event.CompanyTotalMatchPL = CompanyTotalMatchPL;
         Event.CompanyTotalSessionCommission = CompanyTotalSessionCommission;
         Event.CompanyTotalMatchCommission =CompanyTotalMatchCommission;   
         Event.CompanyTotalShare = CompanyTotalShare;


       
          Event.MyTotalSessionPL = MyTotalSessionPL;
         Event.MyTotalMatchPL = MyTotalMatchPL;
         Event.MyTotalSessionCommission = MyTotalSessionCommission;
          Event.MyTotalMatchCommission = MyTotalMatchCommission;
         Event.MyTotalShare = MyTotalShare; 
      Event.Profit_Loss = MyTotalMatchPL+MyTotalSessionPL;

     
   
    CompanyTotal= -( CompanyTotalSessionPL+CompanyTotalMatchPL)+ (CompanyTotalSessionCommission+CompanyTotalMatchCommission);
    CompanyBalance=parseFloat( calculatePercentage(CompanyTotal,CompanyTotalShare).toFixed(2))  
 
Event.CompanyBalance=CompanyBalance ;
CompanyTotalType=(CompanyBalance<0?"Debit":"Credit");

Event.CompanyTotalType=CompanyTotalType;
  
 
 //}


    }




    return Events

}
export const _getCricketDetails =async(Events, Bets)=> {

    let Users = []

    return await getManageBets(Bets, Users).then(async () => {


        return await getUserBets(Bets, Users).then(async () => {


            return await getManageEventBest(Events, Users).then(async () => {



                return await CalculateEventPL(Events, Users);


            })
        })


    })










}
 