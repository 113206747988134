import React,{useEffect,useState } from "react";   
import { useParams   } from "react-router-dom";  
import {  Table , Empty, Spin, Row,Col  } from 'antd';
import GoBack from "../components/buttons/GoBack"
 import {getLiveCompletedFancyBets } from "../../appRedux/actions/rex";
 import { Typography } from 'antd';
     
 const { Text } = Typography;
 const customEmptyText = (
  <div>
  <Empty  description={<Text disabled><h2>No Bets available on this session</h2></Text>} />
</div>
);

const CompletedBets = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  
 

  const [ CompletedBets,setCompletedBets] = useState([])  
 
  const { eid } = useParams()  

  
const columns = [
  
  {
    title: 'Title',
    dataIndex: 'Title',
    key: 'Title',
       },
  {
    title: 'P&L',
    dataIndex: 'PL',
    key: 'PL',  render:(Status,row)=>( 
 
     <span  style={{"color":row.PL<0?"red":"green"}} >{row.PL}</span>
    ), 
  }, 
  {
    title: 'Comm+',
    dataIndex: 'Comm_Plus',
    key: 'Comm_Plus',
       },
       {
        title: 'Comm-',
        dataIndex: 'Comm_Minus',
        key: 'Comm_Minus',
           },
       {
         title: 'Net P&L',
         dataIndex: 'Net_PL',
         key: 'Net_PL',
          render:(Status,row)=>( 
      
          <span  style={{"color":row.Net_PL<0?"red":"green"}} >{row.Net_PL}</span>
         ), 
          } ,
          {
            title: 'Action',
            dataIndex: 'Action',
            key: 'Action',
            render:(Status,row)=>( 
              row.key !== 'totalRow' ? (
                <a href={"/Events/"+eid+"/"+ btoa(row.Title)+"/live-report"} className="ant-tag ant-tag-has-color" style={{ backgroundColor: 'rgb(255, 85, 0)' }}>
                  Show Bets
                </a>
              ) : null
             ), 
           
             } 
 ];

   const GetCompletedFancyBets =async()=>{

 await getLiveCompletedFancyBets(eid ).then(async(result)=>{  
      
  setCompletedBets(result);
  setIsLoading(false);

     });


   }
  useEffect(() => {
   const fetchData = async () => {
    setIsLoading(true);

  await   GetCompletedFancyBets();


   };

   fetchData();
},   [props]);
 
 

  return ( 
    <>
  
  <div className="ant-card ant-card-bordered">
  <div className="ant-card-head">
    <div className="ant-card-head-wrapper">
      <div className="ant-card-head-title">South Africa v India - Profit and Loss</div>
           
        <div className="ant-card-extra"><span><GoBack/></span></div>
        
    </div>
  </div>
  <div className="ant-card-body" style={{ padding: 0 }}>
    <Row>
               <div class="ant-col ant-col-xs-12 ant-col-sm-12 ant-col-md-6 ant-col-lg-6 ant-col-xl-6">
                <a href={"/event-pnl/"+props.eid+"/match-slips"}>
                  <div class="ant-card ant-card gx-card-full gx-p-3 btn-grad ant-card-bordered">
                    <div class="ant-card-body">
                      <p class="gx-mb-0" style={{"color": "rgb(255, 255, 255)"}}>Match Bet Slips</p>
                    </div>
                  </div>
                </a>
              </div>
              <div class="ant-col ant-col-xs-12 ant-col-sm-12 ant-col-md-6 ant-col-lg-6 ant-col-xl-6">
                <a href={"/event-pnl/"+props.eid+"/fancy-slips"}>
                  <div class="ant-card ant-card gx-card-full gx-p-3 btn-grad ant-card-bordered">
                    <div class="ant-card-body">
                      <p class="gx-mb-0" style={{"color": "rgb(255, 255, 255)"}}>Session Bet Slips</p>
                    </div>
                  </div>
                </a>
              </div>
              <div class="ant-col ant-col-xs-12 ant-col-sm-12 ant-col-md-6 ant-col-lg-6 ant-col-xl-6">
                <a href={"/event-pnl/"+props.eid+"/client-report"}>
                  <div class="ant-card ant-card gx-card-full gx-p-3 btn-grad ant-card-bordered">
                    <div class="ant-card-body">
                      <p class="gx-mb-0" style={{"color": "rgb(255, 255, 255)"}}>Client Report</p>
                    </div>
                  </div>
                </a>
              </div>
              <div class="ant-col ant-col-xs-12 ant-col-sm-12 ant-col-md-6 ant-col-lg-6 ant-col-xl-6">
                <a href={"/event-pnl/"+props.eid+"/company-report"}>
                  <div class="ant-card ant-card gx-card-full gx-p-3 btn-grad ant-card-bordered">
                    <div class="ant-card-body">
                      <p class="gx-mb-0" style={{"color": "rgb(255, 255, 255)"}}>Company Report</p>
                    </div>
                  </div>
                </a>
              </div>
           
          </Row>
    
    <Row>
    <div class="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-xl-24">
  <Spin spinning={isLoading}>
      <Table width={100} size='small' bordered   scroll={{x: 'max-content'}} pagination={false}    locale={{ emptyText:customEmptyText , }} columns={columns} dataSource={CompletedBets ? CompletedBets == null ? null : CompletedBets : null}/>
 </Spin>
 </div>
 </Row>  
  </div>
</div>
                </>







  );
};

export default CompletedBets;
