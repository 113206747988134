 import {   Table } from 'antd';
import React  from 'react'; 
const LoginReport  = (props) => {
  
const data = props.data; 
 
  const columns = [
    {
      title: 'Country',
      dataIndex: 'Country',
      key: 'Country',
     },
     {
      title: 'Region',
      dataIndex: 'Region',
      key: 'Region',
     },
     {
      title: 'City',
      dataIndex: 'City',
      key: 'City',
     },
     {
      title: 'ISP',
      dataIndex: 'ISP',
      key: 'ISP',
     },
     {
      title: 'IP-Address',
      dataIndex: 'IP-Address',
      key: 'IP-Address',
     } ,
     {
      title: 'Login Date',
      dataIndex: 'Login Date',
      key: 'Login Date',
     } ,
       
  ];
  return <Table   width={100} size='small' bordered scroll={"left"}   columns={columns} dataSource={data} />;
};
export default LoginReport;