import React from "react";
import { useParams} from "react-router-dom";  
import Ledger from "./ledger"
import GoBack from "../../../components/buttons/GoBack"

  const UserLedger = () => {
   const authUser = JSON.parse(localStorage.getItem("authUser"));
   const { U_Type, U_ID } = useParams();
 
   // Update U_ID to authUser.ID if U_ID is undefined or null
   const updatedU_ID = U_ID === undefined || U_ID === null ? authUser.ID : U_ID;
 
   var User_Type;
   var DUser_Type;  

   
  if(U_Type.toLowerCase()==="admin"){
      User_Type= "Admin" ;  
      DUser_Type= "Mini Admin" ;  
      }
      else if(U_Type.toLowerCase()==="mini"){
          User_Type=  "Mini Admin" ;  
          DUser_Type= "Master" ;  
      }
      else if(U_Type.toLowerCase()==="master"){
          User_Type=  "Master" ;  
          DUser_Type= "Super" ;  
      }
      else if(U_Type.toLowerCase()==="super"){
          User_Type=  "Super" ;  
          DUser_Type= "Agent" ;  
      }
      else if(U_Type.toLowerCase()==="agent"){
          User_Type=  "Agent" ;  
          DUser_Type= "Client" ;  
      }
      else if(U_Type.toLowerCase()==="client"){
          User_Type=  "Client" ;  
          DUser_Type= "" ;  
      }
         
   return ( 


      <>
      <div className="ant-card ant-card-bordered">
         <div className="ant-card-head">
            <div className="ant-card-head-wrapper">
               <div className="ant-card-head-title">
                  <span>
                     <h1 style={{"display": "inline-block"}}>{User_Type=='Super'?User_Type+" Agent":User_Type} Ledger</h1>
                  </span>
               </div>
               <div className="ant-card-extra"><span><GoBack/></span></div>
            </div>
         </div>
         <div className="ant-card-body">
            
         <div className="ant-row gx-center" style={{"margin-top": "24px"}}>
   <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-8 ant-col-xl-8">
      
        <Ledger type="Lena" color="rgb(16, 191, 53)" user_Type={ User_Type}  d_user_Type={DUser_Type} user_ID={updatedU_ID} />
   
   </div>
   <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-8 ant-col-xl-8">
      
      <Ledger type="Dena"  color="rgb(222, 77, 77)"  user_Type={ User_Type}  d_user_Type={DUser_Type}  user_ID={updatedU_ID} />
   
   </div>
   <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-8 ant-col-xl-8">
    
      <Ledger type="Clear" color="rgb(92, 181, 245)" user_Type={ User_Type}  d_user_Type={DUser_Type} user_ID={updatedU_ID}  />
   
   </div>
</div>
         </div>
      </div>
                  </>
  
  
  
  
  
  
  
    );
   }
 

export default UserLedger;
