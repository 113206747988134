import React, { useState, useEffect } from 'react';
import {     Select } from 'antd'; 
import moment from "moment";
import { getAgentsCommissionClients  } from "../../../../../appRedux/actions/rex";
 

const Option = Select.Option;
const Clients = (props) => {
  const [Clients, setClients] = useState([]);
   
  const GetAgentsCommissionClients = async () => {
    try {
        const result = await getAgentsCommissionClients();
        if (result ){
        setClients(result);
        }
  
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      
  };
  }
  useEffect(() => {
    GetAgentsCommissionClients();
  }, [props.resetCount]);

 
  const filteredData = Clients && Clients.filter(item => {
    if (props.timeRange.length === 0) {
     return true; // Show all data if no time range is selected
   } else {
     const eventTime = moment(item.Time, 'DD-MM-YYYY HH:mm:ss');
     const startTime = moment(props.timeRange[0]).startOf('day');
     const endTime = moment(props.timeRange[1]).endOf('day');
 
     return eventTime.isBetween(startTime, endTime, null, '[]');
   }
 });

   
return (
 <>  
        <div style={{ "marginTop": "10px" }}>
        <Select
              style={{ width: "100%" }}
             defaultValue={props.client}
               placeholder={`Select Master`}
               onChange={props.handleClientChange}
              allowClear
              showSearch
              optionFilterProp="children"
            >
              <Option value="All">All</Option>
              {filteredData && filteredData.length>0 ?filteredData.map(Client  => (
                <Option key={Client.U_ID} value={Client.U_ID}>
                  {Client.Username}
                </Option>
              )):null} 
            </Select>
         
      </div>
     
   

 </>
);
};

export default Clients;