import { Row, Col, DatePicker, Dropdown, Table, Menu, Spin } from 'antd';
import moment from "moment";
import React, { useState, useEffect } from 'react';

import {ApiSocket} from "../../../../util/api-socket";
const EventList = (props) => {
  ApiSocket();
  const AuthUser = JSON.parse(localStorage.getItem("authUser"));
   const [timeRange, setTimeRange] = useState([]); // State to store the selected time range
  const data = props.data;

  

  const { RangePicker } = DatePicker;

  const onChange = (dates, dateStrings) => {
    console.log(dateStrings);
    setTimeRange(dates); // Update the time range state
  }
  const renderOptionMenu = (row) => {
    return (
      <Menu>
       { row.Status=="Active"? <Menu.Item key="1"><a className="dropdown-link" href={"/Events/"+row.Event_Id+"/live-report"}>
       Match and Session Position
        </a></Menu.Item>:
       null} 
       {AuthUser.U_Type=="Agent"? 
       <Menu.Item key="2"><a className="dropdown-link" href={"/Events/"+row.Event_Id+"/agent-comm-report"}>
      Agent Commission Report
        </a></Menu.Item>:null
        }
        <Menu.Item key="2"><a className="dropdown-link" href={"/Events/"+row.Event_Id+"/plus-minus-type"}>
        Match and Session Plus Minus
        </a></Menu.Item>
        <Menu.Item key="2">
        { row.Status=="Completed"?
        
        <a className="dropdown-link" href={"/Events/"+row.Event_Id+"/completed-match-slips"}>
        Display Match Bets
        </a>
        :  <a className="dropdown-link" href={"/Events/"+row.Event_Id+"/match-slips"}>
        Display Match Bets
        </a>}
        
        </Menu.Item>
        { row.Status=="Completed"?null:
        
        <Menu.Item key="2"><a className="dropdown-link" href={"/Events/"+row.Event_Id+"/fancy-slips"}>
        Display Session Bets
        </a></Menu.Item>}
        <Menu.Item key="2"><a className="dropdown-link" href={"/Events/"+row.Event_Id+"/completed-fancy-slips"}>
        Completed Fancies
        </a></Menu.Item>
        {row.Status=="Completed"?
        <Menu.Item key="2"><a className="dropdown-link" href={"/Events/"+row.Event_Id+"/client-report"}>
        Agent Plus Minus
        </a></Menu.Item>:null}
       <Menu.Item key="2"><a className="dropdown-link" href={"/Events/"+row.Event_Id+"/rejectedBetsByEvent"}>
        Rejected Bets
        </a></Menu.Item>
        
      </Menu>
    );
  }; 


  const columns = [
    
    {
      title: '',
      dataIndex: 'Status',
      key: 'Status',
      render: (Status,row) =>  (
        <Dropdown overlay={renderOptionMenu(row)} trigger={['click']}
        >
<button type="button" className="ant-btn ant-dropdown-trigger ant-btn-primary ant-btn-sm" style={{"margin-bottom": "0px"}} ant-click-animating-without-extra-node="false">
  <i className="icon icon-dropdown"></i></button>

        
        </Dropdown>
      ),
     },
    {
      title: 'Event Id',
      dataIndex: 'Event_Id',
      key: 'Event-Id',
       
    },
    {
      title: 'Market Name',
      dataIndex: 'Event_Name',
      key: 'Event-Name',
      render: (Status,row) =>  (
       <span>{row.Event_Name}</span> 
      ),
      },
    {
      title: 'Time',
      dataIndex: 'Time',
      key: 'Time',
      render: (Status,row) =>  (
        <div>{row.Time}</div>
       ),
      },
    {
      title: 'Won by',
      dataIndex: 'Won_by',
      key: 'Won-by',
       },
    {
      title: 'Profit Loss',
      dataIndex: 'Profit_Loss',
      key: 'Profit-Loss',
      align: 'right',
      render: (Status,row) =>  (
      <span style={{color:row.Profit_Loss<0?"red":"green"}}>{parseFloat(row.Profit_Loss).toFixed(2)}</span> 
       ),
        }]
// Filter the data based on the selected time range
const filteredData = data && data.filter(item => {
  if (timeRange.length === 0) {
    return true; // Show all data if no time range is selected
  } else {
    const eventTime = moment(item.Time, 'DD-MM-YYYY HH:mm:ss');
    const startTime = moment(timeRange[0]).startOf('day');
    const endTime = moment(timeRange[1]).endOf('day');

    return eventTime.isBetween(startTime, endTime, null, '[]');
  }
});

return (
  <Row>
    <Col lg={8} md={8} sm={24} xs={24} style={{ "text-align": "center", "margin": "0 auto" }}>
      <div style={{ "marginTop": "10px" }}>
        <RangePicker className="gx-mb-2 gx-w-100"
          ranges={{ Today: [moment(), moment()], 'This Month': [moment().startOf('month'), moment().endOf('month')] }}
          onChange={onChange} />
      </div>
    </Col>

    <Col xl={24} md={24} sm={24} xs={24}>
      <Spin spinning={props.loading}>    
         <Table width={100} size='small'   pagination={{ pageSize: 100 }}   bordered scroll={{ x: true }} columns={columns} dataSource={filteredData} />
     </Spin>
    </Col>
  </Row>
);
};

export default EventList;