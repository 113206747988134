import React  from "react";   
import { Row,Col } from 'antd'; 
import TodayPandl_ from "../../components/tables/TodayPandl" 
import GoBack from "../../components/buttons/GoBack"
const TodayPandl = () => { 
 
  
 
    return (<> 
<div className="ant-card ant-card-bordered">
   <div className="ant-card-head">
      <div className="ant-card-head-wrapper">
         <div className="ant-card-head-title">
            <span>
               <h1 style={{"display": "inline-block"}}>  </h1>
            </span>
         </div>
         <div className="ant-card-extra"><span><GoBack/></span></div>
      </div>
   </div>
   <div className="ant-card-body">

   <Row style={{"margin": "10px"}}> 
    <Col xl={24} md={24} sm={24} xs={24} style={{ "margin-left":"20px"}}>
       <div style={{"marginTop": "10px"}}>
       <h1 style={{"text-align": "center", "padding": "15px"}}>Total : 0.00</h1>
      </div>
  </Col>
</Row>
       
      <div className="ant-table-wrapper">
         <div className="ant-spin-nested-loading">
            <div className="ant-spin-container">
            <TodayPandl_   />
     
               </div>
         </div>
       
      </div>
   </div>
</div> 
            </>
               );
 

 
}


 
 
 
export default TodayPandl;
