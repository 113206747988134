import React from "react";
import {Route, Switch} from "react-router-dom";
import ListUsers from "./list-users/index"; 

import UserTransactions from "./txn/user-transactions/index";  

import ShareAdmin from "./share-admin/index"; 
import MyLedger from "./ledger/my-ledger/index"; 
import AgentCommission from "./ledger/agent-commission/index"; 
import MatchLedger from "./ledger/match-ledger/index"; 
import DetailedLedger from "./ledger/detailed-ledger/index";  

import DeletedLenden from "./deleted-lenden/index";  

import LedgerPartnership from "./ledger/ledger-partnership/index"; 
//import CreateUser from "./create-user/index"; 
import CreateMini from "./create-mini/index"; 
import CreateMaster from "./create-master/index"; 
import CreateSuper from "./create-super/index"; 
import CreateAgent from "./create-agent/index"; 
import CreateClient from "./create-client/index"; 


import UpdateMini from "./update-mini/index"; 
import UpdateMaster from "./update-master/index"; 
import UpdateSuper from "./update-super/index"; 
import UpdateAgent from "./update-agent/index"; 
import UpdateClient from "./update-client/index"; 


import LoginReport from "./login-report/index"; 
import AccountOperations from "./account-operations/index"; 
import Statement from "./statement/index";  

import Deads from "./dead";  

const authUser=JSON.parse(localStorage.getItem("authUser")); 
 

const Main = ({match}) => (
  <Switch>
   {/* <Route path={`${match.url}/create-:U_Type`} component={CreateUser}/> */}
    <Route path={`${match.url}/create-mini`} component={CreateMini}/>
    <Route path={`${match.url}/create-master`} component={CreateMaster}/> 
    <Route path={`${match.url}/create-super`} component={CreateSuper}/> 
    <Route path={`${match.url}/create-agent`} component={CreateAgent}/> 
    <Route path={`${match.url}/create-client`} component={CreateClient}/>   

    <Route path={`${match.url}/update-mini/:U_ID`} component={UpdateMini}/>
    <Route path={`${match.url}/update-master/:U_ID`} component={UpdateMaster}/> 
    <Route path={`${match.url}/update-super/:U_ID`} component={UpdateSuper}/> 
    <Route path={`${match.url}/update-agent/:U_ID`} component={UpdateAgent}/> 
    <Route path={`${match.url}/update-client/:U_ID`} component={UpdateClient}/>   


    <Route path={`${match.url}/list-:U_Type/:U_ID`} component={ListUsers}/>  
    <Route path={`${match.url}/list-:U_Type`} component={ListUsers}/>  

    <Route path={`${match.url}/deletedlenden/:U_ID`} component={DeletedLenden}/>  

    <Route path={`${match.url}/login-report/:U_ID`} component={LoginReport}/>  
    <Route path={`${match.url}/account-operations/:U_ID`} component={AccountOperations}/> 
    <Route path={`${match.url}/statement/:U_ID`} component={Statement}/>   



    <Route path={`${match.url}/txn-:U_Type`} component={UserTransactions}/>  
    <Route path={`${match.url}/share-admin`} component={ShareAdmin}/>  

    <Route path={`${match.url}/my-ledger`} component={MyLedger}/> 
    <Route path={`${match.url}/match-ledger`} component={MatchLedger}/>  
    
    <Route path={`${match.url}/ledger-:U_Type/:U_ID`} component={DetailedLedger}/>  
    <Route path={`${match.url}/ledger-:U_Type`} component={DetailedLedger}/> 
    <Route path={`${match.url}/agent-commission`} component={AgentCommission}/> 

    <Route path={`${match.url}/partnership`} component={LedgerPartnership}/> 

    <Route path={`${match.url}/dead:U_Type`} component={Deads}/>   



  </Switch>
);

export default Main;
