import React from "react";
import { Row  } from 'antd'; 
import Ledger from "./ledger"
import GoBack from "../../../components/buttons/GoBack"
 
const MyLedger = () => { 
      return ( 


      <>
      <div className="ant-card ant-card-bordered">
         <div className="ant-card-head">
            <div className="ant-card-head-wrapper">
               <div className="ant-card-head-title">
                  <span>
                     <h1 style={{"display": "inline-block"}}>My Ledger</h1>
                  </span>
               </div>
               <div className="ant-card-extra"><span><GoBack/></span></div>
            </div>
         </div>
         <div className="ant-card-body">
           
  
          <Ledger/>
         </div>
      </div>
                  </>
  
  
  
  
  
  
  
    );
};

export default MyLedger;
