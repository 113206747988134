import React,{useEffect,useState} from "react";
import { Row,Col,DatePicker  } from 'antd';
import moment from "moment";
import EventList from "./EventList";
import {getEvents} from "../../../../appRedux/actions/rex"
import GoBack from "../../../components/buttons/GoBack"
 
const cricket = () => {
   const [Events, setEvents] = useState({});
   const [loading, setLoading] = useState(true);
  
   useEffect(() => {
      const fetchData = async () => {
        setLoading(true);
      await getEvents().then((result)=>{ 
   setEvents( result);  
       setLoading(false);
    });
      };

      fetchData();
  }, []);

  return ( 


    <>
    <div className="ant-card ant-card-bordered">
       <div className="ant-card-head">
          <div className="ant-card-head-wrapper">
             <div className="ant-card-head-title">
                <span>
                   <h1 style={{"display": "inline-block"}}>Cricket Detail</h1>
                </span>
             </div>
             <div className="ant-card-extra"><span><GoBack/></span></div>
          </div>
       </div>
       <div className="ant-card-body">
         
 <EventList loading={loading} data={Events?Events.length>0?Events:null:null}/>
 
  
       
       </div>
    </div>
                </>







  );
};

export default cricket;
