import React,{useEffect,useState } from "react";   
import { Row, Col , Select, Table,  Spin } from 'antd';
import { useParams  } from "react-router-dom";   
import GoBack from "../../components/buttons/GoBack"
import {getRejectedBets} from "../../../appRedux/actions/rex"  
 
const Option = Select.Option;

const rejectedBetsByEvent = () => {
   const [Bets, setBets] = useState([]);
   
   const { eid } = useParams()  
 const columns = [
    
         {
           title: 'Rate',
           dataIndex: 'Rate',
           key: 'Rate'
         },
         {
           title: 'Amount',
           dataIndex: 'Amount',
           key: 'Amount',
     
         },
         {
           title: 'Type',
           dataIndex: 'Type',
           key: 'Type',
         },
         {
           title: 'Team',
           dataIndex: 'Team',
           key: 'Team',
         },
         {
           title: 'Client',
           dataIndex: 'Client',
           key: 'Client',
         },
         {
           title: 'Agent',
           dataIndex: 'Agent',
           key: 'Agent',
         }
         ,
         {
           title: 'Date',
           dataIndex: 'Date',
           key: 'Date',
         }
         ,
         {
           title: 'Loss',
           dataIndex: 'Loss',
           key: 'Loss',
         }
         ,
         {
           title: 'Profit',
           dataIndex: 'Profit',
           key: 'Profit',
         },
          {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
          } 
     
       ];

   useEffect(() => {
      const fetchData = async () => {
     
    await getRejectedBets(eid).then((result)=>{ 
       
      setBets( result);  
    });
      };

      fetchData();



     






  }, []);

  return ( 


    <>
    <div className="ant-card ant-card-bordered">
       <div className="ant-card-head gx-mb-3 ">
          <div className="ant-card-head-wrapper">
             <div className="ant-card-head-title">
                <span>
                   <h1 style={{"display": "inline-block"}}>REJECTED And CANCELLED Bets</h1>
                </span>
             </div>
             <div className="ant-card-extra"><span><GoBack/></span></div>
          </div>
       </div>
       <div className="ant-card-body ">
       <Row style={{"margin": "10px"}}>
    
    
    <Col xl={4} md={4} sm={24} xs={24} style={{ "text-align": "left"  }}>
           <Select
              style={{ width: "100%" }}
             // value={selectedUser}
              placeholder={`Select Mini`}
             // onChange={handleUserChange}
              allowClear
              showSearch
              optionFilterProp="children"
            >
              <Option value="All">All</Option>
          
            </Select>
         
     </Col>
    <Col xl={4} md={4} sm={24} xs={24} style={{ "text-align": "left"  }}>
            <Select
              style={{ width: "100%" }}
             // value={selectedUser}
             placeholder={`Select Master`}
             // onChange={handleUserChange}
              allowClear
              showSearch
              optionFilterProp="children"
            >
              <Option value="All">All</Option>
          
            </Select>
         
      </Col>
    <Col xl={4} md={4} sm={24} xs={24} style={{ "text-align": "left"  }}>
            <Select
              style={{ width: "100%" }}
             // value={selectedUser}
             placeholder={`Select Super`}
             // onChange={handleUserChange}
              allowClear
              showSearch
              optionFilterProp="children"
            >
              <Option value="All">All</Option>
          
            </Select>
         
   </Col>
   <Col xl={4} md={4} sm={24} xs={24} style={{ "text-align": "left"  }}>
            <Select
              style={{ width: "100%" }}
             // value={selectedUser}
             placeholder={`Select Agent`}
             // onChange={handleUserChange}
              allowClear
              showSearch
              optionFilterProp="children"
            >
              <Option value="All">All</Option>
          
            </Select>
         
    </Col>
    <Col xl={4} md={4} sm={24} xs={24} style={{ "text-align": "left"  }}>
            <Select
              style={{ width: "100%" }}
             // value={selectedUser}
             placeholder={`Select Client`}
             // onChange={handleUserChange}
              allowClear
              showSearch
              optionFilterProp="children"
            >
              <Option value="All">All</Option>
          
            </Select>
         
    </Col>
  </Row>
  <Row className=" gx-center">
    <Col xl={24} md={24} sm={24} xs={24} style={{ "text-align": "left"  }}>
  
       <Table style={{"margin-top": "10px"}} width={100} size='small' bordered scroll={{ x: 'max-content' }} columns={columns}  dataSource={Bets?Bets==null?null:Bets:null}   />
       </Col>
       </Row>
        
       </div>
    </div>
                </>







  );
};

export default rejectedBetsByEvent;
