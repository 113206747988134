import React,{useEffect,useState } from "react";   
import { useParams   } from "react-router-dom";  
import {getMatchOddsLivePLBooks } from "../../../appRedux/actions/rex";
import { FindJson } from '../../../util/jsonManeger';
 

const  MatchOddsLivePLBooks = (props) => { 
  const [Books, setBooks] = useState(false);


    
  const  {eid}  =   useParams()
  useEffect(() => {
    const fetchData = async () => { 
      
     try{  const _Books=await getMatchOddsLivePLBooks(eid); 
      if(_Books){ 
          
    setBooks( {MyBook:FindJson(_Books.MyBook,'Selection_ID',props.Selection),AllBooks:FindJson(_Books.AllBooks,'Selection_ID',props.Selection)}  );     

             }else{
        
        
        setBooks(false);     
       
      
      }
    }catch{}
    };

    fetchData();
  }, [props.Selection]);   
  return (
    <>
 
{Books?  

props.myBook ?
Books.MyBook.Balance>0?<div  style={{ "color": "rgb(81, 153, 230)" }}>{Books.MyBook.Balance.toFixed(2)}</div>:
<div style={{ "color": "red" }}>{Books.MyBook.Balance.toFixed(2) }</div> 
:
Books.AllBooks.Balance>0?<div  style={{ "color": "rgb(81, 153, 230)" }}>{Books.AllBooks.Balance.toFixed(2)}</div>:
<div style={{ "color": "red" }}>{Books.AllBooks.Balance.toFixed(2) }</div> 


:<div style={{ "color": "rgb(81, 153, 230)" }}>0.00</div>






}


       
    
</>

  );
};

export default MatchOddsLivePLBooks;