import React,{useEffect,useState} from "react"; 
import Commissions from "./commissions/index"
import GoBack from "../../../components/buttons/GoBack"
import { NotificationContainer } from 'react-notifications';
 
const AgentCommission = () => {
   const [Lenden, setLenden] = useState({});

//    useEffect(() => {
//       const fetchData = async () => {
     
//     await getCommissions().then((result)=>{ 
//     setEvents( result);  
//     });
//       };

//       fetchData();
//   }, []);

  return ( 


    <>
    <div className="ant-card ant-card-bordered">
       <div className="ant-card-head">
          <div className="ant-card-head-wrapper">
             <div className="ant-card-head-title">
                <span>
                   <h1 style={{"display": "inline-block"}}>Client Commission Lenden</h1>
                </span>
             </div>
             <div className="ant-card-extra"><span><GoBack/></span></div>
          </div>
       </div>
       <div className="ant-card-body">
         
 <Commissions data={Lenden?Lenden.length>0?Lenden:null:null}/>
 
  
       
       </div>
    </div>
        <NotificationContainer/>        </>







  );
};

export default AgentCommission;
