import React,{useEffect,useState } from "react";   
import { useParams   } from "react-router-dom";  
import {  Table , Empty, Spin  } from 'antd';
 import {getLiveCompletedFancyBets } from "../../../appRedux/actions/rex";
 import { Typography } from 'antd';
     
 const { Text } = Typography;
 const customEmptyText = (
  <div>
  <Empty  description={<Text disabled><h2>No Bets available on this session</h2></Text>} />
</div>
);

const CompletedBets = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  
  const [ ReloadKey,SetReloadKey ] = useState(0)  
 
  const Reload  = () => {
    SetReloadKey((prevReloadKey) => prevReloadKey + 1);
  }; 

  const [ CompletedFancies,setCompletedFancies ] = useState([])  
 
  const { eid } = useParams()  

  
const columns = [
  
  {
    title: 'Title',
    dataIndex: 'Title',
    key: 'Title',
       },
  {
    title: 'P&L',
    dataIndex: 'PL',
    key: 'PL',  render:(Status,row)=>( 
 
     <span  style={{"color":row.PL<0?"red":"green"}} >{row.PL}</span>
    ), 
  },
  {
    title: 'Won By',
    dataIndex: 'Won By',
    key: 'Won By',
       },
       {
         title: 'Net P&L',
         dataIndex: 'Net_PL',
         key: 'Net_PL',
          render:(Status,row)=>( 
      
          <span  style={{"color":row.Net_PL<0?"red":"green"}} >{row.Net_PL}</span>
         ), 
          } ,
          {
            title: 'Action',
            dataIndex: 'Action',
            key: 'Action',
            render:(Status,row)=>( 
              row.key !== 'totalRow' ? (
                <a href={"/Events/"+eid+"/"+ btoa(row.Title)+"/live-report"} className="ant-tag ant-tag-has-color" style={{ backgroundColor: 'rgb(255, 85, 0)' }}>
                  Show Bets
                </a>
              ) : null
             ), 
           
             } 
 ];

   const GetCompletedFancyBets =async()=>{

 await getLiveCompletedFancyBets(eid ).then(async(result)=>{  
      
  setCompletedFancies(result);
  setIsLoading(false);

     });


   }
  useEffect(() => {
   const fetchData = async () => {
    setIsLoading(true);

  await   GetCompletedFancyBets();


   };

   fetchData();
},   [props.GemsX,ReloadKey]);
 
 


let totalPL = 0.00; 
  let totalNet_PL = 0.00; 

  if (CompletedFancies) {
  CompletedFancies.forEach((item) => {
    totalPL += parseFloat(item.PL) || 0.00;
    totalNet_PL += parseFloat(item.Net_PL) || 0.00;
  });
}

totalPL = totalPL.toFixed(2);
totalNet_PL = totalNet_PL.toFixed(2);
   // Create the total row
 const totalRow = {
    key: 'totalRow', // Use a unique key
    Code: 'Total',
    Title: 'Total',
    PL: totalPL,
    Net_PL: totalNet_PL,
    };
  const rowClassName = (record) => {
    return CompletedFancies.key === 'total' ? 'dateHiglight' : '';
  }
 

  return ( 
    <>
  
  <div className="ant-card ant-card-bordered">
  <div className="ant-card-head">
    <div className="ant-card-head-wrapper">
      <div className="ant-card-head-title">Completed fancy</div>
      <div className="ant-card-extra">
        <span>
          <button type="button" onClick={()=>Reload()} className="ant-btn ant-btn-primary">
            <span> Refresh </span>
          </button>
        </span>
      </div>
    </div>
  </div>
  <div className="ant-card-body" style={{ padding: 0 }}>
  <Spin spinning={isLoading}>
      <Table width={100} size='small' bordered   scroll={{x: 'max-content'}} pagination={false}  rowClassName={rowClassName} locale={{ emptyText:customEmptyText , }} columns={columns} dataSource={CompletedFancies?CompletedFancies == null ?[totalRow] :  [totalRow,...CompletedFancies ]:null} />
 </Spin>
  </div>
</div>
                </>







  );
};

export default CompletedBets;
