import React,{useEffect,useState} from "react";  
 
import { useParams  } from "react-router-dom";   
import   ProfitLoss   from "./profit-loss"; 
const PlusMinusType = () => {
      
   const { eid } = useParams()  
   const [eventId, day] = eid.split('-');
   const FDay = day || 'All';

   
  return ( 


    <>
     
      
<ProfitLoss EID={eventId} Day={FDay} />
 
                </>







  );
};

export default PlusMinusType;
